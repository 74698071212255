import React from 'react'
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword'

function ForgotPasswordPage() {
    return (
        <div>
            <ForgotPassword />
        </div>
    )
}

export default ForgotPasswordPage