import React, { useEffect } from "react";
import { useState } from "react";
import "../Css/Login.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import closedEye from "../Assets/closed_eye.png";
import openedEye from "../Assets/open_eye.png";
import { Field, Formik } from 'formik';
import { LoginValidation } from "../Utils/Validation";
import { equalTo, get, getDatabase, onValue, orderByChild, push, query, ref, set } from "firebase/database";
import { app, database } from "../Utils/Firebase";
import { v4 as uuidv4 } from 'uuid';
import { FormControlLabel, Switch } from "@mui/material";
import mainlogo from "../Assets/logo_and_text.svg";
import moment from "moment";
import { History } from "../Utils/Constant";

function Login() {
    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();
    const newUUID = uuidv4();

    const db = getDatabase(app);
    // const database = app.database();

    //send data
    const handleSubmit = (values, { setFieldError, resetForm, setSubmitting }) => {
        try {
            // set(ref(db, `users/${newUUID}`), {
            //     email: values?.email, password: values?.password, no_of_screens: 2
            // });
            const usersRef = ref(db, 'users/')
            const mostViewedPosts = query(ref(db, 'users'), orderByChild('email'), equalTo(values?.email));
            get(mostViewedPosts).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const userKey = Object.keys(data)[0];
                    const user = data[userKey];

                    if (user.password === values?.password) {
                        localStorage.setItem('id', userKey);
                        localStorage.setItem('email', user?.email);
                        localStorage.setItem('no_of_screens', user?.no_of_screens);
                        const history = {
                            user_id: userKey,
                            title: "Account",
                            status: `${user?.email} has been logged in successfully.`,
                            timestamp: moment(new Date()).toISOString()
                        }
                        const historyRef = ref(db, History);
                        const newHistoryRef = push(historyRef);
                        set(newHistoryRef, history);
                        window.location.href = "/dashboard"
                        resetForm();
                    } else {
                        setFieldError("password", "Invalid Password Please Enter correct password.")
                        setSubmitting(false);
                    }
                } else {
                    setFieldError("email", "This email does not exits.")
                    setSubmitting(false);
                    console.log('No data found');
                }
            }).catch((error) => {
                console.error("Error getting data:", error);
            });
        } catch (error) {
            console.log("error", error)
        }
        // window.location.href = "/dashboard"
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleShowPassword = () => {
        setPasswordShown(!passwordShown);
    };


    return (
        <>
            <div className="container">
                <div>
                    <img src={mainlogo} alt="logo" className="logo" />
                </div>
                <div className="sub-login-container">
                    <div className="div1">
                        <p className="login-left-text">Log in to your <br />CMS account</p>
                        <p className="login-left-subtext">Enter your user id and password to <br />sign in!</p>
                    </div>
                    <div className="loginform-container">
                        <div className="loginform">
                            <Formik
                                initialValues={{ email: '', password: '' }}
                                validationSchema={LoginValidation}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    getFieldProps
                                    /* and other goodies */
                                }) => (
                                    <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        <div className="field">
                                            <label>User ID</label>
                                            <Field
                                                type="text"
                                                name="email"
                                                className={`textarea ${(touched.email && errors.email) ? "error-border" : ""}`}
                                                value={values?.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <p className="error-message">
                                            {touched.email && errors.email}
                                        </p>
                                        <div className="field">
                                            <label>Password</label>
                                            <Field
                                                type={passwordShown ? "text" : "password"}
                                                name="password"
                                                className={`textarea ${(touched.password && errors.password) ? "error-border" : ""}`}
                                                value={values?.password}
                                                onChange={handleChange}
                                            />
                                            <Image
                                                src={passwordShown ? openedEye : closedEye}
                                                alt="hide password"
                                                onClick={handleShowPassword}
                                            />
                                        </div>
                                        <p className="error-message">
                                            {touched.password && errors.password}
                                        </p>
                                        <div className="middle">
                                            <div>
                                                <FormControlLabel control={<Switch defaultChecked />} label="Remember me" />
                                            </div>
                                            <div className="right">
                                                <label>
                                                    <p className="forgot-password" onClick={() => navigate("/forgot-password")}>Forgot password?</p>
                                                </label>
                                            </div>
                                        </div>

                                        {isSubmitting ? (
                                            <button
                                                className="btn12"
                                                onClick={handleSubmit}
                                                style={{ display: "grid" }}
                                                disabled={isSubmitting}
                                            >
                                                <Loader />
                                            </button>
                                        ) : (
                                            <button className="btn12" onClick={handleSubmit}>
                                                Log In
                                            </button>
                                        )}
                                    </form>
                                )}
                            </Formik >
                        </div>
                        <div className="term-container">
                            <p className="term-condition-text">Terms & Conditions</p>
                            <p className="term-condition-text">Privacy Policy
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;

const Image = styled.img`
  position: absolute;
  padding-top: 38px;
  right: 25px;
  width: 18px;
  height: 18px;
`;

const Loader = styled.div`
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
