import React, { useMemo, useState } from 'react'
import CustomTable from '../Common/Table'
import CustomButton from '../Common/CustomButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ScreenFormDrawer from '../Screens/ScreenFormDrawer';
import { get, getDatabase, ref, update } from 'firebase/database';
import { app } from '../../Utils/Firebase';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';

const defaultValue = {
    pairing_code: "",
    screen_name: "",
    screen_tag: "",
    location: "",
    city: "",
    state: "",
    country: "",
    area: ""
}

function ScreenTable({ screens, loading }) {
    const [isOpen, setIsOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(defaultValue);
    const db = getDatabase(app);

    const navigate = useNavigate();
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    const handleView = (data) => {
        setIsOpen(true);
        setInitialValues(data);
    }

    const columns = useMemo(
        () => [
            {
                accessor: 'firstName',
                Header: 'Sr.no',
                Cell: (row) => {
                    return <p>{row?.cell?.row?.index + 1}</p>
                }
            },
            {
                accessor: 'screen_name',
                Header: 'Screen Name',
            },
            {
                accessor: 'pairing_code',
                Header: 'Pairing Code',
            },
            {
                accessor: 'startDate',
                Header: 'Date of Pairing',
            },
            // {
            //     accessor: 'status',
            //     Header: 'Status',
            // },
            {
                accessor: 'actions',
                Header: 'Action',
                Cell: ({ row }) => {
                    return <Button variant="contained" onClick={() => handleView(row?.original)} sx={{
                        width: "auto", background: 'rgba(13, 110, 253, 1)', borderRadius: "30px", height: "30px", color: 'white', fontFamily: "Montserrat", ":hover": {
                            background: 'rgba(13, 110, 253, 1)',
                            opacity: 0.8
                        },
                        fontSize: "10.3px",
                        fontWeight: 600,
                    }}>
                        View More&nbsp;<ArrowForwardIosIcon style={{ height: 16, width: 16 }} />
                    </Button>
                }
            },
        ],
        []
    );

    const filteredScreen = useMemo(() => {
        const top5 = screens.splice(0, 5);
        return top5;
    }, [screens]);

    const handleSubmit = async (values, { resetForm, setSubmitting }) => {
        try {
            const updates = {};
            updates[`Screens/${values?.pairing_code}`] = {
                ...values,
            }
            await update(ref(db), updates);
            resetForm();
            setSubmitting(false);
            toast.success("Screen updated successfully.", {
                closeButton: <Close />
            })
            setIsOpen(false);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='dashboard-table-container'>
            <div className='screen-header'>
                <div>
                    <p className='screen-text'>Screen / Players</p>
                    <p className='total-screen-text'>Total Screens - {screens?.length} . Last 5 Screens</p>
                </div>
                <div>
                    <CustomButton onClick={() => navigate("/screens")} rightIcon={<ArrowForwardIosIcon style={{ height: 18, width: 18 }} />} label="View More" background="rgba(13, 110, 253, 1)" />
                </div>
            </div>
            <CustomTable loading={loading} columns={columns} data={filteredScreen} details="No Screen Found!" />
            <div>
                <p className='showing-text'>Showing {filteredScreen?.length} result</p>
            </div>
            <ScreenFormDrawer open={isOpen} toggleDrawer={toggleDrawer} initialValues={initialValues} handleSubmit={handleSubmit} isEdit={true} />
        </div>
    )
}

export default ScreenTable