import React from 'react';

import { Box, Button, Checkbox, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Formik } from 'formik';
import InputField from '../Common/InputField';
import { TimeAlreadyExist, getScheduleValidation } from '../../Utils/Validation';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Area, History } from '../../Utils/Constant';
import { useParams } from 'react-router-dom';
import { app } from '../../Utils/Firebase';
import { get, getDatabase, push, ref, set, update } from 'firebase/database';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import AlertDialog from '../Common/AlertDialog';
import CustomInputField from '../Common/CustomInputField';
import CustomSelect from '../Common/Select';
import moment from 'moment';

const minDate = new Date().toISOString().substr(0, 10);
function ScheduleModel({ open, setOpen, playlist, areaData }) {
    const [playlistOption, setPlaylistOption] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [replacePlaylistId, setReplacePlaylistId] = useState();
    const formikRef = useRef();
    const id = localStorage.getItem("id");
    const { id: areaId } = useParams();
    const db = getDatabase(app);

    const ScheduleValidation = getScheduleValidation(areaData?.playlist);
    const TimeAlreadyExist = (scheduleArray, startDate, endDate, time, daily, play_now) => {
        return scheduleArray.some(schedule => {
            const isDaily = schedule?.daily;
            const isPlayNow = schedule?.play_now;
            const scheduleStartDate = new Date(schedule.startDate)?.setHours(0, 0, 0, 0);
            const scheduleEndDate = new Date(schedule.endDate)?.setHours(0, 0, 0, 0);
            const selectedStartDateObj = new Date(startDate)?.setHours(0, 0, 0, 0);
            const selectedEndDateObj = new Date(endDate)?.setHours(0, 0, 0, 0);
            const selectedTime = time;

            const isStartDateBetween = selectedStartDateObj >= scheduleStartDate && selectedStartDateObj <= scheduleEndDate;
            const isStartDateBetween2 = scheduleStartDate >= selectedStartDateObj && scheduleEndDate <= selectedEndDateObj;

            const isEndDateBetween = selectedEndDateObj >= scheduleStartDate && selectedEndDateObj <= scheduleEndDate;

            const isTimeMatch = schedule.startTime <= selectedTime && schedule?.endTime >= selectedTime;

            if (((isStartDateBetween || isEndDateBetween || isStartDateBetween2 || isDaily || daily) && isTimeMatch) || (isPlayNow && play_now)) {
                setReplacePlaylistId(schedule?.id);
                return true;
            } else {
                return false;
            }

        });
    };

    const handleSubmit = async (values) => {
        const isNotValid = TimeAlreadyExist(areaData?.playlist, values?.startDate, values?.endDate, values?.startTime, values?.daily, values?.play_now);
        console.log("isNotValid   -=-=-=", isNotValid)
        if (isNotValid) {
            setAlertOpen(true);
            // formikRef?.current?.setFieldError("startTime", "Start Time already exits between selected date");
            return;
        }
        const value = {
            ...values,
            id: values?.playlist,
        }
        console.log("v-=---=----=---", values)
        if (values?.play_now) {
            delete value.daily
            delete value.startDate
            delete value.endDate
            delete value.startTime
            delete value.endTime
        }
        if (values?.daily) {
            delete value.startDate
            delete value.endDate
        }
        try {
            const updates = {};
            const areaSnapshot = await get(ref(db, `${Area}/${areaId}`));
            if (areaSnapshot.exists()) {
                const areaData = areaSnapshot.val();
                console.log("areaDtaaaa", areaData)
                const playlistData = areaData?.playlist?.filter(item => item?.id !== value?.id);
                updates['/Area/' + areaId] = {
                    ...areaData,
                    playlist: [...playlistData, value]
                }
            }
            const updateDataaa = await update(ref(db), updates);
            const history = {
                user_id: id,
                title: "Group",
                status: `Playlist successfully scheduled to ${areaData?.name} group.`,
                timestamp: moment(new Date()).toISOString()
            }
            const historyRef = ref(db, History);
            const newHistoryRef = push(historyRef);
            set(newHistoryRef, history);
            toast.success("Schedule saved successfully.", {
                closeButton: <Close />
            });
            setOpen(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (playlist?.length) {
            const filteredData = playlist?.map((item) => {
                return {
                    label: item?.name,
                    value: item?.id
                }
            })
            setPlaylistOption(filteredData);
        }
    }, [playlist])


    const handleDaily = (e) => {
        formikRef?.current?.setFieldValue("daily", e.target.checked);
        if (e.target.checked) {
            formikRef?.current?.setErrors({
                startDate: "",
                endDate: "",
            });
        }
    }

    const handlePlayNow = (e) => {
        formikRef?.current?.setFieldValue("play_now", e.target.checked);
        if (e.target.checked) {
            formikRef?.current?.setFieldValue("daily", true);
        } else {
            formikRef?.current?.setFieldValue("daily", false);
        }
        if (e.target.checked) {
            formikRef?.current?.setErrors({
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
            });
        }
    }

    const handleYes = async () => {
        const value = {
            ...formikRef?.current?.values,
            id: formikRef?.current?.values?.playlist,
        }
        console.log("v-=---=----=---", formikRef?.current?.values)
        if (formikRef?.current?.values?.play_now) {
            delete value.daily
            delete value.startDate
            delete value.endDate
            delete value.startTime
            delete value.endTime
        }
        if (formikRef?.current?.values?.daily) {
            delete value.startDate
            delete value.endDate
        }
        try {
            const updates = {};
            const areaSnapshot = await get(ref(db, `${Area}/${areaId}`));
            if (areaSnapshot.exists()) {
                const areaData = areaSnapshot.val();
                console.log("areaDtaaaa", areaData)
                const playlistData = areaData?.playlist?.filter(item => item?.id !== value?.id);
                const clearedPlaylist = playlistData?.map(item => {
                    if (item?.id === replacePlaylistId) {
                        return {
                            id: item?.id
                        }
                    } else {
                        return {
                            ...item
                        }
                    }
                });
                console.log("fclearedPlaylist", clearedPlaylist)
                updates['/Area/' + areaId] = {
                    ...areaData,
                    playlist: [...clearedPlaylist, value]
                }
            }
            const updateDataaa = await update(ref(db), updates);
            toast.success("Area Saved Successfully.", {
                closeButton: <Close />
            });
            setOpen(false);
            setAlertOpen(false);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            {/* <Button variant="contained" onClick={() => (setOpen(true))} sx={{ width: "180px", background: '#3379d0', height: "40px", color: 'white' }}>
                <AddIcon /> Add Schedule
            </Button> */}
            <Modal
                open={open}
                onClose={() => (setOpen(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => (setOpen(false))} />
                    </Box>
                    <p className="select-playlist-text">Are you sure?</p>
                    <Formik
                        initialValues={{ daily: false, startDate: null, endDate: null, startTime: "", endTime: "", playlist: "", play_now: false }}
                        validationSchema={ScheduleValidation}
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            setFieldError
                        }) => (
                            <form className='schedule-form'>
                                {console.log(values, errors)}
                                <Box sx={{ height: '100%', width: '100%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', height: '100%', width: '100%', marginTop: '20px', overflow: "auto" }}>
                                        <CustomSelect label="Playlist" name="playlist" options={playlistOption} onChange={handleChange} value={values.playlist} error={touched?.playlist && errors.playlist} style={{ width: "100%" }} />
                                        <div className="input-field" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "15px 0" }}>
                                            <Checkbox
                                                name='play_now'
                                                checked={values.play_now}
                                                onChange={(e) => handlePlayNow(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                style={{ padding: '5px' }}
                                            />
                                            <label style={{ margin: 0 }}>Play now content</label>
                                        </div>
                                        <div className="input-field" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "0" }}>
                                            <Checkbox
                                                name='daily'
                                                checked={values.daily}
                                                onChange={(e) => handleDaily(e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                style={{ padding: '5px' }}
                                                disabled={values?.play_now}
                                            />
                                            {console.log('values?.play_now: ', values?.play_now)}
                                            <label style={{ margin: 0 }}>Schedule as daily routine</label>
                                        </div>
                                        <Box sx={{ display: 'flex', width: "100%", gap: "10px" }}>
                                            <CustomInputField type="date" inputProps={{ min: minDate }} disabled={values?.daily || values?.play_now} sx={smallTextField} label="Start Date" name="startDate" onChange={handleChange} value={values.startDate} error={touched?.startDate && errors.startDate} style={{ width: "50%" }} />
                                            <CustomInputField type="date" inputProps={{ min: minDate }} disabled={values?.daily || values?.play_now} sx={smallTextField} label="End Date" name="endDate" onChange={handleChange} value={values.endDate} error={touched?.endDate && errors.endDate} style={{ width: "50%" }} />
                                        </Box>
                                        <Box sx={{ display: 'flex', width: "100%", gap: "10px" }}>
                                            <CustomInputField type="time" sx={smallTextField} label="Start Time" name="startTime" disabled={values?.play_now} onChange={handleChange} value={values.startTime} error={touched?.startTime && errors.startTime} style={{ width: "50%" }} />
                                            <CustomInputField type="time" sx={smallTextField} label="End Time" name="endTime" disabled={values?.play_now} onChange={handleChange} value={values.endTime} error={touched?.endTime && errors.endTime} style={{ width: "50%" }} />
                                        </Box>
                                    </Box>
                                    <button type="submit" onClick={handleSubmit} variant="contained" className='btn12' style={{ padding: "22px 40px", width: "100%" }}>
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            <AlertDialog
                open={alertOpen}
                setOpen={setAlertOpen}
                handleYes={handleYes}
                title="Oops selected start time already exits between selected date"
                description="Do you want to overrite this time between selected date?"
            />
        </div>
    )
}

export default ScheduleModel

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '400px',
    width: {
        xs: '90%',  // 0px - 600px
        sm: '700px',
    },
    height: '80vh',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px",
    padding: {
        xs: '20px',  // 0px - 600px
        sm: '20px 80px 45px',
    },
    overflowY: "auto",
    boxSizing: "border-box"
};

const smallTextField = {
    width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
    "& .MuiInputBase-root ": {
        width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
        height: "40px",
    },
    marginRight: "10px"
}