import React, { useState } from 'react'
import { imageRegex, videoRegex } from '../../Utils/Constant';
import deleteIcon from "../../Assets/delete-icon-red.svg"
import Video from '../Common/Video';
import youtubeImage from "../../Assets/youtube.png";
import webpageImage from "../../Assets/webpage.png";
import clockImage from "../../Assets/clockImage.png";
import cloudImage from "../../Assets/cloudImage.jpg";
import saveIconImage from "../../Assets/saveIcon.svg";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import RenamePlaylistNameModal from './RenamePlaylistNameModal';

function PlaylistCard({ playlist, handleDeleteIcon }) {
    const [renameOpen, setRenameOpen] = useState(false);
    const [renameData, setRenameData] = useState();
    const navigate = useNavigate();

    const handleRenameClose = () => {
        setRenameOpen(false);
    }

    const handleRename = (item) => {
        console.log("dsdsdsdsdsdsdd", item)
        setRenameData(item);
        setRenameOpen(true);
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '20px', marginTop: "35px" }}>
            {playlist?.length ? playlist?.map((p, i) => {
                const url = p?.data?.zone?.[0]?.[0]?.url || p?.data?.zone?.[1]?.[0]?.url;
                const type = p?.data?.zone?.[0]?.[0]?.type || p?.data?.zone?.[1]?.[0]?.type;
                return (<div key={i} className='mediaFileCard'>
                    {imageRegex.test(type) ? <img
                        src={url}
                        className="mediaFileCardImg"
                        alt="image not found"
                    /> : videoRegex.test(type) ?
                        <Video url={url} type={type} style={{ height: "120px" }} />
                        : (type) === 'webpage' ? <img src={webpageImage} alt='webpageImage' className="mediaFileCardImg" /> : (type) === 'stream' ? <img src={youtubeImage} alt='youtube' className="mediaFileCardImg"
                        /> : (type) === 'weather' ? <img src={cloudImage} alt='youtube' className="mediaFileCardImg"
                        /> : (type) === 'clock' ? <img src={clockImage} alt='youtube' className="mediaFileCardImg"
                        /> : <img src={saveIconImage} alt='webpageImage' className="mediaFileCardImg" />}
                    <div className='playlist-content-container'>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: "20px" }}>
                            <p className='medialCard-header'>{p?.name?.length > 25
                                ? p.name.slice(0, 25) + "..."
                                : p.name}</p>
                            {/* <p className='medialCard-desc'>Video</p> */}
                            <p className='medialCard-date'>Date of creation: <span>{p?.timestamp ? moment(p?.timestamp)?.format("MMM DD YYYY") : ""}</span></p>
                        </div>
                        <div className='playlist-button-container' style={{ margin: 0 }}>
                            {/* <img src={duplicateIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} /> */}
                            <img src={deleteIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} />
                        </div>
                    </div>
                    <div className='playlist-button-container'>
                        <button className='rename-btn' onClick={() => handleRename(p)}>Rename</button>
                        <button className='rename-btn' onClick={() => navigate(`/playlist-setting/${p.id}`)}>Edit</button>
                        {/* <img src={deleteIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} /> */}
                    </div>
                </div>)
            }) : <p className='mediafile-not-found'>No Playlist Found</p>}
            <RenamePlaylistNameModal open={renameOpen} handleClose={handleRenameClose} playlistData={renameData} />
        </div>
    )
}

export default PlaylistCard