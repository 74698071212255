import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout/Layout'
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { app } from '../Utils/Firebase';
import { child, equalTo, get, getDatabase, onValue, orderByChild, push, query, ref, set, update } from 'firebase/database';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/auth';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import AddNewScreenModal from '../Components/Modals/AddNewScreenModal';
import notificationIcon from "../Assets/notificationIcon.svg";
import ScreenCard from '../Components/Dashboard/ScreenCard';
import ScreenTable from '../Components/Screens/ScreenTable';
import DateTime from '../Components/Screens/DateTime';
import { fetchFolderSize } from '../Utils/utils';
import saveIconImage from "../Assets/saveIcon.svg";

const defaultValue = {
    pairing_code: "",
    screen_name: "",
    screen_tag: "",
    location: "",
    city: "",
    state: "",
    country: "",
    area: ""
}

function Screens() {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(defaultValue);
    const [formOpen, setFormOpen] = useState(false);
    const [storageSize, setStorageSize] = useState();
    const [screens, setScreens] = useState([]);
    console.log("scrrrr===-=-=", screens)
    const db = getDatabase(app);
    const id = localStorage.getItem("id");
    const max_no_of_screens = user?.no_of_screens;
    const navigate = useNavigate();

    const getStorageSize = async () => {
        try {
            const storage = await fetchFolderSize(id);
            setStorageSize(storage);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        try {
            setLoading(true);
            const screensQuery = query(ref(db, 'Screens'), orderByChild('user_id'), equalTo(id));
            const screensData = [];
            const filterData = onValue(screensQuery, async (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const screenIds = Object.keys(data);
                    console.log(screenIds)
                    // Fetch user data for each screen
                    const screensData = [];
                    let i = 1;
                    for (const screenId of screenIds) {
                        const screen = data[screenId];
                        const pairingSnapshot = await get(ref(db, `pairingCodes/${screen.pairing_code}`));
                        if (pairingSnapshot.exists()) {
                            const pairingData = pairingSnapshot.val();
                            screensData.push({
                                ...screen,
                                id: screen?.pairing_code,
                                status: pairingData?.isOnline ? "Online" : "Offline",
                                index: i
                            });
                        }
                        i++;
                    }

                    setScreens(screensData);
                } else {
                    console.log('No data found');
                }
                setLoading(false);
            });
        } catch (error) {
            console.log("error", error);
        }
        getStorageSize();
    }, [])

    const handleSubmit = async (values, { resetForm, setSubmitting }) => {
        const value = {
            ...values,
            user_id: id,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format("YYYY-MM-DD")
        }
        console.log(value)
        try {
            const dbRef = ref(getDatabase());
            get(child(dbRef, `pairingCodes/${values?.pairing_code}`)).then(async (snapshot) => {
                const spanData = snapshot.val();
                if (snapshot.exists() && !spanData?.userId) {
                    const updates = {};
                    updates['/pairingCodes/' + spanData?.pairingCode] = { ...spanData, isConnected: true, userId: id };
                    const updateData = update(ref(db), updates);
                    await set(ref(db, `Screens/${spanData?.pairingCode}`), value);
                    // const playListRef = ref(db, 'Screens');
                    // const newPlaylistRef = push(playListRef);
                    // set(newPlaylistRef, value);
                    resetForm();
                    setSubmitting(false);
                    toast.success("New Screen added successfully.", {
                        closeButton: <Close />
                    })
                    setFormOpen(false);
                } else {
                    console.log("No data available");
                    toast.error("Pairing code not matched.", {
                        closeButton: <Close />
                    })
                }
            }).catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.log(error)
        }
    }

    const AddScreenHandle = () => {
        if (screens?.length >= max_no_of_screens) {
            toast.error("Maximum Screens limit is over.", {
                closeButton: <Close />
            });
        } else {
            setFormOpen(true);
        }
    }

    const columns = [
        { field: 'pairing_code', headerName: 'Paring Code', width: 150 },
        { field: 'screen_name', headerName: 'Screen Name', width: 250 },
        { field: 'screen_tag', headerName: 'Screen Tag', width: 250 },
        { field: 'startDate', headerName: 'Start Date', width: 150 },
        { field: 'endDate', headerName: 'End Date', width: 150 },
        {
            field: 'status', headerName: 'Status', width: 150,
            renderCell: (params) => (
                <>
                    <p style={{ color: params.row.status ? 'green' : 'red' }}>
                        {params.row.status}
                    </p>
                </>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => (
                <>
                    <div className="cellAction">
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/screen/${params.row.id}`)}
                        >
                            <SettingsIcon />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <div className='breadcrumbs'>
                                <p>{`Home >`}</p>
                                <span>&nbsp; Screens</span>
                            </div>
                        </div>
                        <div className='header-container'>
                            <DateTime />
                            <img src={notificationIcon} alt="notificationIcon" />
                            <div className='header-profile-box'>
                                <img src={user?.image || saveIconImage} className='header-profile-img' alt="notificationIcon" />
                                <p className='header-username-text'>{`${user?.first_name} ${user?.last_name}`}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <div className='screen-card-container'>
                            <ScreenCard screens={screens} storageSize={storageSize} />
                        </div>
                        <ScreenTable screens={screens} loading={loading} />
                    </div>
                </div>
            </div>
            <AddNewScreenModal
                initialValues={initialValues} handleSubmit={handleSubmit} formOpen={formOpen} setFormOpen={setFormOpen} />
        </Layout>
    )
}

export default Screens
