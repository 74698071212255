import { Box, Button, Modal, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { equalTo, get, getDatabase, onValue, orderByChild, query, ref, update } from 'firebase/database';
import { Screens } from '../Utils/Constant';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import { app } from '../Utils/Firebase';
import PlaylistScheduleModel from './Modals/PlaylistScheduleModal';

function PlaylistPublishScreen({ open, setOpen, selectedFile }) {
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [screens, setScreens] = useState([]);
    const [scheduleData, setScheduleData] = useState({ daily: false, startDate: null, endDate: null, startTime: "", endTime: "" });
    const id = localStorage.getItem("id");
    const db = getDatabase(app);
    const [scheduleOpen, setScheduleOpen] = useState(false);


    const handleSelectionModelChange = (newSelection) => {
        setSelectedRowIds(newSelection);
    };

    useEffect(() => {
        try {
            const screensQuery = query(ref(db, Screens), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(screensQuery, async (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const screenIds = Object.keys(data);
                    // Fetch user data for each screen
                    const screensData = [];

                    for (const screenId of screenIds) {
                        const screen = data[screenId];
                        const pairingSnapshot = await get(ref(db, `pairingCodes/${screen.pairing_code}`));
                        if (pairingSnapshot.exists()) {
                            const pairingData = pairingSnapshot.val();
                            screensData.push({
                                ...screen,
                                id: screen?.pairing_code,
                                status: pairingData?.isOnline ? "Online" : "Offline"
                            });
                        }
                    }

                    setScreens(screensData);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [])

    const handlePublishFile = async () => {
        try {
            for (let i = 0; i < selectedRowIds.length; i++) {
                const item = selectedRowIds[i];
                const screenData = screens?.filter(sr => sr?.pairing_code === item);
                delete screenData[0].id;
                delete screenData[0]?.status;
                const updates = {};
                const pairingCode = screenData[0]?.pairing_code;

                if (pairingCode) {
                    updates['/Screens/' + pairingCode] = {
                        ...screenData[0],
                        playlist: scheduleData,
                        isArea: false
                    };
                    await update(ref(db), updates);
                }
            }
            toast.success("File Published Successfully.", {
                closeButton: <Close />
            })
            setOpen(false);
        } catch (error) {
            console.log("error--=-=-", error)
        }
    }

    const columns = [
        { field: 'pairing_code', headerName: 'Paring Code', width: 150 },
        { field: 'screen_name', headerName: 'Screen Name', width: 150 },
        { field: 'screen_tag', headerName: 'Screen Tag', width: 150 },
        {
            field: 'status', headerName: 'Status', width: 150,
            renderCell: (params) => (
                <>
                    <p style={{ color: params.row.status ? 'green' : 'red' }}>
                        {params.row.status}
                    </p>
                </>
            ),
        },
    ];

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{ position: 'relative', overflowY: 'auto' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>{"Select Screens"}</Typography>
                    <div style={{ display: 'flex', gap: "20px", marginBottom: "20px" }}>
                        <PlaylistScheduleModel open={scheduleOpen} setOpen={setScheduleOpen} selectedFile={selectedFile} scheduleData={scheduleData} setScheduleData={setScheduleData} />
                        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
                    </div>
                </Box>
                <div style={{ height: 'auto', width: '100%' }}>
                    <DataGrid
                        rows={screens}
                        columns={columns}
                        checkboxSelection
                        onRowSelectionModelChange={handleSelectionModelChange}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                        <Button type="submit" onClick={handlePublishFile} variant="contained" sx={{ width: "200px", margin: "0 30px 30px 0", background: '#3379d0', height: "40px", color: 'white' }}>
                            Publish
                        </Button>
                    </Box>
                </div>
            </Box>
        </Modal>
    )
}

export default PlaylistPublishScreen

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '80vh',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
};