import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { imageRegex, videoRegex } from '../../Utils/Constant';
import { FormControlLabel, InputLabel, Radio, RadioGroup, TextField } from '@mui/material';
import youtubeImage from "../../Assets/youtube.png";
import webpageImage from "../../Assets/webpage.png";
import deleteIcon from "../../Assets/delete-icon-red.svg"
import Video from '../Common/Video';
import { uploadFiles } from '../../Utils/Firebase';
import FontPicker from 'font-picker-react';


function Zone({ zone, setZone, selectedScreen, handleClose, handleChange, settingData, setSettingData, isFileUploading, setIsFileUploading, isLogoUploading, setIsLogoUploading, zoneSetting, setZoneSetting }) {
    const handleFontChange = (nextFont) => {
        const currentScreen = selectedScreen.i;
        setZoneSetting(prev => ({
            ...prev,
            [currentScreen]: {
                ...prev[currentScreen],
                fontFamily: nextFont?.family
            }
        }));
    };
    const id = localStorage.getItem("id");
    const handleSettingChange = (e) => {
        const { name, value } = e.target;
        setSettingData(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const onFileChange = async (e) => {
        try {
            const file = e.target.files[0];
            const url = await uploadFiles(id, file, setIsFileUploading);
            setIsFileUploading(false);
            setSettingData(prev => ({
                ...prev,
                audio_file: {
                    file_name: file?.name,
                    url: url
                },
            }));
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const onLogoChange = async (e) => {
        try {
            const file = e.target.files[0];
            const url = await uploadFiles(id, file, setIsLogoUploading);
            setIsLogoUploading(false);
            setSettingData(prev => ({
                ...prev,
                logo_file: {
                    file_name: file?.name,
                    url: url
                },
            }));
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const handleZoneSettingChange = (e) => {
        const { name, value } = e.target;
        const currentScreen = selectedScreen.i;
        setZoneSetting(prev => ({
            ...prev,
            [currentScreen]: {
                ...prev[currentScreen],
                [name]: value
            }
        }));
    }

    const handleWidgetStyleChange = (e, item) => {
        const { name, value } = e.target;
        updateWidgetStyle(name, value, item);
    }

    const handleWidgetFontChange = (nextFont, item) => {
        updateWidgetStyle("fontFamily", nextFont, item);
    };

    const updateWidgetStyle = (name, value, item) => {
        // const isZoneArray = Array.isArray(zone); // Check if zone is an array

        // const zoneCopy = isZoneArray ? zone : Object?.values(zone);
        // const updatedZone = zoneCopy.map((screenArray, screenIndex) => {
        //     if (screenIndex === selectedScreen?.i) {
        //         // Map through the array to find the specific item
        //         return screenArray.map((obj) => {
        //             if (obj.id === item.id) {
        //                 // Update the specific object with new value
        //                 return {
        //                     ...obj, style: {
        //                         ...obj?.style,
        //                         [name]: value
        //                     }
        //                 };
        //             }
        //             return obj;
        //         });
        //     }
        //     return screenArray;
        // });

        // // Update your state or zone here
        // setZone(updatedZone);

        const screenIndex = selectedScreen?.i;
        // Function to update the specific item style
        const updateStyle = (items) =>
            items.map((obj) =>
                obj.id === item.id
                    ? { ...obj, style: { ...obj?.style, [name]: value } }
                    : obj
            );

        // Check if zone is an array or an object
        const updatedZone = Array.isArray(zone)
            ? zone.map((screenArray, index) =>
                index === screenIndex ? updateStyle(screenArray) : screenArray
            )
            : {
                ...zone,
                [screenIndex]: updateStyle(zone[screenIndex]),
            };

        // Update your state or zone here
        setZone(updatedZone);
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
            key="zone"
        >
            <h2>Zone</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', width: "100%", padding: "10px", boxSizing: "border-box" }}>
                <div className='music-mute-container'>
                    <p>Music: </p>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="music_mute"
                        value={settingData?.music_mute}
                        onChange={handleSettingChange}
                    >
                        <FormControlLabel value="mute" control={<Radio />} label="Mute" />
                        <FormControlLabel value="video" control={<Radio />} label="Video" />
                        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                    </RadioGroup>
                    {settingData?.music_mute === "custom" && (isFileUploading !== false ? <p>Uploading File...</p> : (
                        <div>
                            <p style={{ fontSize: "14px", textAlign: "start" }}>Upload audio file: </p>
                            <input type="file" accept='audio/*' onChange={onFileChange} />
                        </div>
                    ))}
                    {settingData?.music_mute === "custom" && settingData?.audio_file?.file_name && <p style={{ fontSize: "14px" }}>Uploaded File: {settingData?.audio_file?.file_name}</p>}
                </div>
                <div className='music-mute-container'>
                    <p>Logo: </p>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="logo"
                        value={settingData?.logo}
                        onChange={handleSettingChange}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    {settingData?.logo === "yes" && (isLogoUploading !== false ? <p>Uploading File...</p> : (
                        <div>
                            <p style={{ fontSize: "14px", textAlign: "start" }}>Upload logo: </p>
                            <input type="file" accept='image/*' onChange={onLogoChange} />
                        </div>
                    ))}
                    {settingData?.logo === "yes" && settingData?.logo_file?.file_name && <p style={{ fontSize: "14px" }}>Uploaded logo: {settingData?.logo_file?.file_name}</p>}
                </div>
                {!selectedScreen?.scrollTop && !selectedScreen?.scrollBottom && (selectedScreen?.i || selectedScreen?.i === 0) && <div className='music-mute-container'>
                    <p>Image Direction: </p>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="image_direction"
                        value={zoneSetting?.[selectedScreen.i]?.image_direction}
                        onChange={handleZoneSettingChange}
                    >
                        <FormControlLabel value="ltr" control={<Radio />} label="Left to right" />
                        <FormControlLabel value="rtl" control={<Radio />} label="Right to left" />
                        <FormControlLabel value="ttb" control={<Radio />} label="Top to bottom" />
                        <FormControlLabel value="btt" control={<Radio />} label="Bottom to top" />
                    </RadioGroup>
                    <div style={{ display: 'flex', flexDirection: "column", alignItems: "start" }}>
                        <p>Image Duration: </p>
                        <TextField sx={{ ...textFieldStyle, margin: "5px 0 0" }} placeholder="Enter Duration" name="image_duration" onChange={handleZoneSettingChange} value={zoneSetting?.[selectedScreen.i]?.image_duration} />
                    </div>
                </div>}
            </div>
            <div style={{ margin: 8, width: "100%" }}>
                <Droppable droppableId={"zone"} key={"zone"}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    padding: 4,
                                    width: '100%',
                                    minHeight: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    background: snapshot?.isDraggingOver
                                        ? "lightblue"
                                        : "white",
                                }}
                            >
                                {!(selectedScreen?.scrollTop || selectedScreen?.scrollBottom) && zone?.[selectedScreen?.i]?.map((item, index) => {
                                    return (
                                        <Draggable
                                            key={item?.id}
                                            draggableId={item.id}
                                            index={index}
                                        // disableInteractiveElementBlocking
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            userSelect: "none",
                                                            padding: "8px",
                                                            margin: "0 0 8px 0",
                                                            // height: "126px",
                                                            width: "90%",
                                                            backgroundColor: "#456C86",
                                                            // color: "white",
                                                            position: 'relative',
                                                            background: "rgba(255, 255, 255, 1)",
                                                            boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)",
                                                            borderRadius: "10px",
                                                            ...provided.draggableProps.style
                                                        }}
                                                    >
                                                        {imageRegex.test(item?.type) ? <img
                                                            src={item.url}
                                                            className="card-img-top"
                                                            height={110}
                                                            style={{
                                                                height: "75px",
                                                                width: "100%",
                                                                borderRadius: "10px"
                                                            }}
                                                            alt="image not found"
                                                        /> : videoRegex.test(item?.type) ? <Video url={item?.url} type={item?.type} style={{ margin: '5px', height: "75px" }} /> : item.type === "stream" ? <div>
                                                            <div style={{
                                                                height: "75px",
                                                                width: "100%", border: '1px solid black', boxShadow: '0px 0px 5px 2px grey', borderRadius: '5px', background: "#cccccc", overflow: 'hidden'
                                                            }}>
                                                                <img src={youtubeImage} alt='youtube' style={{ height: '70px', width: '100%' }} />
                                                                <div>
                                                                    <p style={{ margin: 0, color: 'black', fontSize: '16px' }}>{item?.name}</p>
                                                                </div>
                                                            </div>
                                                        </div> : item.type === "webpage" ? <div>
                                                            <div style={{
                                                                height: "75px",
                                                                width: "100%", border: '1px solid black', boxShadow: '0px 0px 5px 2px grey', borderRadius: '5px', background: "#cccccc", overflow: 'hidden'
                                                            }}>
                                                                <img src={webpageImage} alt='youtube' style={{ height: '70px', width: '100%' }} />
                                                                <div>
                                                                    <p style={{ margin: 0, color: 'black', fontSize: '16px' }}>{item?.name}</p>
                                                                </div>
                                                            </div>
                                                        </div> : item.type === "weather" ? <div className='weatherZoneCard'>
                                                            <div>
                                                                <p style={{ margin: 0, color: 'black', fontSize: '16px' }}>{item?.district}</p>
                                                            </div>
                                                        </div> : item.type === "clock" ? <div className='weatherZoneCard'>
                                                            <div>
                                                                <p style={{ margin: 0, color: 'black', fontSize: '16px' }}>{item?.clock_format}</p>
                                                            </div>
                                                        </div> : ""}
                                                        <div className='zone-card-container'>
                                                            <div>
                                                                <p className='zone-card-title'>{item?.name.slice(0, 30)}</p>
                                                                <p className='zone-card-desc'>File Type - {item?.type}</p>
                                                            </div>
                                                            <div>
                                                                <img src={deleteIcon} alt="delete-img" className='zone-card-delete-icon' onClick={() => handleClose(item)} />
                                                            </div>
                                                        </div>
                                                        {/* <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '-5px',
                                                                right: '-5px',
                                                                cursor: 'pointer',
                                                                color: 'black',
                                                                background: 'white',
                                                                height: '20px',
                                                                width: '20px',
                                                                borderRadius: '50%',
                                                                fontSize: "13px"
                                                            }}
                                                            onClick={() => handleClose(item)}
                                                        >
                                                            X
                                                        </div> */}
                                                        {(item?.type === "clock" || item?.type === "weather") && (
                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: "start", alignItems: 'baseline', margin: "0px 10px", boxSizing: "border-box" }}>
                                                                <InputLabel sx={{ color: 'black' }}>Font Family: </InputLabel>
                                                                <FontPicker
                                                                    apiKey="AIzaSyB4ChL2pNol3QAa5Exoyb6wAT6zq6BZjfQ"
                                                                    activeFontFamily={item?.style?.fontFamily || "Open Sans"}
                                                                    onChange={(value) => handleWidgetFontChange(value?.family, item)}
                                                                    pickerId={`main${index}`}
                                                                    className={`apply-font-main${index}`}
                                                                />
                                                                <div style={{ width: "100%", display: 'flex', gap: "10px" }}>
                                                                    <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                                                                        <InputLabel sx={{ color: 'black', marginTop: "10px" }}>Font Color: </InputLabel>
                                                                        <input style={{ width: "100%" }} type="color" name="color" value={item?.style?.color} onChange={(e) => handleWidgetStyleChange(e, item)} />
                                                                    </div>
                                                                    <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                                                                        <InputLabel sx={{ color: 'black', marginTop: "10px" }}>Background Color: </InputLabel>
                                                                        <input style={{ width: "100%" }} type="color" name="background_color" value={item?.style?.background_color} onChange={(e) => handleWidgetStyleChange(e, item)} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: "50%" }}>
                                                                    <InputLabel sx={{ color: 'black', marginTop: "10px", textAlign: 'start' }}>Font Size: </InputLabel>
                                                                    <TextField sx={{ ...textFieldStyle, width: "100%", margin: 0, textAlign: "start" }} placeholder="Enter Size" name="font_size" onChange={(e) => handleWidgetStyleChange(e, item)} value={item?.style?.font_size} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
                {selectedScreen?.scrollTop || selectedScreen?.scrollBottom ? <div className='music-mute-container' style={{ display: 'flex', flexDirection: 'column', alignItems: "start", alignItems: 'baseline', margin: "0px 10px", boxSizing: "border-box" }}>
                    <InputLabel sx={{ color: 'black' }}>Scroll Text</InputLabel>
                    <TextField sx={{ ...textFieldStyle, fontFamily: settingData?.fontFamily, margin: 0 }} placeholder="Enter Text" name="text" onChange={handleChange} value={selectedScreen?.text} />
                    <InputLabel sx={{ color: 'black', marginTop: "10px" }}>Font Family: </InputLabel>
                    <FontPicker
                        apiKey="AIzaSyB4ChL2pNol3QAa5Exoyb6wAT6zq6BZjfQ"
                        activeFontFamily={zoneSetting?.[selectedScreen.i]?.fontFamily}
                        onChange={handleFontChange}
                        id="font-picker"
                        className="font-picker"
                    />
                    <div style={{ width: "100%", display: 'flex', gap: "10px" }}>
                        <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                            <InputLabel sx={{ color: 'black', marginTop: "10px" }}>Font Color: </InputLabel>
                            <input style={{ width: "100%" }} type="color" name="color" value={zoneSetting?.[selectedScreen.i]?.color} onChange={handleZoneSettingChange} />
                        </div>
                        <div style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                            <InputLabel sx={{ color: 'black', marginTop: "10px" }}>Background Color: </InputLabel>
                            <input style={{ width: "100%" }} type="color" name="background_color" value={zoneSetting?.[selectedScreen.i]?.background_color} onChange={handleZoneSettingChange} />
                        </div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <InputLabel sx={{ color: 'black', marginTop: "10px", textAlign: 'start' }}>Font Size: </InputLabel>
                        <TextField sx={{ ...textFieldStyle, width: "100%", margin: 0, textAlign: "start" }} placeholder="Enter Size" name="font_size" onChange={handleZoneSettingChange} value={zoneSetting?.[selectedScreen.i]?.font_size} />
                    </div>
                </div> : ""}
            </div>
        </div>
    )
}

export default Zone

const textFieldStyle = {
    width: "calc(100% - 40px)",
    height: "40px",
    margin: '0px 20px',
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "20px",
    },
    "& .MuiInputBase-root ": {
        width: "100%",
        height: "40px",
    },
    "& .MuiFormControl-root": {
        padding: "0px 10px",
        height: "40px",
    },
};
