import React, { useEffect, useState } from 'react'

function DateTime() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);

        return () => {
            clearInterval(timerID);
        };
    }, []);

    const tick = () => {
        setTime(new Date());
    };
    return (
        <>
            <p className='header-time-text'>{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
            <p className='header-date-text'>{time.toLocaleDateString('en-US')}</p>
        </>
    )
}

export default DateTime