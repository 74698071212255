// validationSchema.js
import * as Yup from 'yup';

export const LoginValidation = Yup.object({
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password must contain at least one letter, one number, and one special character'
        ),
});

export const ResetValidation = Yup.object({
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
});

export const OTPValidation = Yup.object({
    otp: Yup.number().required('OTP is required'),
});

export const NewScreenValidation = Yup.object({
    pairing_code: Yup.string()
        .required('Pairing Code is required'),
    screen_name: Yup.string()
        .required('Screen Name is required'),
});


export const NewPlaylistValidation = Yup.object({
    playlisy_name: Yup.string()
        .required('Playlist Name is required'),
});

export const AreaValidation = Yup.object({
    area_name: Yup.string()
        .required('Group Name is required'),
});

export const UpdateProfileValidation = Yup.object({
    first_name: Yup.string()
        .required('First name is required'),
    last_name: Yup.string()
        .required('Last Name is required'),
});

const dateAndTimeAlreadyExist = (scheduleArray, date, time, isStartDate) => {
    return scheduleArray.some(schedule => {
        // const scheduleDate = isStartDate ? schedule.startDate : schedule.endDate;
        // const scheduleTime = isStartDate ? schedule.startTime : schedule.endTime;
        // const inputDate = new Date(date);
        // const year = inputDate.getFullYear();
        // const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        // const day = inputDate.getDate().toString().padStart(2, '0');

        // const formattedDate = `${year}-${month}-${day}`;
        // console.log("poppopopopopp-----", scheduleDate, formattedDate, scheduleTime, time)

        // console.log(formattedDate); // Output: 2023-12-01
        // return scheduleDate === formattedDate && scheduleTime === time;

        const scheduleStartDate = new Date(schedule.startDate)?.toLocaleDateString('en-GB');
        const scheduleEndDate = new Date(schedule.endDate)?.toLocaleDateString('en-GB');
        const selectedStartDateObj = new Date(date)?.toLocaleDateString('en-GB');
        const selectedEndDateObj = new Date(date)?.toLocaleDateString('en-GB');
        const selectedTime = time;
        console.log("date formatssss", scheduleStartDate, scheduleEndDate, selectedStartDateObj, selectedEndDateObj)

        // Check if selectedStartDate is within the range of existing startDate and endDate
        const isStartDateBetween = selectedStartDateObj >= scheduleStartDate && selectedStartDateObj <= scheduleEndDate;

        // Check if selectedEndDate is within the range of existing startDate and endDate
        const isEndDateBetween = selectedEndDateObj >= scheduleStartDate && selectedEndDateObj <= scheduleEndDate;

        // Check if the selected time matches the existing time
        const isTimeMatch = schedule.startTime === selectedTime;

        // If either startDate or endDate is between the existing range or the time matches, return true
        return (isStartDateBetween || isEndDateBetween) || isTimeMatch;

    });
};

const EndDateAlreadyExist = (scheduleArray, startDate, endDate) => {
    return scheduleArray.some(schedule => {

        const scheduleStartDate = new Date(schedule?.startDate);
        const scheduleEndDate = new Date(schedule?.endDate);
        const selectedStartDateObj = new Date(startDate);
        const selectedEndDateObj = new Date(endDate);

        // Check if selectedStartDate is within the range of existing startDate and endDate
        const isStartDateBetween = scheduleStartDate >= selectedStartDateObj && scheduleEndDate <= selectedEndDateObj;
        // console.log("validationssss-----enddddddddssssssss900", isStartDateBetween, selectedStartDateObj, selectedEndDateObj, scheduleEndDate)

        // If either startDate or endDate is between the existing range or the time matches, return true
        return isStartDateBetween;

    });
};

export const TimeAlreadyExist = (scheduleArray, startDate, endDate, time) => {
    return scheduleArray.some(schedule => {
        const isDaily = schedule?.daily;
        const scheduleStartDate = new Date(schedule.startDate)?.setHours(0, 0, 0, 0);
        const scheduleEndDate = new Date(schedule.endDate)?.setHours(0, 0, 0, 0);
        const selectedStartDateObj = new Date(startDate)?.setHours(0, 0, 0, 0);
        const selectedEndDateObj = new Date(endDate)?.setHours(0, 0, 0, 0);
        const selectedTime = time;
        console.log("date formatssss", scheduleStartDate, scheduleEndDate, selectedStartDateObj, selectedEndDateObj)

        // Check if selectedStartDate is within the range of existing startDate and endDate
        const isStartDateBetween = selectedStartDateObj >= scheduleStartDate && selectedStartDateObj <= scheduleEndDate;
        const isStartDateBetween2 = scheduleStartDate >= selectedStartDateObj && scheduleEndDate <= selectedEndDateObj;

        // Check if selectedEndDate is within the range of existing startDate and endDate
        const isEndDateBetween = selectedEndDateObj >= scheduleStartDate && selectedEndDateObj <= scheduleEndDate;

        // Check if the selected time matches the existing time
        const isTimeMatch = schedule.startTime <= selectedTime && schedule?.endTime >= selectedTime;
        console.log("date issssss", isStartDateBetween, isEndDateBetween, isStartDateBetween2, isTimeMatch, schedule.startTime, selectedTime)

        // If either startDate or endDate is between the existing range or the time matches, return true
        return (isStartDateBetween || isEndDateBetween || isStartDateBetween2 || isDaily) && isTimeMatch;

    });
};

export const getScheduleValidation = (dynamicArray) => {
    return Yup.object().shape({
        daily: Yup.boolean().default(false).nullable(),
        playlist: Yup.string()
            .required('Playlist is required'),
        startDate: Yup.date()
            .when(
                ["play_now", "daily"],
                (daily, schema) => {
                    console.log('schema: ', schema);
                    console.log('daily: ', daily);
                    if (!daily?.[0] && !daily?.[1]) {
                        return schema.required('Start Date is Required');
                    }
                }
            )
            .when(
                "daily",
                (daily, schema) => {
                    if (!daily?.[0]) {
                        return schema.required('Start Date is Required');
                    }
                }
            )
            // .when(
            //     "daily",
            //     (daily, schema) => {
            //         if (!daily?.[0]) {
            //             return schema.test({
            //                 name: 'uniqueStartDateAndTime',
            //                 message: 'Start Date and Time combination already exists',
            //                 test: function (startDate) {
            //                     const startTime = this.parent.startTime;
            //                     // console.log("startDate=-=-pppp", startDate, startTime)
            //                     if (!startDate) {
            //                         return true;
            //                     }

            //                     const scheduleArray = dynamicArray; // Replace with your actual array
            //                     return !dateAndTimeAlreadyExist(scheduleArray, startDate, startTime, true);
            //                 },
            //             });
            //         }
            //     }
            // )
            .nullable(),
        endDate: Yup.date()
            .when(
                ["play_now", "daily"],
                (daily, schema) => {
                    if (!daily?.[0] && !daily?.[1]) {
                        return schema.required('End Date is Required');
                    }
                }
            )
            .when(
                "daily",
                (daily, schema) => {
                    if (!daily?.[0]) {
                        return schema.required('End Date is Required');
                    }
                }
            )
            .when(
                "startDate",
                (startDate, schema) => {
                    if (startDate?.[0]) {
                        return schema.min(startDate, 'End Date should not be before Start Date');
                    }
                }
            )
            // .when(
            //     "daily",
            //     (daily, schema) => {
            //         if (!daily?.[0]) {
            //             return schema.test({
            //                 name: 'uniqueEndDateAndTime',
            //                 message: 'End Date and Time combination already exists',
            //                 test: function (endDate) {
            //                     const endTime = this.parent.endTime;

            //                     if (!endDate) {
            //                         return true;
            //                     }
            //                     const scheduleArray = dynamicArray;
            //                     return !dateAndTimeAlreadyExist(dynamicArray, endDate, endTime, false);
            //                 },
            //             });
            //         }
            //     }
            // )
            // .when(
            //     "daily",
            //     (daily, schema) => {
            //         if (!daily?.[0]) {
            //             return schema.test({
            //                 name: 'uniqueEndDate',
            //                 message: 'Between Start Date and End Date already exits',
            //                 test: function (endDate) {
            //                     const endTime = this.parent.endTime;
            //                     const startDate = this.parent.startDate;
            //                     if (!endDate) {
            //                         return true;
            //                     }
            //                     console.log("wdsdsdsdsdds0---0--0-00", endDate)
            //                     const scheduleArray = dynamicArray;
            //                     return !EndDateAlreadyExist(dynamicArray, startDate, endDate);
            //                 },
            //             });
            //         }
            //     }
            // )
            .nullable(),
        startTime: Yup.string().when(
            "play_now",
            (daily, schema) => {
                if (!daily?.[0]) {
                    return schema.required('Start Time is Required');
                }
            }
        ).nullable()
        // .when(
        //     "startDate",
        //     (startDate, schema) => {
        //         console.log({ startDate })
        //         if (!startDate?.[0]) {
        //             return schema.test({
        //                 name: 'uniqueSttartTimeDate',
        //                 message: 'First Select start Date is Required',
        //                 test: function (startDate) {
        //                     return false;
        //                 },
        //             });;
        //         }
        //     }
        // )
        // .when(
        //     "startDate",
        //     (startdate, schema) => {
        //         if (startdate?.[0]) {
        //             return schema.test({
        //                 name: 'uniqueEndtime',
        //                 message: 'Start Time already exits between selected date',
        //                 test: function (time) {
        //                     const endTime = this.parent.endTime;
        //                     const startDate = this.parent.startDate;
        //                     const endDate = this.parent.endDate;
        //                     console.log("wdsdsdsdsdds0---0--0-00", time)
        //                     if (!time) {
        //                         return true;
        //                     }
        //                     const scheduleArray = dynamicArray;
        //                     return !TimeAlreadyExist(dynamicArray, startDate, endDate, time);
        //                 },
        //             });
        //         }
        //     }
        // ),
        ,
        endTime: Yup.string().when(
            "play_now",
            (daily, schema) => {
                if (!daily?.[0]) {
                    return schema.required('End Time is Required');
                }
            }
        ).nullable()
        // .test({
        //     name: 'endTimeAfterStartTime',
        //     message: 'End Time should be after Start Time',
        //     test: function (endTime) {
        //         const startTime = this.parent.startTime;

        //         if (!startTime || !endTime) {
        //             // Ignore validation if any of the fields is not set
        //             return true;
        //         }

        //         // Convert time strings to Date objects for comparison
        //         const startTimeDate = new Date(`1970-01-01T${startTime}`);
        //         const endTimeDate = new Date(`1970-01-01T${endTime}`);

        //         return endTimeDate > startTimeDate;
        //     },
        // }),
    });
}


export const PlaylistScheduleValidation = () => {
    return Yup.object().shape({
        daily: Yup.boolean().default(false).nullable(),
        startDate: Yup.date()
            .when(
                "daily",
                (daily, schema) => {
                    if (!daily?.[0]) {
                        return schema.required('Start Date is Required');
                    }
                }
            )
            .nullable(),
        endDate: Yup.date()
            .when(
                "daily",
                (daily, schema) => {
                    if (!daily?.[0]) {
                        return schema.required('End Date is Required');
                    }
                }
            )
            .when(
                "startDate",
                (startDate, schema) => {
                    if (startDate?.[0]) {
                        return schema.min(startDate, 'End Date should not be before Start Date');
                    }
                }
            )
            .nullable(),
        startTime: Yup.string().required('Start Time is required'),
        endTime: Yup.string().required('End Time is required')
            .test({
                name: 'endTimeAfterStartTime',
                message: 'End Time should be after Start Time',
                test: function (endTime) {
                    const startTime = this.parent.startTime;

                    if (!startTime || !endTime) {
                        return true;
                    }
                    const startTimeDate = new Date(`1970-01-01T${startTime}`);
                    const endTimeDate = new Date(`1970-01-01T${endTime}`);

                    return endTimeDate > startTimeDate;
                },
            }),
    });
}

export const ChangePasswordValidation = Yup.object({
    oldPassword: Yup.string()
        .required('Password is required'),
    newPassword: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password must contain at least one letter, one number, and one special character'
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'New Password and Confirm Password are different.')
        .required('Confirm Password is required'),
});

export const NewPasswordValidation = Yup.object({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password must contain at least one letter, one number, and one special character'
        ),
});

export const NewYoutubeValidation = Yup.object({
    name: Yup.string()
        .required('Name is required'),
    url: Yup.string().url('Invalid URL format')
        .required('Url is required'),
});

export const NewWebpageValidation = Yup.object({
    name: Yup.string()
        .required('Name is required'),
    url: Yup.string().url('Invalid URL format')
        .required('Url is required'),
    refresh_time: Yup.string()
        .required('Refresh time is required'),
});

export const NewWeatherValidation = Yup.object({
    name: Yup.string()
        .required('Name is required'),
    state: Yup.string()
        .required('State is required'),
    district: Yup.string()
        .required('District is required'),
});

export const NewClockValidation = Yup.object({
    name: Yup.string()
        .required('Name is required'),
    clock_format: Yup.string()
        .required('Clock format is required'),
});

export const RenameFileNameValidation = Yup.object({
    name: Yup.string()
        .required('File name is required'),
});

export const FolderNameValidation = Yup.object({
    name: Yup.string()
        .required('Folder name is required'),
});

