import { Backdrop, Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Fade from '@mui/material/Fade';
import successImage from "../../Assets/success-back.svg"
import checkIcon from "../../Assets/checkIcon.svg"
import CloseIcon from '@mui/icons-material/Close';
import CustomInputField from '../Common/CustomInputField';
import { get, getDatabase, ref, update } from 'firebase/database';
import { app } from '../../Utils/Firebase';
import { Formik } from 'formik';
import { RenameFileNameValidation } from '../../Utils/Validation';
import { Media, Playlist } from '../../Utils/Constant';
import AlertModal from '../Common/AlertModal';

const defaultValues = { name: "" };

function RenamePlaylistNameModal({ open, handleClose, playlistData }) {
    const [initialValues, setInitialValues] = useState(defaultValues);
    const [successOpen, setSuccessOpen] = useState(false);
    const db = getDatabase(app);

    useEffect(() => {
        if (playlistData) {
            setInitialValues({
                name: playlistData?.name
            })
        }
    }, [playlistData])


    const handleUpdateChanges = async (values) => {
        try {
            if (playlistData?.id) {
                const updates = {};
                updates[`/${Playlist}/` + playlistData?.id] = {
                    ...playlistData,
                    name: values?.name,
                };
                console.log("updaaaaa", updates)
                await update(ref(db), updates);
                handleClose();
                setSuccessOpen(true);
                setTimeout(() => {
                    setSuccessOpen(false)
                }, 4000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (<>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={RenameFileNameValidation}
                        onSubmit={handleUpdateChanges}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <>
                                <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div>
                                <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={handleClose} /></div>
                                <div className='modal-container'>
                                    <p className='modal-title'>{"Rename Playlist"}</p>
                                    <CustomInputField label="Name" name="name" onChange={handleChange} error={touched?.name && errors.name} value={values?.name} style={{ width: "80%" }} />
                                    <button type='submit' onClick={handleSubmit} disabled={isSubmitting} className='btn12' style={{ padding: "22px 40px", width: "80%" }}>
                                        Update Changes
                                    </button>
                                </div>
                            </>
                        )}
                    </Formik >
                </Box>
            </Fade>
        </Modal>
        <AlertModal
            open={successOpen}
            handleClose={() => setSuccessOpen(false)}
            title={`Changes Updated`}
            desc={"Playlist name changed successfully"}
            icon={checkIcon}
            success={true}
        />
    </>
    )
}

export default RenamePlaylistNameModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: "90%",
        sm: '650px',
    },
    height: '400px',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px"
};