import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { NewScreenValidation } from '../../Utils/Validation'
import InputField from '../Common/InputField'
import { Formik } from 'formik'
import CustomInputField from '../Common/CustomInputField'
import { useMediaQuery } from 'react-responsive';

function ScreenFormDrawer({ open, toggleDrawer, initialValues, handleSubmit, isEdit, isTransfer }) {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 720px)' });
    return (
        <Drawer
            open={open}
            onClose={toggleDrawer}
            direction='right'
            size={isLargeScreen ? '550px' : '300px'}
            lockBackgroundScroll
            style={{
                height: '100%',
                overflow: 'auto'
            }}
        >
            <Box sx={{ padding: "20px" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'sticky' }}>
                    <Typography variant='h6' sx={{ fontSize: "24px", fontWeight: 500 }}>{isEdit || isTransfer ? "Edit" : "Add New Screen"}</Typography>
                </Box>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={NewScreenValidation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            getFieldProps
                            /* and other goodies */
                        }) => (
                            <form className='form-container'>
                                <Box sx={{ height: '100%', width: '100%', marginTop: '20px' }}>
                                    <CustomInputField label="Pairing Code" name="pairing_code" disabled={isEdit || isTransfer} onChange={handleChange} value={values.pairing_code} error={touched?.pairing_code && errors.pairing_code} />
                                    {isTransfer && <CustomInputField label="New Pairing Code" name="new_pairing_code" onChange={handleChange} value={values.new_pairing_code} error={errors.new_pairing_code} />}
                                    <CustomInputField label="Screen Name" name="screen_name" onChange={handleChange} value={values.screen_name} error={touched?.screen_name && errors.screen_name} />
                                    <CustomInputField label="Screen Tag" name="screen_tag" onChange={handleChange} />
                                    <CustomInputField label="Enter Location" name="location" onChange={handleChange} />
                                    <Box sx={{ display: 'flex', gap: "10px" }}>
                                        <CustomInputField label="City" name="city" onChange={handleChange} />
                                        <CustomInputField label="State" name="state" onChange={handleChange} />
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: "10px" }}>
                                        <CustomInputField label="Country" name="country" onChange={handleChange} />
                                        <CustomInputField label="Area Code" name="area" onChange={handleChange} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                                    <button type="submit" className='btn12 submitBtn' onClick={handleSubmit} variant="contained" sx={{ width: "200px", margin: "0 30px 30px 0", background: '#3379d0', height: "40px", color: 'white' }}>
                                        {isEdit ? isSubmitting ? "Loading..." : "Update Screen" : isSubmitting ? "Loading..." : "Pair Screen"}
                                    </button>
                                </Box>
                            </form>
                        )}
                    </Formik >
                </Box>
            </Box>
        </Drawer>
    )
}

export default ScreenFormDrawer

const smallTextField = {
    width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
    "& .MuiInputBase-root ": {
        width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
        height: "40px",
    },
    marginRight: "10px"
}