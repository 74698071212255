import React from 'react'
import { imageRegex, videoRegex } from '../../Utils/Constant'
import youtubeImage from "../../Assets/youtube.png";
import webpageImage from "../../Assets/webpage.png";
import Video from './Video';

function SingleCollapse({ item, zone }) {
    return (
        <>
            {imageRegex.test(item?.type || zone?.[item?.i]?.[0]?.type) ? <img
                src={item.url || zone?.[item?.i]?.[0]?.url}
                className="card-img-top"
                style={{
                    height: "100%",
                    width: "100%",
                }}
                alt="image not found"
            /> : videoRegex.test(item.type || zone?.[item?.i]?.[0]?.type) ? <Video url={item?.url || zone?.[item?.i]?.[0]?.url} type={item?.type || zone?.[item?.i]?.[0]?.type} /> : (item?.type || zone?.[item?.i]?.[0]?.type) === 'stream' ? <div style={{
                height: "100%",
                width: "100%", borderRadius: '10px', background: "#cccccc",
                display: 'flex',
                flexDirection: 'column',
                boxSizing: "border-box"
            }}>
                <img src={youtubeImage} alt='youtube' style={{ height: '85%', width: '100%' }} />
                <div>
                    <p style={{ margin: 0, color: 'black', fontSize: '16px', height: "15%" }}>{item?.name || zone?.[item?.i]?.[0]?.name}</p>
                </div>
                {/* <ReactPlayer url={sc.url || ""} width="100%" height="100%" /> */}
            </div> : (item?.type || zone?.[item?.i]?.[0]?.type) === 'webpage' ? <div style={{
                height: "100%",
                width: "100%", borderRadius: '10px', background: "#cccccc",
                display: 'flex',
                flexDirection: 'column',
                boxSizing: "border-box"
            }}>
                <img src={webpageImage} alt='youtube' style={{ height: '85%', width: '100%' }} />
                <div>
                    <p style={{ margin: 0, color: 'black', fontSize: '16px', height: "15%" }}>{item?.name || zone?.[item?.i]?.[0]?.name}</p>
                </div>
                {/* <ReactPlayer url={sc.url || ""} width="100%" height="100%" /> */}
            </div> : (item?.type || zone?.[item?.i]?.[0]?.type) === 'weather' ? <div className='weatherTvScreenCard'>
                <p style={{ margin: 0, color: 'black', fontSize: '16px', height: "15%" }}>{item?.district || zone?.[item?.i]?.[0]?.district}</p>
                <p style={{ margin: 0, color: 'black', fontSize: '16px', height: "15%" }}>{item?.name || zone?.[item?.i]?.[0]?.name}</p>
            </div> : (item?.type || zone?.[item?.i]?.[0]?.type) === 'clock' ? <div className='weatherTvScreenCard'>
                <div>
                    <p style={{ margin: 0, color: 'black', fontSize: '16px', height: "15%" }}>{item?.clock_format || zone?.[item?.i]?.[0]?.clock_format}</p>
                </div>
            </div> : <div className='select-section-box'><p>Select section to add image/Video</p></div>}
        </>
    )
}

export default SingleCollapse