import React from 'react';

import { Box, Button, Checkbox, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Formik } from 'formik';
import InputField from '../Common/InputField';
import { PlaylistScheduleValidation } from '../../Utils/Validation';
import { useRef } from 'react';
import { useState } from 'react';

function PlaylistScheduleModel({ open, setOpen, selectedFile, scheduleData, setScheduleData }) {
    const formikRef = useRef();

    const handleSubmit = async (values) => {
        const value = {
            ...values,
            id: selectedFile?.id,
        }
        if (values?.daily) {
            delete value.startDate
            delete value.endDate
        }
        setScheduleData(value);
        setOpen(false);
    }

    const handleDaily = (e) => {
        formikRef?.current?.setFieldValue("daily", e.target.checked);
        if (e.target.checked) {
            formikRef?.current?.setErrors({
                startDate: "",
                endDate: "",
            });
        }
    }

    return (
        <div>
            <Button variant="contained" onClick={() => (setOpen(true))} sx={{ width: "180px", background: '#3379d0', height: "40px", color: 'white' }}>
                <AddIcon /> Add Schedule
            </Button>
            <Modal
                open={open}
                onClose={() => (setOpen(false))}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ position: 'relative', overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='h6'>Add Schedule</Typography>
                        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => (setOpen(false))} />
                    </Box>
                    <Formik
                        initialValues={scheduleData}
                        validationSchema={PlaylistScheduleValidation}
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form>
                                {console.log(values, errors)}
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', height: '100%', width: '100%', marginTop: '20px' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Checkbox
                                            name='daily'
                                            checked={values.daily}
                                            onChange={(e) => handleDaily(e)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            style={{ padding: '5px' }}
                                        />
                                        <label>Routine Daily</label>
                                    </Box>
                                    {/* <CustomSelect label="Playlist" name="playlist" options={playlistOption} onChange={handleChange} value={values.playlist} error={touched?.playlist && errors.playlist} /> */}
                                    <Box sx={{ display: 'flex' }}>
                                        <InputField type="date" disabled={values?.daily} sx={smallTextField} label="Start Date" name="startDate" onChange={handleChange} value={values.startDate} error={touched?.startDate && errors.startDate} />
                                        <InputField type="date" disabled={values?.daily} sx={smallTextField} label="End Date" name="endDate" onChange={handleChange} value={values.endDate} error={touched?.endDate && errors.endDate} />
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <InputField type="time" sx={smallTextField} label="Start Time" name="startTime" onChange={handleChange} value={values.startTime} error={touched?.startTime && errors.startTime} />
                                        <InputField type="time" sx={smallTextField} label="End Time" name="endTime" onChange={handleChange} value={values.endTime} error={touched?.endTime && errors.endTime} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                                    <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ width: "200px", margin: "0 30px 30px 0", background: '#3379d0', height: "40px", color: 'white' }}>
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </div>
    )
}

export default PlaylistScheduleModel

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '60vh',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
};

const smallTextField = {
    width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
    "& .MuiInputBase-root ": {
        width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
        height: "40px",
    },
    marginRight: "10px"
}