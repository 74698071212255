import React from 'react'
import Layout from '../Components/Layout/Layout'
import Setting from '../Components/Setting'

function SettingPage() {
    return (
        <Layout>
            <Setting />
        </Layout>
    )
}

export default SettingPage