import { FormHelperText, InputLabel, TextField } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import "../../Css/Screens.css";

function CustomInputField({ label, name, error, ErrorMessage, onChange, style, ...params }) {
    return (
        <>
            <div className="input-field" style={style}>
                <InputLabel>{label}</InputLabel>
                <Field
                    name={name}
                    variant="outlined"
                    className={`${error ? "input-error-border" : ""}`}
                    placeholder={label}
                    error={error}
                    onChange={onChange}
                    {...params}
                />
                {error && <p className="input-error-message">{error}</p>}
            </div>
        </>
    )
}

export default CustomInputField

