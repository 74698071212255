import { Autocomplete, Box, Button, FormHelperText, InputLabel, Modal, TextField, Typography } from '@mui/material'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import InputField from '../Common/InputField'
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import { NewClockValidation, NewWeatherValidation, NewWebpageValidation, NewYoutubeValidation } from '../../Utils/Validation';
import { toast } from 'react-toastify';
import { getDatabase, push, ref, set } from 'firebase/database';
import { app } from '../../Utils/Firebase';
import { AllWidgets, clockFormatOption, WebpageWidgets, YoutubeWidgets } from '../../Utils/Constant';
import { Close } from '@mui/icons-material';
import CustomInputField from '../Common/CustomInputField';
import successImage from "../../Assets/success-back.svg"
import AlertModal from '../Common/AlertModal';
import checkIcon from "../../Assets/checkIcon.svg"
import CustomSelect from '../Common/Select';
import { getAllCities, getAllState } from '../../Utils/utils';

function AddWidgetModal({ open, setOpen, selectedWidget }) {
    const [youtubeUrl, setYoutubeUrl] = useState("");
    const [successOpen, setSuccessOpen] = useState(false);
    const [allState, setAllState] = useState([]);
    const [cities, setCities] = useState([]);
    const formikRef = useRef();
    const db = getDatabase(app);
    const id = localStorage.getItem("id");

    const handleAddYoutube = async (values) => {
        try {
            console.log(values);
            const playListRef = ref(db, YoutubeWidgets);
            const newPlaylistRef = push(playListRef);
            set(newPlaylistRef, {
                url: values.url, type: 'stream', user_id: id, name: values?.name
            });
            setOpen(false);
            setSuccessOpen(true);
            setTimeout(() => {
                setSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const state = getAllState();
        setAllState(state);
    }, []);

    const handleAddWebpage = async (values) => {
        try {
            console.log(values);
            const widgetRef = ref(db, WebpageWidgets);
            const newWidgetRef = push(widgetRef);
            set(newWidgetRef, {
                url: values.url, type: 'webpage', user_id: id, name: values?.name, refresh_time: values?.refresh_time
            });
            setOpen(false);
            setSuccessOpen(true);
            setTimeout(() => {
                setSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddWidget = async (values) => {
        try {
            const widgetRef = ref(db, AllWidgets);
            const newWidgetRef = push(widgetRef);
            set(newWidgetRef, values);
            setOpen(false);
            setSuccessOpen(true);
            setTimeout(() => {
                setSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddClockWidget = async (values) => {
        try {
            delete values?.url;
            const payload = {
                ...values,
                type: "clock",
                user_id: id,
            }
            handleAddWidget(payload);
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddWeather = async (values) => {
        try {
            delete values?.url;
            const payload = {
                ...values,
                type: "weather",
                user_id: id,
            }
            handleAddWidget(payload);
        } catch (error) {
            console.log(error);
        }
    }

    const handleStateChange = async (newValue, setFieldValue) => {
        try {
            setFieldValue("state", newValue?.name);
            const cities = getAllCities(newValue?.code);
            setCities(cities);
        } catch (error) {
            console.log('error: ', error);
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, height: selectedWidget === "webpage" || selectedWidget === "weather" ? "600px" : "500px" }}>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Typography variant='h6'>{selectedWidget === "youtube" ? "Add Youtube Url" : "Add Webpage Url"}</Typography>
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
                </Box> */}
                    {selectedWidget === "youtube" ?
                        <Formik
                            initialValues={{ name: "", url: "" }}
                            validationSchema={NewYoutubeValidation}
                            onSubmit={handleAddYoutube}
                            innerRef={formikRef}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                            }) => (
                                <form style={{ height: "100%" }}>
                                    {console.log(values)}
                                    <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div>
                                    <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={() => setOpen(false)} /></div>
                                    <div className='add-playlist-modal-container'>
                                        <p className='modal-title'>{"Add Youtube Link"}</p>
                                        <CustomInputField label="Name" name="name" onChange={handleChange} value={values.name} error={touched?.name && errors.name} style={{ width: "80%" }} />
                                        <CustomInputField label="Youtube Url" name="url" onChange={(e) => (setFieldValue("url", e.target.value), setYoutubeUrl(e.target.value))} value={values.url} error={touched?.url && errors.url} style={{ width: "80%" }} />
                                        <button className='btn12' type="submit" onClick={handleSubmit} style={{ padding: "22px 40px", width: "80%" }}>
                                            {isSubmitting ? "Submitting..." : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik> : selectedWidget === "webpage" ?
                            <Formik
                                initialValues={{ name: "", url: "" }}
                                validationSchema={NewWebpageValidation}
                                onSubmit={handleAddWebpage}
                                innerRef={formikRef}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                }) => (
                                    <form style={{ height: "100%" }}>
                                        {console.log(values)}
                                        <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div>
                                        <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={() => setOpen(false)} style={{ width: "80%" }} /></div>
                                        <div className='add-playlist-modal-container'>
                                            <p className='modal-title'>{"Add Webpage Link"}</p>
                                            <CustomInputField label="Webpage Name" name="name" onChange={handleChange} value={values.name} error={touched?.name && errors.name} style={{ width: "80%" }} />
                                            <CustomInputField label="Webpage Url" name="url" onChange={(e) => (setFieldValue("url", e.target.value), setYoutubeUrl(e.target.value))} value={values.url} error={touched?.url && errors.url} style={{ width: "80%" }} />
                                            <CustomInputField label="Refresh Time (In Seconds)" name="refresh_time" onChange={handleChange} value={values.refresh_time} error={touched?.refresh_time && errors.refresh_time} style={{ width: "80%" }} />
                                            <button type="submit" onClick={handleSubmit} className='btn12' style={{ padding: "22px 40px", width: "80%" }}>
                                                {isSubmitting ? "Submitting..." : "Submit"}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </Formik> : selectedWidget === "weather" ?
                                <Formik
                                    initialValues={{ name: "", district: "", state: "" }}
                                    validationSchema={NewWeatherValidation}
                                    onSubmit={handleAddWeather}
                                    innerRef={formikRef}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue
                                    }) => (
                                        <form style={{ height: "100%" }}>
                                            {console.log(values)}
                                            <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div>
                                            <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={() => setOpen(false)} /></div>
                                            <div className='add-playlist-modal-container'>
                                                <p className='modal-title'>{"Add Weather"}</p>
                                                <CustomInputField label="Name" name="name" onChange={handleChange} value={values.name} error={touched?.name && errors.name} style={{ width: "80%" }} />
                                                <div style={{ width: "80%" }}>
                                                    <p className='input-label'>State</p>
                                                    <Autocomplete
                                                        options={allState}
                                                        value={values.state || ""}
                                                        onChange={(event, newValue) => handleStateChange(newValue, setFieldValue)}
                                                        renderInput={(params) => <TextField {...params} label="Select State" variant="outlined" />}
                                                        getOptionLabel={(option) => (typeof option === "string" ? option : option?.name)}
                                                        style={{ background: "white", borderRadius: "8px" }}
                                                        label=""
                                                    />
                                                    {touched?.state && errors?.state && (
                                                        <p
                                                            className='input-error-message'
                                                        >
                                                            {errors.state}
                                                        </p>
                                                    )}
                                                </div>
                                                <div style={{ width: "80%" }}>
                                                    <p className='input-label'>City</p>
                                                    <Autocomplete
                                                        options={cities}
                                                        value={values.district || ""}
                                                        onChange={(event, newValue) => setFieldValue("district", newValue)}
                                                        renderInput={(params) => <TextField {...params} label="Select City" variant="outlined" />}
                                                        style={{ background: "white", borderRadius: "8px" }}
                                                        disabled={!values.state}
                                                        label=""
                                                    />
                                                    {touched?.district && errors?.district && (
                                                        <p
                                                            className='input-error-message'
                                                        >
                                                            {errors.district}
                                                        </p>
                                                    )}
                                                </div>
                                                {/* <CustomInputField label="District" name="district" onChange={handleChange} value={values.district} error={touched?.district && errors.district} style={{ width: "80%" }} /> */}
                                                <button className='btn12' type="submit" onClick={handleSubmit} style={{ padding: "22px 40px", width: "80%" }}>
                                                    {isSubmitting ? "Submitting..." : "Submit"}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik> : selectedWidget === "clock" ?
                                    <Formik
                                        initialValues={{ name: "", clock_format: "" }}
                                        validationSchema={NewClockValidation}
                                        onSubmit={handleAddClockWidget}
                                        innerRef={formikRef}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue
                                        }) => (
                                            <form style={{ height: "100%" }}>
                                                {console.log(values)}
                                                <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div>
                                                <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={() => setOpen(false)} /></div>
                                                <div className='add-playlist-modal-container'>
                                                    <p className='modal-title'>{"Add Clock"}</p>
                                                    <CustomInputField label="Name" name="name" onChange={handleChange} value={values.name} error={touched?.name && errors.name} style={{ width: "80%" }} />
                                                    <CustomSelect label="Clock format" name="clock_format" options={clockFormatOption} onChange={handleChange} value={values.clock_format} error={touched?.clock_format && errors.clock_format} style={{ width: "80%" }} />
                                                    <button className='btn12' type="submit" onClick={handleSubmit} style={{ padding: "22px 40px", width: "80%" }}>
                                                        {isSubmitting ? "Submitting..." : "Submit"}
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik> : ""}
                </Box>
            </Modal>
            <AlertModal
                open={successOpen}
                handleClose={() => setSuccessOpen(false)}
                title={`Widget added successfully`}
                desc={""}
                icon={checkIcon}
                success={true}
            />
        </>
    )
}

export default AddWidgetModal;


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',  // 0px - 600px
        sm: '650px',
    },
    height: '500px',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px"
};

const textFieldStyle = {
    width: { xs: 300, sm: 300, md: 270, lg: 300, xl: 300 },
    height: "40px",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "20px",
    },
    "& .MuiInputBase-root ": {
        width: { xs: 300, sm: 300, md: 270, lg: 300, xl: 300 },
        height: "40px",
    },
    "& .MuiFormControl-root": {
        padding: "0px 10px",
        height: "40px",
    },
};