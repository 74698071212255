import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CustomTabs({ tabItems, tabStyle, tabsSx }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: { xs: "100%", sm: "50%" }, ...tabStyle }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{
                    style: {
                        backgroundColor: 'rgba(37, 37, 37, 1)', // Change the indicator color
                    },
                }} sx={tabsSx}>
                    {tabItems?.map((item, i) => (
                        <Tab label={item?.title} {...a11yProps(i)} style={{
                            margin: "10px 0px 0px 0px", fontWeight: value === i ? 'bold' : 'normal',
                            color: value === i ? 'rgba(37, 37, 37, 1)' : 'rgba(160, 174, 192, 1)',
                            textTransform: "initial",
                            fontSize: "16px",
                            fontWeight: "600"
                        }} />
                    ))}
                </Tabs>
            </Box>
            {tabItems?.map((item, i) => {
                return (
                    <CustomTabPanel value={value} index={i}>
                        {item?.component}
                    </CustomTabPanel>
                )
            })}
        </Box>
    );
}