import React from 'react'
import duplicateIcon from "../../Assets/saveIcon.svg"
import { useNavigate } from 'react-router-dom';
import deleteIcon from "../../Assets/delete-icon-red.svg"

function AreaCard({ areas, handleDeleteIcon, handleRenameClick }) {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '20px', marginTop: "35px" }}>
            {areas?.length ? areas?.map((p, i) => {
                return (<div key={i} className='group-card'>
                    <img
                        src={duplicateIcon}
                        className="mediaFileCardImg"
                        alt="image not found"
                    />
                    <div className='playlist-content-container'>
                        <div>
                            <p className='group-card-title'>{p?.name?.length > 25
                                ? p.name.slice(0, 25) + "..."
                                : p.name}</p>
                        </div>
                        <img src={deleteIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} />
                    </div>
                    <div className='playlist-button-container' style={{ alignItems: "center" }}>
                        <button className='rename-btn' onClick={() => handleRenameClick(p)}>Rename</button>
                        <button className='rename-btn' onClick={() => navigate(`/area-group/${p?.id}`)}>Edit</button>
                    </div>
                </div>)
            }) : <p className='mediafile-not-found'>No Area Found</p>}
        </div>
    )
}

export default AreaCard