import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout/Layout'
import { Typography } from '@mui/material'
import { child, equalTo, get, getDatabase, onValue, orderByChild, query, ref, set, update } from 'firebase/database';
import { app } from '../Utils/Firebase';
import { useAuth } from '../Context/auth';
import { checkAvailableStorage, fetchFolderSize } from '../Utils/utils';
import { toast } from 'react-toastify';
import ScreenCard from '../Components/Dashboard/ScreenCard';
import StatisticsSide from '../Components/Dashboard/StatisticsSide';
import CustomTable from '../Components/Common/Table';
import ScreenTable from '../Components/Dashboard/ScreenTable';
import ActivityLogs from '../Components/Dashboard/ActivityLogs';
import ScreenFormDrawer from '../Components/Screens/ScreenFormDrawer';
import AlertModal from '../Components/Common/AlertModal';
import moment from 'moment';
import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import errorIcon from "../Assets/error-icn.svg";
import successIcon from "../Assets/success-icon.svg";
import lockIcon from "../Assets/whiteLock.svg"
import CustomButton from '../Components/Common/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const defaultValue = {
    pairing_code: "",
    screen_name: "",
    screen_tag: "",
    location: "",
    city: "",
    state: "",
    country: "",
    area: ""
}

function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState(defaultValue);
    const [successOpen, setSuccessOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [totalScreen, setTotalScreen] = useState(0);
    const [storageSize, setStorageSize] = useState();
    const { user } = useAuth();
    const [screens, setScreens] = useState([]);
    const db = getDatabase(app);
    const id = localStorage.getItem("id");
    const max_no_of_screens = user?.no_of_screens;
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [totalStorage, setTotalStorage] = useState();
    const navigate = useNavigate();
    const openNav = () => setIsOpenAdd(true);
    const closeNav = () => setIsOpenAdd(false);


    const getStorageSize = async () => {
        try {
            const storage = await fetchFolderSize(id);
            setStorageSize(storage);
            const res = checkAvailableStorage(user?.storage, storage?.totalSizeInMB);
            setTotalStorage(res);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        try {
            const screensQuery = query(ref(db, 'Screens'), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(screensQuery, async (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const screenIds = Object.keys(data);
                    console.log(screenIds)
                    // Fetch user data for each screen
                    const screensData = [];

                    for (const screenId of screenIds) {
                        const screen = data[screenId];
                        const pairingSnapshot = await get(ref(db, `pairingCodes/${screen.pairing_code}`));
                        if (pairingSnapshot.exists()) {
                            const pairingData = pairingSnapshot.val();
                            screensData.push({
                                ...screen,
                                id: screen?.pairing_code,
                                status: pairingData?.isOnline ? "Online" : "Offline"
                            });
                        }
                    }

                    setScreens(screensData);
                    setTotalScreen(screensData?.length)
                    setLoading(false);
                } else {
                    console.log('No data found');
                    setLoading(false);
                }
            });
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
        getStorageSize();
    }, [])

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
        setInitialValues(defaultValue);
    }

    const handleSubmit = async (values, { resetForm, setSubmitting }) => {
        try {
            const value = {
                ...values,
                user_id: id,
                startDate: moment(new Date()).format("YYYY-MM-DD"),
                endDate: moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format("YYYY-MM-DD")
            }
            console.log(value)
            const dbRef = ref(getDatabase());
            get(child(dbRef, `pairingCodes/${values?.pairing_code}`)).then(async (snapshot) => {
                const spanData = snapshot.val();
                if (snapshot.exists() && !spanData?.userId) {
                    const updates = {};
                    updates['/pairingCodes/' + spanData?.pairingCode] = { ...spanData, isConnected: true, userId: id };
                    const updateData = update(ref(db), updates);
                    await set(ref(db, `Screens/${spanData?.pairingCode}`), value);
                    resetForm();
                    setSubmitting(false);
                    setIsSuccess(true);
                    setIsOpen(false);
                    setInitialValues(defaultValue);
                    setSuccessOpen(true);
                    setTimeout(() => {
                        setSuccessOpen(false)
                    }, 4000);
                } else {
                    console.log("No data available");
                    setIsSuccess(false);
                    setSuccessOpen(true);
                    setTimeout(() => {
                        setSuccessOpen(false)
                    }, 4000);
                }
            }).catch((error) => {
                console.error(error);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleNewScreen = (screen) => {
        console.log("ssssssscreensscreensscreens", screen)

        if (totalScreen >= max_no_of_screens) {
            toast.error("Maximum Screens limit is over.", {
                closeButton: <Close />
            });
        } else {
            setIsOpen(true);
            setIsOpenAdd(false);
        }
    }

    const handleAddMedia = () => {
        setIsOpenAdd(false);
        navigate("/library")
    }

    const handleAddPlaylist = () => {
        setIsOpenAdd(false);
        navigate("/playlist")
    }

    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <h1 className="heading">CMS Web Panel</h1>
                            <div className='breadcrumbs'>
                                <p>{`Home >`}</p>
                                <span>&nbsp; Dashboard</span>
                            </div>
                        </div>
                        <div className='button-container'>
                            <CustomButton leftIcon={<AddIcon />} disabled={loading} onClick={() => handleNewScreen(screens)} label="Add Screen" background="rgba(49, 0, 255, 1)" />
                            <CustomButton leftIcon={<AddIcon />} label="Add Media" onClick={handleAddMedia} background="rgba(199, 37, 95, 1)" />
                            <CustomButton leftIcon={<AddIcon />} label="Add Playlist" onClick={handleAddPlaylist} background="rgba(253, 130, 42, 1)" />
                        </div>
                        <div className={`overlay ${isOpenAdd ? 'open' : ''}`}>
                            <button className='add-button closebtn' onClick={closeNav}><CloseIcon /></button>
                            <div className="overlay-content">
                                <CustomButton leftIcon={<AddIcon />} disabled={loading} onClick={() => handleNewScreen(screens)} label="Add Screen" background="rgba(0, 0, 0, 1)" />
                                <CustomButton leftIcon={<AddIcon />} label="Add Media" onClick={handleAddMedia} background="rgba(0, 0, 0, 1)" />
                                <CustomButton leftIcon={<AddIcon />} label="Add Playlist" onClick={handleAddPlaylist} background="rgba(0, 0, 0, 1)" />
                            </div>
                        </div>
                        <button className='add-button' onClick={openNav}><AddIcon /></button>
                    </div>
                    <div className='dashboard-body'>
                        <div className='dashboard-card-container'>
                            <ScreenCard screens={screens} totalScreen={totalScreen} storageSize={storageSize} />
                            {/* <ActivityLogs /> */}
                        </div>
                        <div className='dashboard-screentable-container' style={{ display: 'flex' }}>
                            <ScreenTable screens={screens} loading={loading} />
                            <StatisticsSide storageSize={storageSize} totalStorage={totalStorage} />
                        </div>
                    </div>
                </div>
                <ScreenFormDrawer open={isOpen} toggleDrawer={toggleDrawer} initialValues={initialValues} handleSubmit={handleSubmit} isEdit={false} />
                <AlertModal
                    open={successOpen}
                    handleClose={() => setSuccessOpen(false)}
                    title={isSuccess ? "Paired Successfully" : `Oops! \n Pairing code does not match`}
                    desc={isSuccess ? "Screen paired successfully, you can now play content in application." : "Please check your pairing code and try again"}
                    icon={isSuccess ? successIcon : errorIcon}
                    success={isSuccess ? true : false}
                />
            </div>
        </Layout>
    )
}

export default Dashboard