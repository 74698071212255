import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAC_U8XkS0NQ8UngBPgo_z3g3-qjr35AaI",
    authDomain: "maxpro-displays.firebaseapp.com",
    databaseURL: "https://maxpro-displays-default-rtdb.firebaseio.com",
    projectId: "maxpro-displays",
    storageBucket: "maxpro-displays.appspot.com",
    messagingSenderId: "953685497660",
    appId: "1:953685497660:web:991118c1e4a3accce93039"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getFirestore(app)

const storage = getStorage(app)

export const uploadFiles = async (id, imageUpload, setLoading) => {
    try {
        const imageRef = ref(storage, `${id}/${new Date().getTime()}_${imageUpload?.name}`);
        const snapshot = uploadBytesResumable(imageRef, imageUpload);
        snapshot.on('state_changed',
            (state_snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (state_snapshot.bytesTransferred / state_snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress.toFixed(2) + '% done');
                setLoading(progress?.toFixed(2))
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error(error);
            },
            () => {
                // Handle successful uploads on complete
                console.log('Upload successful');
            }
        );

        await snapshot;

        // Get download URL
        console.log("snapshotsnapshot", snapshot)
        const downloadURL = await getDownloadURL(imageRef);
        console.log("doeeeee", downloadURL)

        return downloadURL;
    } catch (error) {
        console.log({ error })
    }
}

export async function uploadBlobToFirebaseStorage(blobUrl, fileName, id, setLoading) {
    try {
        // Convert Blob URL to Blob Object
        const response = await fetch(blobUrl);
        const blobData = await response.blob();

        // Upload Blob to Firebase Storage
        const storageRef = ref(storage, `${id}/${fileName}`); // Assuming 'storage' is your Firebase Storage instance
        const snapshot = uploadBytesResumable(storageRef, blobData);

        snapshot.on('state_changed',
            (state_snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (state_snapshot.bytesTransferred / state_snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress.toFixed(2) + '% done');
                setLoading(progress?.toFixed(2))
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error(error);
            },
            () => {
                // Handle successful uploads on complete
                console.log('Upload successful');
            }
        );

        await snapshot;

        // Get download URL
        console.log("snapshotsnapshot", snapshot)
        const downloadURL = await getDownloadURL(storageRef);
        console.log("doeeeee", downloadURL)

        return downloadURL;
    } catch (error) {
        console.error("Error uploading blob to Firebase Storage:", error);
        throw error;
    }
}

export const uploadProfilePicture = async (imageUpload) => {
    try {
        const imageRef = ref(storage, `Users/${imageUpload?.name}`);
        const snapshot = await uploadBytes(imageRef, imageUpload);
        const url = await getDownloadURL(snapshot.ref);
        return url;
    } catch (error) {
        console.log({ error })
    }
}

export { database, app, storage }