import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { Loader } from '../Css';
import closedEye from "../../Assets/closed_eye.png";
import openedEye from "../../Assets/open_eye.png";
import styled from 'styled-components';
import { NewPasswordValidation } from '../../Utils/Validation';
import { equalTo, get, getDatabase, orderByChild, query, ref, update } from 'firebase/database';
import { app } from '../../Utils/Firebase';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function NewPassword({ email }) {
    const [passwordShown, setPasswordShown] = useState(false);
    const db = getDatabase(app);
    const navigate = useNavigate();

    const handleSubmit = async (values, { setFieldError, resetForm, setSubmitting }) => {
        try {
            const userSnapshot = query(ref(db, "users"), orderByChild('email'), equalTo(email));
            get(userSnapshot).then(async (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const userData = Object.values(data);
                    const ids = Object.keys(data);
                    const updates = {};
                    updates['/users/' + ids?.[0]] = { ...userData?.[0], password: values?.password };
                    const updateData = await update(ref(db), updates);
                    resetForm();
                    setSubmitting(false);
                    toast.success("Password Updated Successfully.", {
                        closeButton: <Close />
                    })
                    resetForm();
                    navigate('/login');
                }
            }).catch((error) => {
                console.error("Error getting data:", error);
            });

        } catch (error) {
            console.log("error", error)
        }
    };

    return (
        <div className="loginform-container">
            <div className="loginform">
                <Formik
                    initialValues={{ password: '' }}
                    validationSchema={NewPasswordValidation}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form>
                            <h1>New Password</h1>
                            <br />

                            <div className="field">
                                <label>Enter New Password</label>
                                <Field
                                    type={passwordShown ? "text" : "password"}
                                    name="password"
                                    className="textarea"
                                    value={values?.password}
                                    onChange={handleChange}
                                />
                                <Image
                                    src={passwordShown ? openedEye : closedEye}
                                    alt="hide password"
                                    onClick={() => setPasswordShown(!passwordShown)}
                                />
                            </div>
                            <p className="error-message">
                                {touched.password && errors.password}
                            </p>

                            {isSubmitting ? (
                                <button
                                    className="btn12"
                                    onClick={handleSubmit}
                                    style={{ display: "grid" }}
                                    disabled={isSubmitting}
                                >
                                    <Loader />
                                </button>
                            ) : (
                                <button className="btn12" onClick={handleSubmit}>
                                    Submit
                                </button>
                            )}
                        </form>
                    )}
                </Formik >
            </div>
        </div>
    )
}

export default NewPassword


const Image = styled.img`
  position: absolute;
  padding-top: 38px;
  right: 25px;
  width: 18px;
  height: 18px;
`;