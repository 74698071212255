import React, { useEffect } from 'react'
import Layout from '../Components/Layout/Layout'
import { Box, Button, Modal, Typography } from '@mui/material'
import { useState } from 'react';
import { app, uploadFiles } from '../Utils/Firebase';
import { equalTo, getDatabase, onValue, orderByChild, push, query, ref, remove, set } from 'firebase/database';
import { Media, Area, History, AreaGroup } from '../Utils/Constant';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import AddGroupModal from '../Components/Groups/AddGroupModal';
import CustomButton from '../Components/Common/CustomButton';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import GroupCard from '../Components/Groups/GroupCard';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import AlertModal from '../Components/Common/AlertModal';
import ConfirmationModal from '../Components/Common/ConfirmationModal';
import checkIcon from "../Assets/checkIcon.svg"
import AreaCard from '../Components/Area/AreaCard';
import AddAreaModal from '../Components/Area/AddAreaModal';
import RenameAreaNameModal from '../Components/Area/RenameAreaNameModal';

function AreaGroupPage() {
    const [formOpen, setFormOpen] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();
    const [area, setArea] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedData, setSelectedData] = useState();
    const [renameOpen, setRenameOpen] = useState(false);
    console.log(area)
    const id = localStorage.getItem("id");
    const db = getDatabase(app);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);
    const imageRegex = /^image\/(jpeg|jpg|png|gif|bmp|webp)$/;

    useEffect(() => {
        try {
            const areaQuery = query(ref(db, AreaGroup), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(areaQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key
                    }));
                    setArea(itemsArray);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [])

    const handleSubmit = async (values) => {
        try {
            const newUUID = uuidv4();
            await set(ref(db, `${AreaGroup}/${newUUID}`), {
                name: values?.area_name, user_id: id
            });
            const history = {
                user_id: id,
                title: "Area",
                status: `${values?.area_name} area created successfully.`,
                timestamp: moment(new Date()).toISOString()
            }
            const historyRef = ref(db, History);
            const newHistoryRef = push(historyRef);
            set(newHistoryRef, history);
            navigate(`/area-group/${newUUID}`)
        } catch (error) {
            console.log(error)
        }
    }

    let filteredData = [];

    if (searchText === "") {
        filteredData = area;
    } else if (!area) {
        filteredData = [];
    } else {
        filteredData = area?.filter((item) => {
            const name = item?.name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
    }

    const handleUploadOpen = () => {
        setFormOpen(true);
        closeNav();
    }

    const handleDeleteIcon = (item) => {
        setSelectedGroup(item);
        setDeleteModel(true);
    }

    const handleDeleteScreen = async () => {
        try {
            const groupRef = ref(db, `${AreaGroup}/${selectedGroup?.id}`);
            await remove(groupRef)
            if (selectedGroup?.groups) {
                await Promise.all(selectedGroup?.groups.map(async (groupID) => {
                    const groupRef = ref(db, `${Area}/${groupID}`);
                    await remove(groupRef);
                }));
            }
            setDeleteModel(false);
            setSelectedGroup();
            setDeleteSuccessOpen(true);
            setTimeout(() => {
                setDeleteSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log(error);
        }
    }

    const handleRenameClick = (item) => {
        setSelectedData(item);
        setRenameOpen(true);
    }

    const handleRenameClose = () => {
        setSelectedData();
        setRenameOpen(false);
    }


    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div className='breadcrumbs'>
                                <p>{`Home >`}</p>
                                <span>&nbsp; Area</span>
                            </div>
                        </div>
                        <div className='button-container'>
                            <CustomButton leftIcon={<AddIcon />} onClick={() => setFormOpen(true)} label="Add Area" background="rgba(13, 110, 253, 1)" />
                        </div>
                        <div className={`overlay ${isOpen ? 'open' : ''}`}>
                            <button className='add-button closebtn' onClick={closeNav}><CloseIcon /></button>
                            <div className="overlay-content">
                                <CustomButton leftIcon={<AddIcon />} onClick={handleUploadOpen} label="Add Area" background="rgba(0, 0, 0, 1)" />
                            </div>
                        </div>
                        <button className='add-button' onClick={openNav}><AddIcon /></button>
                    </div>
                    <div className='file-header'>
                        <div>
                            <p className='file-text'>Area</p>
                            <p className='total-file-text'>Total Area - {area?.length}</p>
                        </div>
                        <div className='file-header-right-side'>
                            <div class="wrapper">
                                <div class="icon"><SearchIcon color='rgba(135, 142, 171, 1)' /></div>
                                <input onChange={(e) => setSearchText(e.target.value)} className='search-input' placeholder='Search by Name' />
                            </div>
                            <CustomButton onClick={() => { }} leftIcon={<TuneIcon style={{ height: 18, width: 18 }} />} label="Filters" background="rgba(13, 110, 253, 0.3)" />
                        </div>
                    </div>
                    <AreaCard areas={filteredData} handleDeleteIcon={handleDeleteIcon} handleRenameClick={handleRenameClick} />
                </div>
            </div>
            <ConfirmationModal
                open={deleteModel}
                setOpen={setDeleteModel}
                handleYes={handleDeleteScreen}
                handleClose={() => setDeleteModel(false)}
                title="Are you sure?"
                desc="Are you sure you want to delete this area?"
            />
            <AlertModal
                open={deleteSuccessOpen}
                handleClose={() => setDeleteSuccessOpen(false)}
                title={`Deleted Successfully`}
                desc={"The group is removed successfully"}
                icon={checkIcon}
                success={true}
            />
            <AddAreaModal open={formOpen} handleClose={() => setFormOpen(false)} handleSubmit={handleSubmit} />
            <RenameAreaNameModal
                open={renameOpen}
                handleClose={handleRenameClose}
                areaData={selectedData} />
        </Layout>
    )
}

export default AreaGroupPage
