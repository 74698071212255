import React, { useEffect } from 'react'
import Layout from '../Components/Layout/Layout'
import { Button, } from '@mui/material'
import { useState } from 'react';
import { app } from '../Utils/Firebase';
import { equalTo, get, getDatabase, onValue, orderByChild, push, query, ref, remove, set, update } from 'firebase/database';
import { toast } from 'react-toastify';
import Close from '@mui/icons-material/Close';
import { Area, History, Playlist, videoRegex } from '../Utils/Constant';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import PublishScreen from '../Components/Modals/PublishScreen';
import ScheduleModel from '../Components/Modals/ScheduleModel';
import AddPlaylistToAreaModal from '../Components/Modals/AddPlaylistToAreaModal';
import CustomButton from '../Components/Common/CustomButton';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import EditGroupCard from '../Components/Groups/EditGroupCard';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import ConfirmationModal from '../Components/Common/ConfirmationModal';
import AlertModal from '../Components/Common/AlertModal';
import checkIcon from "../Assets/checkIcon.svg"

function AreaSetting() {
    const [open, setOpen] = useState(false);
    const [selectedFile, setselectedFile] = useState("");
    const [formOpen, setFormOpen] = useState(false);
    const [scheduleOpen, setScheduleOpen] = useState(false);
    const [allPlaylist, setAllPlaylist] = useState([]);
    const [playlist, setPlaylist] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [isPublicBtnDisable, setIsPublicBtnDisable] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [deleteModel, setDeleteModel] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
    const id = localStorage.getItem("id");
    const db = getDatabase(app);
    const navigate = useNavigate();
    const { id: areaId } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);
    const imageRegex = /^image\/(jpeg|jpg|png|gif|bmp|webp)$/;


    const getAreaData = async () => {
        try {
            const areaRef = ref(db, `Area/${areaId}`);
            onValue(areaRef, async (snapshot) => {
                if (snapshot.exists()) {
                    const area_data = snapshot.val();
                    setAreaData(area_data)
                    if (area_data?.playlist?.length) {
                        setSelectedPlaylist(area_data?.playlist);
                        const allPlaylistData = [];
                        let isAllPlaylistScheduled = false;
                        for (const playlistId of area_data?.playlist) {
                            const playlistRef = await get(ref(db, `${Playlist}/${playlistId?.id || playlistId}`));
                            if (playlistRef.exists()) {
                                const playlistData = playlistRef.val();
                                allPlaylistData.push({ ...playlistId, ...playlistData });
                                if (playlistId?.startTime || playlistId?.play_now) {
                                    isAllPlaylistScheduled = true;
                                }
                            }
                        }
                        if (isAllPlaylistScheduled) {
                            setIsPublicBtnDisable(false);
                        } else {
                            setIsPublicBtnDisable(true);
                        }
                        setAllPlaylist(allPlaylistData);
                    } else {
                        console.log('No data found');
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        try {
            getAreaData();
        } catch (error) {
            console.log("error", error);
        }
    }, [])

    useEffect(() => {
        try {
            const playlistQuery = query(ref(db, Playlist), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(playlistQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key
                    }));
                    setPlaylist(itemsArray);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [])

    const handleSubmit = async (values) => {
        try {
            // const playlistData = selectedPlaylist?.filter(item => item?.id !== value?.id);
            const playlistData = selectedPlaylist?.map(item => {
                if (item?.id) {
                    return {
                        ...item
                    }
                } else {
                    return {
                        id: item
                    }
                }
            });
            const updates = {};
            const areaSnapshot = await get(ref(db, `Area/${areaId}`));
            if (areaSnapshot.exists()) {
                const areaData = areaSnapshot.val();
                updates['/Area/' + areaId] = {
                    ...areaData,
                    playlist: playlistData
                }
            }
            const updateDataaa = await update(ref(db), updates);
            const history = {
                user_id: id,
                title: "Group",
                status: `Playlist successfully added to ${areaData?.name} group.`,
                timestamp: moment(new Date()).toISOString()
            }
            const historyRef = ref(db, History);
            const newHistoryRef = push(historyRef);
            set(newHistoryRef, history);
            toast.success("Display Saved Successfully.", {
                closeButton: <Close />
            })
            setFormOpen(false);
        } catch (error) {
            console.log(error)
        }
    }

    const handleCheckbox = (e, playlist) => {
        const isSelected = selectedPlaylist?.filter(p => p?.id === playlist?.id || p === playlist?.id);
        if (isSelected?.length) {
            const filteredData = selectedPlaylist.filter(p => p?.id !== playlist?.id && p !== playlist?.id);
            setSelectedPlaylist(filteredData);
        } else {
            setSelectedPlaylist([...selectedPlaylist, playlist?.id]);
        }
    }

    let filteredData = [];

    if (searchText === "") {
        filteredData = allPlaylist;
    } else if (!allPlaylist) {
        filteredData = [];
    } else {
        filteredData = allPlaylist?.filter((item) => {
            const name = item?.name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
    }

    const handleDeleteIcon = (item) => {
        setSelectedGroup(item);
        setDeleteModel(true);
    }

    const handleDeleteScreen = async () => {
        try {
            const newPlaylist = areaData?.playlist?.filter(p => p?.id !== selectedGroup?.id);
            const updates = {};
            const areaSnapshot = await get(ref(db, `Area/${areaId}`));
            if (areaSnapshot.exists()) {
                const areaData = areaSnapshot.val();
                updates['/Area/' + areaId] = {
                    ...areaData,
                    playlist: newPlaylist
                }
            }
            await update(ref(db), updates);
            setDeleteModel(false);
            setSelectedGroup();
            setDeleteSuccessOpen(true);
            setTimeout(() => {
                setDeleteSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log(error);
        }
    }

    const handleAreaNavigate = () => {
        navigate("/area-group");
    }

    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div className='breadcrumbs'>
                                <p>{`Home >`}</p>
                                <span onClick={handleAreaNavigate}>&nbsp; Area {` >`}</span>
                                <span>&nbsp; Display {` >`}</span>
                                <span>&nbsp; {areaData?.name}</span>
                            </div>
                        </div>
                        <div className='button-container'>
                            <CustomButton leftIcon={<AddIcon />} onClick={() => setFormOpen(true)} label="Add Playlist" background="rgba(13, 110, 253, 1)" />
                            <CustomButton leftIcon={<AddIcon />} onClick={() => setScheduleOpen(true)} label="Add Schedule" background="rgba(13, 110, 253, 1)" />
                            <CustomButton leftIcon={<IosShareIcon />} disabled={isPublicBtnDisable} onClick={() => (setOpen(true), setselectedFile(areaId))} label="Publish" background="#000000" style={{ padding: "10px 35px" }} />
                        </div>
                        <div className={`overlay ${isOpen ? 'open' : ''}`}>
                            <button className='add-button closebtn' onClick={closeNav}><CloseIcon /></button>
                            <div className="overlay-content">
                                <CustomButton leftIcon={<AddIcon />} onClick={() => (setFormOpen(true), closeNav())} label="Add Playlist" background="rgba(0, 0, 0, 1)" />
                                <CustomButton leftIcon={<AddIcon />} onClick={() => (setScheduleOpen(true), closeNav())} label="Add Schedule" background="rgba(0, 0, 0, 1)" />
                                <CustomButton leftIcon={<IosShareIcon />} disabled={isPublicBtnDisable} onClick={() => (setOpen(true), setselectedFile(areaId), closeNav())} label="Publish" background="#000000" style={{ padding: "10px 35px" }} />
                            </div>
                        </div>
                        <button className='add-button' onClick={openNav}><AddIcon /></button>
                    </div>
                    <div className='file-header'>
                        <div>
                            <p className='file-text'>Edit Display</p>
                            <p className='total-file-text'>Total Display Files - {allPlaylist?.length}</p>
                        </div>
                        <div className='file-header-right-side'>
                            <div class="wrapper">
                                <div class="icon"><SearchIcon color='rgba(135, 142, 171, 1)' /></div>
                                <input onChange={(e) => setSearchText(e.target.value)} className='search-input' placeholder='Search by Name' />
                            </div>
                            <CustomButton onClick={() => { }} leftIcon={<TuneIcon style={{ height: 18, width: 18 }} />} label="Filters" background="rgba(13, 110, 253, 0.3)" />
                        </div>
                    </div>
                    <div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', marginTop: "35px" }}>
                            <EditGroupCard playlist={filteredData} handleDeleteIcon={handleDeleteIcon} />
                            {/* <div style={{ width: '100%' }}>
                                <Button disabled={isPublicBtnDisable} variant="contained" onClick={() => (setOpen(true), setselectedFile(areaId))} sx={{ width: "100px", background: '#3379d0', height: "35px", color: 'white', borderRadius: '10px' }}>
                                    Publish
                                </Button>
                            </div> */}
                        </div>
                        <ScheduleModel open={scheduleOpen} setOpen={setScheduleOpen} playlist={allPlaylist} areaData={areaData} />
                    </div>
                </div>
            </div>
            <AddPlaylistToAreaModal
                playlist={playlist}
                formOpen={formOpen}
                setFormOpen={setFormOpen}
                handleSubmit={handleSubmit}
                selectedPlaylist={selectedPlaylist}
                handleCheckbox={handleCheckbox} />

            <ConfirmationModal
                open={deleteModel}
                setOpen={setDeleteModel}
                handleYes={handleDeleteScreen}
                handleClose={() => setDeleteModel(false)}
                title="Are you sure?"
                desc="Are you sure you want to remove this playlist from group?"
            />
            <AlertModal
                open={deleteSuccessOpen}
                handleClose={() => setDeleteSuccessOpen(false)}
                title={`Deleted Successfully`}
                desc={"The playlist is removed successfully"}
                icon={checkIcon}
                success={true}
            />
            <PublishScreen open={open} setOpen={setOpen} selectedFile={selectedFile} isArea={true} areaData={areaData} />
        </Layout>
    )
}

export default AreaSetting
