import './App.css';
import Dashboard from './Pages/Dashboard';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './Pages/Login';
import PrivateRoute from './Context/PrivateRoutes';
import Screens from './Pages/Screens';
import MediaFiles from './Pages/MediaFiles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScreenSetting from './Pages/ScreenSetting';
import PlaylistPage from './Pages/Playlist';
import PlaylistSetting from './Pages/PlaylistSetting';
import Area from './Pages/Area';
import AreaSetting from './Pages/AreaSetting';
import SettingPage from './Pages/Setting';
import ChangePassword from './Pages/ChangePassword';
import ForgotPasswordPage from './Pages/ForgotPassword';
import HistoryPage from './Pages/History';
import ActivityLogsPage from './Pages/ActivityLogs';
import LibraryFolder from './Pages/LibraryFolder';
import AreaGroupPage from './Pages/AreaGroup';


function App() {

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" exact element={<LoginPage />} />
          <Route path="/forgot-password" exact element={<ForgotPasswordPage />} />
        </Routes>
        <PrivateRoute>
          <Routes>
            <Route path="/" exact element={<Dashboard />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/screens" exact element={<Screens />} />
            <Route path="/library" exact element={<MediaFiles />} />
            <Route path="/folder" exact element={<LibraryFolder />} />
            <Route path="/playlist" exact element={<PlaylistPage />} />
            <Route path="/area" exact element={<Area />} />
            <Route path="/area-group" exact element={<AreaGroupPage />} />
            <Route path="/area-group/:areaId" exact element={<Area />} />
            <Route path="/setting" exact element={<SettingPage />} />
            {/* <Route path="/history" exact element={<HistoryPage />} /> */}
            <Route path="/history" exact element={<ActivityLogsPage />} />
            <Route path="/change-password" exact element={<ChangePassword />} />
            <Route path="/area-group/:areaId/group/:id" exact element={<AreaSetting />} />
            <Route path="/screen/:id" exact element={<ScreenSetting />} />
            <Route path="/playlist-setting/:id" exact element={<PlaylistSetting />} />
          </Routes>
        </PrivateRoute>
      </Router>
    </div>
  );
}

export default App;
