import { FormHelperText, InputLabel, TextField } from '@mui/material';
import { Field } from 'formik';
import React, { useState } from 'react';
import "../../Css/Screens.css";
import closedEye from "../../Assets/closed_eye.png";
import openedEye from "../../Assets/open_eye.png";
import styled from 'styled-components';

function PasswordField({ label, name, error, ErrorMessage, onChange, style, value, ...params }) {
    const [passwordShown, setPasswordShown] = useState(false);

    const handleShowPassword = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <>
            <div className="password-field">
                <label>{label}</label>
                <Field
                    type={passwordShown ? "text" : "password"}
                    name={name}
                    className={`${error ? "error-border" : ""}`}
                    onChange={onChange}
                />
                <Image
                    src={passwordShown ? openedEye : closedEye}
                    alt="hide password"
                    onClick={handleShowPassword}
                />
            </div>
            {error && <p className="input-error-message">{error}</p>}
        </>
    )
}

export default PasswordField

const Image = styled.img`
  position: absolute;
  padding-top: 38px;
  right: 25px;
  width: 18px;
  height: 18px;
`;