import React, { useRef, useState } from 'react'
import youtube from "../../Assets/youtube.png";
import webpage from "../../Assets/webpage.png";
import clockImage from "../../Assets/clockImage.png";
import cloudImage from "../../Assets/cloudImage.jpg";
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import YoutubeWidget from './YoutubeWidget';
import { app } from '../../Utils/Firebase';
import { getDatabase, push, ref, remove, set } from 'firebase/database';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import { AllWidgets, WebpageWidgets, YoutubeWidgets } from '../../Utils/Constant';
import WebpageWidget from './WebpageWidget';
import AddWidgetModal from '../Modals/AddWidgetModal';
import WeatherWidget from './WeatherWidget';
import ClockWidget from './ClockWidget';
import ConfirmationModal from '../Common/ConfirmationModal';
import AlertModal from '../Common/AlertModal';
import checkIcon from "../../Assets/checkIcon.svg"

function Widget({ youtubeUrls, webpageUrls, weatherUrls, clockUrls, handleScreenPlaylist, selectedWidget, setSelectedWidget, selectedFiles }) {
    const [open, setOpen] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
    const [currentWidget, setCurrentWidget] = useState({});
    const db = getDatabase(app);
    const id = localStorage.getItem("id");

    const handleDeleteIcon = async (item) => {
        try {
            setCurrentWidget(item);
            setDeleteModel(true);
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const handleDeleteYes = async () => {
        try {
            if (currentWidget?.type === "stream") {
                const groupRef = ref(db, `${YoutubeWidgets}/${currentWidget?.id}`);
                await remove(groupRef)
            } else if (currentWidget?.type === "webpage") {
                const groupRef = ref(db, `${WebpageWidgets}/${currentWidget?.id}`);
                await remove(groupRef)
            } else {
                const groupRef = ref(db, `${AllWidgets}/${currentWidget?.id}`);
                await remove(groupRef)
            }
            setCurrentWidget({});
            setDeleteModel(false);
            setDeleteSuccessOpen(true);
            setTimeout(() => {
                setDeleteSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log('error: ', error);
        }
    }

    return (
        <>
            {!selectedWidget ? <div>
                <h2>Widgets</h2>
                <div style={{ margin: 8, width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'start', padding: '0 10px', gap: '10px', flexWrap: 'wrap' }}>
                        <div className='youtube-widget-logo'><img src={youtube} onClick={() => setSelectedWidget("youtube")} height="100%" width="100%" alt="youtube" style={{ borderRadius: '10px' }} /></div>
                        <div className='youtube-widget-logo'><img src={webpage} onClick={() => setSelectedWidget("webpage")} height="100%" width="100%" alt="youtube" style={{ borderRadius: '10px' }} /></div>
                        <div className='youtube-widget-logo'><img src={cloudImage} onClick={() => setSelectedWidget("weather")} height="100%" width="100%" alt="cloud" style={{ borderRadius: '10px' }} /></div>
                        <div className='youtube-widget-logo'><img src={clockImage} onClick={() => setSelectedWidget("clock")} height="100%" width="100%" alt="clock" style={{ borderRadius: '10px' }} /></div>
                    </div>
                </div>
            </div> :
                selectedWidget === "youtube" ?
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                            <Button variant="contained" onClick={() => setSelectedWidget("")} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <ArrowBackIcon />
                            </Button>
                            <h2 style={{ margin: 0 }}>Youtube</h2>
                            <Button variant="contained" onClick={() => setOpen(true)} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <AddIcon />
                            </Button>
                        </div>
                        <YoutubeWidget youtubeUrls={youtubeUrls} handleScreenPlaylist={handleScreenPlaylist} selectedFiles={selectedFiles} handleDeleteIcon={handleDeleteIcon} />
                    </div> : selectedWidget === "webpage" ? <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                            <Button variant="contained" onClick={() => setSelectedWidget("")} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <ArrowBackIcon />
                            </Button>
                            <h2 style={{ margin: 0 }}>WebPage</h2>
                            <Button variant="contained" onClick={() => setOpen(true)} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <AddIcon />
                            </Button>
                        </div>
                        <WebpageWidget webpageUrls={webpageUrls} handleScreenPlaylist={handleScreenPlaylist} selectedFiles={selectedFiles} handleDeleteIcon={handleDeleteIcon} />
                    </div> : selectedWidget === "weather" ? <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                            <Button variant="contained" onClick={() => setSelectedWidget("")} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <ArrowBackIcon />
                            </Button>
                            <h2 style={{ margin: 0 }}>weather</h2>
                            <Button variant="contained" onClick={() => setOpen(true)} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <AddIcon />
                            </Button>
                        </div>
                        <WeatherWidget weatherUrls={weatherUrls} handleScreenPlaylist={handleScreenPlaylist} selectedFiles={selectedFiles} handleDeleteIcon={handleDeleteIcon} />
                    </div> : selectedWidget === "clock" ? <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                            <Button variant="contained" onClick={() => setSelectedWidget("")} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <ArrowBackIcon />
                            </Button>
                            <h2 style={{ margin: 0 }}>Clock</h2>
                            <Button variant="contained" onClick={() => setOpen(true)} sx={{ width: "20px", background: 'rgba(13, 110, 253, 1)', height: "35px", color: 'white', borderRadius: '50px', }}>
                                <AddIcon />
                            </Button>
                        </div>
                        <ClockWidget clockUrls={clockUrls} handleScreenPlaylist={handleScreenPlaylist} selectedFiles={selectedFiles} handleDeleteIcon={handleDeleteIcon} />
                    </div> : ""}
            <AddWidgetModal open={open} setOpen={setOpen} selectedWidget={selectedWidget} />
            <ConfirmationModal
                open={deleteModel}
                setOpen={setDeleteModel}
                handleYes={handleDeleteYes}
                handleClose={() => setDeleteModel(false)}
                title="Are you sure?"
                desc="Are you sure you want to delete this widget?"
            />
            <AlertModal
                open={deleteSuccessOpen}
                handleClose={() => setDeleteSuccessOpen(false)}
                title={`Deleted Successfully`}
                desc={"Widget is removed successfully"}
                icon={checkIcon}
                success={true}
            />
        </>
    )
}

export default Widget
