import React, { useState } from 'react'
import duplicateIcon from "../../Assets/saveIcon.svg"
import { useNavigate } from 'react-router-dom';
import deleteIcon from "../../Assets/delete-icon-red.svg"
import RenameGroupNameModal from './RenameGroupNameModal';

function GroupCard({ groups, handleDeleteIcon, areaId }) {
    const [renameOpen, setRenameOpen] = useState(false);
    const [renameData, setRenameData] = useState();
    const navigate = useNavigate();

    const handleRenameClose = () => {
        setRenameOpen(false);
    }

    const handleRename = (item) => {
        console.log("dsdsdsdsdsdsdd", item)
        setRenameData(item);
        setRenameOpen(true);
    }
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '20px', marginTop: "35px" }}>
            {groups?.length ? groups?.map((p, i) => {
                return (<div key={i} className='group-card'>
                    <img
                        src={duplicateIcon}
                        className="mediaFileCardImg"
                        alt="image not found"
                    />
                    <div className='playlist-content-container'>
                        <div>
                            <p className='group-card-title'>{p?.name?.length > 25
                                ? p.name.slice(0, 25) + "..."
                                : p.name}</p>
                        </div>
                        <img src={deleteIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} />
                    </div>
                    <div className='playlist-button-container' style={{ alignItems: "center" }}>
                        <button className='rename-btn' onClick={() => handleRename(p)}>Rename</button>
                        <button className='rename-btn' onClick={() => navigate(`/area-group/${areaId}/group/${p?.id}`)}>Edit</button>
                    </div>
                </div>)
            }) : <p className='mediafile-not-found'>No Group Found</p>}
            <RenameGroupNameModal open={renameOpen} handleClose={handleRenameClose} groupData={renameData} />
        </div>
    )
}

export default GroupCard