import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { imageRegex } from '../../Utils/Constant';
import { Check } from '@mui/icons-material';

function MediaFiles({ playlist, handleScreenPlaylist, selectedFiles }) {
    return (
        <>
            {/* <h2>Media Files</h2> */}
            <div style={{ margin: 8 }}>
                <Droppable droppableId={"libarary"} key={"libarary"}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    padding: 4,
                                    width: '100%',
                                    minHeight: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    gap: "10px"
                                }}
                            >
                                {playlist?.map((item, index) => {
                                    const isSelected = selectedFiles?.some(m => m?.url === item?.url);
                                    return (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                            disableInteractiveElementBlocking
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            userSelect: "none",
                                                            // padding: "8px",
                                                            margin: "0 0 8px 0",
                                                            height: "fit-content",
                                                            width: "calc(50% - 5px)",
                                                            backgroundColor: "#456C86",
                                                            color: "white",
                                                            position: 'relative',
                                                            background: "rgba(255, 255, 255, 1)",
                                                            boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)",
                                                            borderRadius: "10px",
                                                            boxSizing: "border-box",
                                                            opacity: isSelected ? 0.5 : 1,
                                                            ...provided.draggableProps.style
                                                        }}
                                                        onClick={() => handleScreenPlaylist(item)}
                                                    >
                                                        {isSelected && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        bottom: 0,
                                                                        backgroundColor: 'black',
                                                                        opacity: 0.3,
                                                                        borderRadius: "10px",
                                                                        zIndex: 1,
                                                                    }}
                                                                // onClick={() => handleScreenPlaylist(item)}
                                                                ></div>
                                                                <Check
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '10px',
                                                                        right: '10px',
                                                                        color: 'black',
                                                                        fontSize: '20px',
                                                                        zIndex: 2,
                                                                        background: "white",
                                                                        borderRadius: "50%"
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                        {imageRegex.test(item?.type) ? <img
                                                            src={item.url}
                                                            className="card-img-top"
                                                            height={"110"}
                                                            width={"100%"}
                                                            alt="image not found"
                                                        // onClick={() => handleScreenPlaylist(item)}
                                                        /> : <>
                                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                <video
                                                                    width="100%"
                                                                    height="110"
                                                                    controls={false}
                                                                    draggable="true"
                                                                // onClick={() => handleScreenPlaylist(item)}
                                                                >
                                                                    <source src={item?.url} type={item?.type} />
                                                                </video>
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        opacity: 0.7, // Adjust the opacity as needed
                                                                        // cursor: 'pointer',
                                                                    }}
                                                                // onClick={() => handleScreenPlaylist(item)}
                                                                >
                                                                    ▶️
                                                                </div>
                                                            </div></>}
                                                        <div className='zone-card-container' style={{ overflowX: "hidden", padding: "5px" }}>
                                                            <div>
                                                                <p className='zone-card-title' style={{ margin: 0 }}>{item?.name.slice(0, 30)}</p>
                                                                <p className='zone-card-desc'>File Type - {item?.type}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </div>
        </>
    )
}

export default MediaFiles