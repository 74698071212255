import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd';
import webpageImage from "../../Assets/webpage.png";
import { Check } from '@mui/icons-material';
import deleteIcon from "../../Assets/delete-icon-red.svg"

function WebpageWidget({ webpageUrls, handleScreenPlaylist, selectedFiles, handleDeleteIcon }) {

    return (
        <>
            <div style={{ margin: 8 }}>
                <Droppable droppableId={"libarary"} key={"libarary"}>
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    padding: 4,
                                    width: '100%',
                                    minHeight: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    gap: 20
                                }}
                            >
                                {webpageUrls?.map((item, index) => {
                                    const isSelected = selectedFiles?.some(m => m?.url === item?.url);
                                    return (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                            disableInteractiveElementBlocking
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            position: 'relative',
                                                        }}
                                                        onClick={() => handleScreenPlaylist(item)}
                                                    >
                                                        {isSelected && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        bottom: 0,
                                                                        backgroundColor: 'black',
                                                                        opacity: 0.3,
                                                                        borderRadius: "10px",
                                                                        zIndex: 1,
                                                                    }}
                                                                ></div>
                                                                <Check
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '10px',
                                                                        right: '10px',
                                                                        color: 'black',
                                                                        fontSize: '20px',
                                                                        zIndex: 2,
                                                                        background: "white",
                                                                        borderRadius: "50%"
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                        <div style={{ width: '120px', boxShadow: '0px 0px 5px 2px grey', borderRadius: '10px', background: "#cccccc" }}>
                                                            <img src={webpageImage} alt='youtube' style={{ height: '70px', width: '100%', borderRadius: "10px" }} />
                                                            <div>
                                                                <p style={{ margin: 0 }}>{item?.name}</p>
                                                                <img
                                                                    src={deleteIcon}
                                                                    alt="delete-img"
                                                                    className='delete-widget-icon'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation(); // Prevents handleScreenPlaylist from being triggered
                                                                        handleDeleteIcon(item);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </div>
        </>
    )
}

export default WebpageWidget