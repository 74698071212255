import React, { useEffect, useState } from 'react'
import CustomButton from '../Common/CustomButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import alertIcon from "../../Assets/alarmIcon.svg";
import { History } from '../../Utils/Constant';
import { equalTo, getDatabase, onValue, orderByChild, query, ref } from 'firebase/database';
import { app } from '../../Utils/Firebase';

function ActivityLogs() {
    const db = getDatabase(app);
    const id = localStorage.getItem("id");
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const historyQuery = query(ref(db, History), orderByChild('user_id'), equalTo(id));
        const filterData = onValue(historyQuery, async (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                const historyData = Object.values(data);
                setLogs(historyData?.reverse());
            }
        })
    }, [])

    return (
        <div className='dashboard-table-container dashboard-activity-container'>
            <div className='screen-header activity-header'>
                <div>
                    <p className='screen-text'>Activity Log</p>
                    <p className='total-screen-text'>Total Log - {logs?.length}</p>
                </div>
                {/* <div>
                    <CustomButton onClick={() => { }} rightIcon={<ArrowForwardIosIcon style={{ height: 18, width: 18 }} />} label="View More" background="rgba(13, 110, 253, 1)" />
                </div> */}
            </div>
            <div>
                {logs?.map((item) => <div className='account-box'>
                    <img src={alertIcon} alt='alarm' />
                    <div>
                        <p className='account-text'>{item?.title}</p>
                        <p className='subaccount-text'>{item?.status}</p>
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export default ActivityLogs