import React, { useEffect } from 'react'
import Layout from '../Components/Layout/Layout'
import { Box, Button, Modal, Typography } from '@mui/material'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { app } from '../Utils/Firebase';
import { equalTo, getDatabase, onValue, orderByChild, push, query, ref, remove, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import InputField from '../Components/Common/InputField';
import { NewPlaylistValidation } from '../Utils/Validation';
import { History, Playlist, videoRegex } from '../Utils/Constant';
import { v4 as uuidv4 } from 'uuid';
import PublishScreen from '../Components/Modals/PublishScreen';
import PlaylistPublishScreen from '../Components/PlaylistPublishScreen';
import CustomButton from '../Components/Common/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistCard from '../Components/Playlist/PlaylistCard';
import AddPlaylistModal from '../Components/Playlist/AddPlaylistModal';
import moment from 'moment';
import ConfirmationModal from '../Components/Common/ConfirmationModal';
import AlertModal from '../Components/Common/AlertModal';
import checkIcon from "../Assets/checkIcon.svg"

function PlaylistPage() {
    const [open, setOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [playlist, setPlaylist] = useState([]);
    const [selectedFile, setselectedFile] = useState("");
    const [searchText, setSearchText] = useState("");
    const [deleteModel, setDeleteModel] = useState(false);
    const [selectedPlaylist, setSelectedPlaylist] = useState();
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
    const navigate = useNavigate();
    const id = localStorage.getItem("id");
    const db = getDatabase(app);
    const [isOpen, setIsOpen] = useState(false);
    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);
    const imageRegex = /^image\/(jpeg|jpg|png|gif|bmp|webp)$/;

    useEffect(() => {
        try {
            const playlistQuery = query(ref(db, Playlist), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(playlistQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key
                    }));
                    setPlaylist(itemsArray);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [])

    const handleSubmit = async (values) => {
        try {
            const newUUID = uuidv4();
            await set(ref(db, `Playlist/${newUUID}`), {
                name: values?.playlisy_name, message: values?.message, user_id: id, timestamp: moment(new Date()).toISOString()
            });
            const history = {
                user_id: id,
                title: "Playlist",
                status: `${values?.playlisy_name} playlist created successfully.`,
                timestamp: moment(new Date()).toISOString()
            }
            const historyRef = ref(db, History);
            const newHistoryRef = push(historyRef);
            set(newHistoryRef, history);
            navigate(`/playlist-setting/${newUUID}`)
        } catch (error) {
            console.log("error", error)
        }
    }

    let filteredData = [];

    if (searchText === "") {
        filteredData = playlist;
    } else if (!playlist) {
        filteredData = [];
    } else {
        filteredData = playlist?.filter((item) => {
            const name = item?.name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
    }

    const handleClose = () => {
        setFormOpen(true);
        closeNav();
    }

    const handleDeleteIcon = (item) => {
        setSelectedPlaylist(item);
        setDeleteModel(true);
    }

    const handleDeleteScreen = async () => {
        try {
            const groupRef = ref(db, `${Playlist}/${selectedPlaylist?.id}`);
            remove(groupRef).then((res) => {
                console.log(" File res res", res)
                setDeleteModel(false);
                setSelectedPlaylist();
                setDeleteSuccessOpen(true);
                setTimeout(() => {
                    setDeleteSuccessOpen(false);
                }, 4000);
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div className='breadcrumbs'>
                                <p>{`Home >`}</p>
                                <span>&nbsp; Playlist</span>
                            </div>
                        </div>
                        <div className='button-container'>
                            <CustomButton leftIcon={<AddIcon />} onClick={() => setFormOpen(true)} label="New Playlist" background="rgba(13, 110, 253, 1)" />
                        </div>
                        <div className={`overlay ${isOpen ? 'open' : ''}`}>
                            <button className='add-button closebtn' onClick={closeNav}><CloseIcon /></button>
                            <div className="overlay-content">
                                <CustomButton leftIcon={<AddIcon />} onClick={handleClose} label="New Playlist" background="rgba(0, 0, 0, 1)" />
                            </div>
                        </div>
                        <button className='add-button' onClick={openNav}><AddIcon /></button>
                    </div>
                    <div className='file-header'>
                        <div>
                            <p className='file-text'>Playlist</p>
                            <p className='total-file-text'>Total files - {playlist?.length}</p>
                        </div>
                        <div style={{ display: 'flex', gap: "10px" }}>
                            <div class="wrapper">
                                <div class="icon"><SearchIcon color='rgba(135, 142, 171, 1)' /></div>
                                <input onChange={(e) => setSearchText(e.target.value)} className='search-input' placeholder='Search by Name' />
                            </div>
                            <CustomButton onClick={() => { }} leftIcon={<TuneIcon style={{ height: 18, width: 18 }} />} label="Filters" background="rgba(13, 110, 253, 0.3)" />
                        </div>
                    </div>
                    <PlaylistCard playlist={filteredData} handleDeleteIcon={handleDeleteIcon} />
                </div>
            </div>
            <AddPlaylistModal
                open={formOpen}
                handleClose={() => setFormOpen(false)}
                handleSubmit={handleSubmit}
            />
            <ConfirmationModal
                open={deleteModel}
                setOpen={setDeleteModel}
                handleYes={handleDeleteScreen}
                handleClose={() => setDeleteModel(false)}
                title="Are you sure?"
                desc="Are you sure you want to remove this playlist?"
            />
            <AlertModal
                open={deleteSuccessOpen}
                handleClose={() => setDeleteSuccessOpen(false)}
                title={`Deleted Successfully`}
                desc={"The playlist is deleted successfully"}
                icon={checkIcon}
                success={true}
            />
            <PlaylistPublishScreen open={open} setOpen={setOpen} selectedFile={selectedFile} />
        </Layout>
    )
}

export default PlaylistPage

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '80vh',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
};