import React, { useState } from 'react'
import { imageRegex, videoRegex } from '../../Utils/Constant';
import deleteIcon from "../../Assets/delete-icon-red.svg"
import duplicateIcon from "../../Assets/saveIcon.svg"
import Video from '../Common/Video';
import VideoDisplayModal from '../Common/VideoDisplayModal';
import { useNavigate } from 'react-router-dom';
import saveIconImage from "../../Assets/saveIcon.svg";
import youtubeImage from "../../Assets/youtube.png";
import webpageImage from "../../Assets/webpage.png";

function EditGroupCard({ playlist, handleDeleteIcon }) {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '20px', marginTop: "35px", width: "100%" }}>
            {playlist?.length ? playlist?.map((p, i) => {
                const url = p?.data?.zone?.[0]?.[0]?.url || p?.data?.zone?.[1]?.[0]?.url;
                const type = p?.data?.zone?.[0]?.[0]?.type || p?.data?.zone?.[1]?.[0]?.type;
                return (<div key={i} className='mediaFileCard'>
                    {imageRegex.test(type) ? <img
                        src={url}
                        className="mediaFileCardImg"
                        alt="image not found"
                    /> : videoRegex.test(type) ?
                        <Video url={url} type={type} style={{ height: "120px" }} />
                        : (type) === 'webpage' ? <img src={webpageImage} alt='webpageImage' className="mediaFileCardImg" /> : (type) === 'stream' ? <img src={youtubeImage} alt='youtube' className="mediaFileCardImg"
                        /> : <img src={saveIconImage} alt='webpageImage' className="mediaFileCardImg" />}
                    <div className='edit-group-card-content-box'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className='edit-group-card-title'>{p?.name?.length > 25
                                ? p.name.slice(0, 25) + "..."
                                : p.name}</p>
                            <img src={deleteIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} />
                        </div>
                        <div className='edit-group-card-date-box'>
                            <p className='medialCard-date'>Date: <span style={{ fontWeight: 500 }}> {p?.daily ? "Daily" : p?.play_now ? "Play Now" : p?.startDate ? `${p?.startDate} - ${p?.endDate}` : "-"}</span></p>
                            <p className='medialCard-date'>Time: <span style={{ fontWeight: 500 }}>{p?.startTime} - {p?.endTime} </span></p>
                        </div>
                        {(p?.startTime && p?.endTime) && <button className='schedule-btn'>Scheduled</button>}
                    </div>
                    <div className='playlist-button-container'>
                    </div>
                </div>)
            }) : <p className='mediafile-not-found'>No Dispay Found</p>}
        </div>
    )
}

export default EditGroupCard