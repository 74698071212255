import React, { useState } from 'react'
import Layout from '../Components/Layout/Layout'
import { Formik } from 'formik'
import { Box, Button } from '@mui/material'
import InputField from '../Components/Common/InputField'
import { ChangePasswordValidation } from '../Utils/Validation'
import { useNavigate } from 'react-router-dom'
import { equalTo, get, getDatabase, onValue, orderByChild, query, ref, set, update } from 'firebase/database'
import { app } from '../Utils/Firebase'
import { Close } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useAuth } from '../Context/auth'

const defaultValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
}
function ChangePassword() {
    const [initialValues, setInitialValues] = useState(defaultValue);
    const navigate = useNavigate();
    const { user } = useAuth();
    const userId = localStorage.getItem("id");
    const db = getDatabase(app);

    const handleSubmit = async (values, { resetForm, setSubmitting, setFieldError }) => {
        console.log("values", values)
        const value = {
            ...values
        }
        try {
            const userSnapshot = await get(ref(db, `users/${userId}`));
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                if (userData?.password === values?.oldPassword) {
                    const updates = {};
                    updates['/users/' + userId] = { ...userData, password: values?.newPassword };
                    const updateData = await update(ref(db), updates);
                    // await set(ref(db, `Screens/${spanData?.pairingCode}`), value);
                    resetForm();
                    setSubmitting(false);
                    toast.success("Password Updated Successfully.", {
                        closeButton: <Close />
                    })
                    resetForm();
                    navigate('/setting');
                } else {
                    setFieldError("oldPassword", "Invalid Old Password Please Enter correct password.");
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Layout>
            <div>
                <div style={{ height: '91vh', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Button variant="contained" onClick={() => navigate(`/setting`)} sx={{ width: "20px", background: '#3379d0', height: "35px", color: 'white', borderRadius: '10px', }}>
                                Back
                            </Button>
                            <h2 style={{ margin: '5px 0px' }}>Change Password</h2>
                        </div>
                    </div>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={ChangePasswordValidation}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                            }) => (
                                <form>
                                    {console.log('values', values)}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: "start", height: '100%', width: '100%', marginTop: '20px' }}>
                                        <InputField label="Old Password" name="oldPassword" onChange={handleChange} value={values.oldPassword} error={touched?.oldPassword && errors.oldPassword} />
                                        <InputField label="New Password" name="newPassword" onChange={handleChange} value={values.newPassword} error={touched?.newPassword && errors.newPassword} />
                                        <InputField label="Confirm Password" name="confirmPassword" onChange={handleChange} value={values.confirmPassword} error={touched?.confirmPassword && errors.confirmPassword} />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                                        <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ width: "200px", margin: "0 30px 30px 0", background: '#3379d0', height: "40px", color: 'white' }}>
                                            {isSubmitting ? "Submitting..." : "Submit"}
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik >
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ChangePassword