import { Box, Modal } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { imageRegex } from '../../Utils/Constant';

function VideoDisplayModal({ open, handleClose, url, type }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: "absolute", right: { xs: "10px", sm: "-40px" } }}>
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
                </Box>
                {imageRegex.test(type) ? <img src={url} style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "50px"
                }} /> : <video controls={true} style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "50px"
                }}>
                    <source src={url} type={type} />
                </video>}
            </Box>
        </Modal>
    )
}

export default VideoDisplayModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        sm: '900px',
    },
    height: {
        xs: "50%",
        sm: '500px',
    },
    bgcolor: 'transparent',
    boxShadow: 24,
};