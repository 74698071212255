import { Box, Button, Modal, Typography } from '@mui/material'
import { Formik } from 'formik'
import React from 'react'
import InputField from '../Common/InputField'
import { NewScreenValidation } from '../../Utils/Validation'
import CloseIcon from '@mui/icons-material/Close';


function AddNewScreenModal({ initialValues, handleSubmit, formOpen, setFormOpen }) {
    return (
        <Modal
            open={formOpen}
            onClose={() => setFormOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'sticky' }}>
                    <Typography variant='h6'>New Screen</Typography>
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setFormOpen(false)} />
                </Box>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={NewScreenValidation}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            getFieldProps
                            /* and other goodies */
                        }) => (
                            <form>
                                <Box sx={{ height: '100%', width: '100%', marginTop: '20px' }}>
                                    <InputField label="Pairing Code" name="pairing_code" onChange={handleChange} value={values.pairing_code} error={touched?.pairing_code && errors.pairing_code} />
                                    <InputField label="Screen Name" name="screen_name" onChange={handleChange} value={values.screen_name} error={touched?.screen_name && errors.screen_name} />
                                    <InputField label="Screen Tag" name="screen_tag" onChange={handleChange} />
                                    <InputField label="Enter Location" name="location" onChange={handleChange} />
                                    <Box sx={{ display: 'flex' }}>
                                        <InputField label="City" name="city" onChange={handleChange} sx={smallTextField} />
                                        <InputField label="State" name="state" onChange={handleChange} sx={smallTextField} />
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <InputField label="Country" name="country" onChange={handleChange} sx={smallTextField} />
                                        <InputField label="Area" name="area" onChange={handleChange} sx={smallTextField} />
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                                    <Button type="submit" onClick={handleSubmit} variant="contained" sx={{ width: "200px", margin: "0 30px 30px 0", background: '#3379d0', height: "40px", color: 'white' }}>
                                        {isSubmitting ? "Pairing Screen..." : "Pair Screen"}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik >
                </Box>
                <Box sx={{}}>
                </Box>
            </Box>
        </Modal>
    )
}

export default AddNewScreenModal


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '50vh',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    overflow: 'auto'
};

const smallTextField = {
    width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
    "& .MuiInputBase-root ": {
        width: { xs: 300, sm: 300, md: 270, lg: 245, xl: 245 },
        height: "40px",
    },
    marginRight: "10px"
}
