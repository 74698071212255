import { useState, useEffect, useContext, createContext } from "react";
import { get, getDatabase, ref } from "firebase/database";
import { app } from "../Utils/Firebase";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const db = getDatabase(app);
    const userId = localStorage.getItem("id");

    const getUser = async () => {
        try {
            setLoading(true);
            const userSnapshot = await get(ref(db, `users/${userId}`));
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                delete userData?.password;
                console.log("----", userData)
                setUser(userData);
            }
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    useEffect(() => {
        getUser();
    }, []);
    const value = {
        user
    };

    return (
        <AuthContext.Provider value={value}>
            {loading ? "Loading..." : children}
        </AuthContext.Provider>
    );
};

// custom hook
const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
