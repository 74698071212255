import React, { useState } from 'react'
import SideBar from './Sidebar'
import { useMediaQuery } from 'react-responsive';
import humBurgerIcon from "../../Assets/hamburger.svg"

function Layout({ children }) {
    const [showSidebar, setShowSidebar] = useState(true);
    const isLargeScreen = useMediaQuery({ query: '(min-width: 720px)' });

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
            <div onClick={() => !showSidebar && setShowSidebar(!showSidebar)} style={{ position: 'absolute', background: "rgba(240, 249, 254, 1)", width: isLargeScreen ? '85vw' : "100vw", left: isLargeScreen ? '15vw' : 0, height: "100%", overflowY: "auto" }}>
                <div className='humburger-icon'>
                    <img src={humBurgerIcon} onClick={() => setShowSidebar(!showSidebar)} alt="menu" />
                </div>
                {children}
            </div>
        </div >
    )
}

export default Layout