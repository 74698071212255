import { FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React from 'react'

function CustomSelect({ label, options, value, name, error, ErrorMessage, onChange, sx, style }) {
    const { values, setFieldValue, handleBlur, touched, errors } = useFormikContext();

    const handleChange = (e) => {
        setFieldValue(name, e.target.value)
    };

    return (
        <div className="input-field" style={style}>
            <InputLabel sx={inputstyle}>{label}</InputLabel>
            <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values[name]}
                label=""
                placeholder='Select'
                onChange={handleChange}
                onBlur={handleBlur(name)}
                error={error}
                sx={{ ...textFieldStyle, ...sx }}
            >
                {options?.map((item) => {
                    return (
                        <MenuItem value={item?.value}>{item?.label}</MenuItem>
                    )
                })}
            </Select>
            {touched[name] && errors[name] && (
                <p className="input-error-message">{error}</p>
            )}
        </div>
    )
}

export default CustomSelect

const inputstyle = {
    color: "black",
    fontSize: "16px",
    padding: "2px 0",
    // marginBottom: "5px",
    marginTop: "10px",
    textAlign: 'start'
};

const textFieldStyle = {
    width: "100%",
    // height: "40px",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "15px",
        padding: "24px 22px"
    },
    "& .MuiInputBase-root ": {
        width: "100%",
        height: "40px",
        padding: "24px 22px"
    },
    "& .MuiFormControl-root": {
        // padding: "0px 10px",
        height: "40px",
    },
    "& .MuiSelect-select": {
        padding: "24px 22px",
        textAlign: 'start',
        fontSize: "18px",
        color: "#536172",
        lineHeight: "25px"
    }
};
