import React from "react";

const Breadcrumbs = ({ folderPath, onBreadcrumbClick }) => {
    return (
        <div className="breadcrumbs">
            {folderPath.map((folder, index) => (
                index === 0 ? <span
                    style={{ cursor: "pointer" }}
                    onClick={() => onBreadcrumbClick(folder.id)}
                >
                    {`Home >  Library ${folderPath.length > 1 ? "> " : ""}`} &nbsp;
                </span> :
                    <span key={folder.id}>
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => onBreadcrumbClick(folder.id)}
                        >
                            {folder.name}
                        </span>
                        {index < folderPath.length - 1 && " > "}&nbsp;
                    </span>
            ))}
        </div>
    );
};

export default Breadcrumbs;