import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../Context/auth';
import "../Css/Profile.css";
import uploadImg from "../Assets/uploadIconWhite.svg"
import deleteIcon from "../Assets/deleteIcon.svg"
import { Formik } from 'formik';
import { Box } from '@mui/material';
import { get, getDatabase, ref, update } from 'firebase/database';
import { app, uploadProfilePicture } from '../Utils/Firebase';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material'
import ChangePasswordDrawer from './Profile/ChangePassword';
import CustomInputField from './Common/CustomInputField';
import { UpdateProfileValidation } from '../Utils/Validation';
import { useMediaQuery } from 'react-responsive';
import UploadFileModal from './Common/UploadFileModal';

const defaultValue = {
    first_name: "",
    last_name: "",
    email: "",
    storage: "",
    no_of_screens: ""
}

const Setting = () => {
    const { user } = useAuth();
    const [initialValues, setInitialValues] = useState(defaultValue);
    const [passwordOpen, setPasswordOpen] = useState(false);
    const [fileOpen, setFileOpen] = useState(false);
    const isLargeScreen = useMediaQuery({ query: '(min-width: 720px)' });
    const userId = localStorage.getItem("id");
    const db = getDatabase(app);
    const formikRef = useRef();

    useEffect(() => {
        setInitialValues({
            ...initialValues,
            ...user
        })
    }, [])


    const handleSubmit = async (values, { resetForm, setSubmitting }) => {
        try {
            const userSnapshot = await get(ref(db, `users/${userId}`));
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                if (values?.image && values?.image instanceof File) {
                    const url = await uploadProfilePicture(values?.image);
                    values.image = url;
                }
                const updates = {};
                updates['/users/' + userId] = { ...userData, first_name: values?.first_name, last_name: values?.last_name, image: values?.image };
                const updateData = await update(ref(db), updates);
                setSubmitting(false);
                toast.success("Profile Updated Successfully.", {
                    closeButton: <Close />
                })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleFileChange = (file) => {
        formikRef.current?.setFieldValue("image", file);
        setFileOpen(false);
    }

    const onRemoveFile = () => {
        formikRef.current?.setFieldValue("image", user?.image);
    }

    return (
        <div className='profile-main-container'>
            <Formik
                initialValues={initialValues}
                validationSchema={UpdateProfileValidation}
                onSubmit={handleSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    getFieldProps
                    /* and other goodies */
                }) => (
                    <>
                        <h2 className='profile-title'>Profile</h2>
                        <p className='profile-subtext'>Update your photo and personal details here.</p>
                        <div className='profile-container'>
                            <div className='profile-photo-box'>
                                <img src={values?.image instanceof File ? URL.createObjectURL(values?.image) : values?.image} alt="profile image" height={"172px"} width={"172px"} />
                                <div>
                                    <p className='profile-picture-text'>Profile Picture</p>
                                    <div className='upload-button-container'>
                                        <button type="submit" className='upload-button' disabled onClick={() => setFileOpen(true)}>
                                            <img src={uploadImg} height={"24px"} width={"24px"} alt="upload image" /> Upload Image
                                        </button>
                                        <button type="submit" className='remove-button' onClick={onRemoveFile}>
                                            <img src={deleteIcon} height={"24px"} width={"24px"} alt="upload image" /> Remove
                                        </button>
                                    </div>
                                    <p className='profile-picture-desc'>We support PNGs, JPEGs and GIFs under 10B</p>
                                </div>
                            </div>
                            <div className='profile-form-section'>

                                <form className='form-container'>
                                    {console.log("values", values)}
                                    <div className='input-container'>
                                        <CustomInputField label="First Name" name="first_name" onChange={handleChange} value={values.first_name} error={touched?.first_name && errors.first_name} style={{ width: isLargeScreen ? "50%" : "100%" }} />
                                        <CustomInputField label="Last Name" name="last_name" onChange={handleChange} value={values.last_name} error={touched?.last_name && errors.last_name} style={{ width: isLargeScreen ? "50%" : "100%" }} />
                                    </div>
                                    <div className='input-container'>
                                        <CustomInputField disabled label="User ID" name="email" onChange={handleChange} style={{ width: isLargeScreen ? "50%" : "100%" }} />
                                        <CustomInputField disabled label="Total Space Alloted" name="storage" onChange={handleChange} style={{ width: isLargeScreen ? "50%" : "100%" }} />
                                    </div>
                                    <div className='input-container'>
                                        <CustomInputField disabled label="Number Of Screens Alloted" name="no_of_screens" onChange={handleChange} style={{ width: isLargeScreen ? "50%" : "100%" }} />
                                        {/* <CustomInputField disabled label="Expiry date of screens" name="email" onChange={handleChange} style={{ width: isLargeScreen ? "50%" : "100%" }} /> */}
                                    </div>
                                    <UploadFileModal title={"Upload Profile Picture"} open={fileOpen} handleClose={() => setFileOpen(!fileOpen)} acceptedFiles={values?.image instanceof File ? values?.image : ""} setAcceptedFiles={handleFileChange} />
                                    <div className='save-button-container'>
                                        <button type="button" onClick={handleSubmit} className='btn12 save-button'>
                                            {isSubmitting ? "Loading..." : "Save Changes"}
                                        </button>
                                        <button type="button" className='change-password-button' onClick={() => setPasswordOpen(true)}>
                                            Change Password
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </>
                )}
            </Formik>
            <ChangePasswordDrawer open={passwordOpen} toggleDrawer={() => setPasswordOpen(false)} />
        </div>
    );
};

export default Setting;
