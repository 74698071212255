import React from 'react'

function Video({ url, type, style, onClick }) {
    return (
        <>
            <div style={{
                position: 'relative', display: 'inline-block', height: "100%",
                width: "100%",
                ...style
            }}>
                <video controls={false} style={{
                    height: "100%",
                    width: "100%"
                }}>
                    <source src={url} type={type} />
                </video>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0.7, // Adjust the opacity as needed
                        cursor: 'pointer',
                    }}
                    onClick={onClick}
                >
                    ▶️
                </div>
            </div>
        </>
    )
}

export default Video