import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { ChangePasswordValidation } from '../../Utils/Validation'
import { Formik } from 'formik'
import { get, getDatabase, push, ref, set, update } from 'firebase/database'
import { app } from '../../Utils/Firebase'
import PasswordField from '../Common/PasswordField'
import AlertModal from '../Common/AlertModal'
import successIcon from "../../Assets/success-change-pass.svg"
import { useAuth } from '../../Context/auth'
import moment from 'moment'
import { History } from '../../Utils/Constant'
import { useMediaQuery } from 'react-responsive'

const defaultValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
}

function ChangePasswordDrawer({ open, toggleDrawer }) {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 720px)' });
    const { user } = useAuth();
    const [initialValues, setInitialValues] = useState(defaultValue);
    const [successOpen, setSuccessOpen] = useState(false);
    const userId = localStorage.getItem("id");
    const db = getDatabase(app);

    const handleSubmit = async (values, { resetForm, setSubmitting, setFieldError }) => {
        try {
            const userSnapshot = await get(ref(db, `users/${userId}`));
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                if (userData?.password === values?.oldPassword) {
                    const updates = {};
                    updates['/users/' + userId] = { ...userData, password: values?.newPassword };
                    const updateData = await update(ref(db), updates);
                    const history = {
                        user_id: userId,
                        title: "Account",
                        status: `${user?.email} password changed successfully.`,
                        timestamp: moment(new Date()).toISOString()
                    }
                    const historyRef = ref(db, History);
                    const newHistoryRef = push(historyRef);
                    set(newHistoryRef, history);
                    resetForm();
                    setSubmitting(false);
                    setSuccessOpen(true);
                    setTimeout(() => {
                        setSuccessOpen(false)
                    }, 4000);
                    toggleDrawer();
                } else {
                    setFieldError("oldPassword", "Invalid Old Password Please Enter correct password.");
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Drawer
            open={open}
            onClose={toggleDrawer}
            direction='right'
            size={isLargeScreen ? '550px' : '300px'}
            lockBackgroundScroll
            style={{
                height: '100%',
                overflow: 'auto'
            }}
        >
            <Box sx={{ padding: { xs: "10px", sm: "20px" } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'sticky' }}>
                    <Typography variant='h6' sx={{ fontSize: "24px", fontWeight: 500 }}>Change Password</Typography>
                </Box>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={ChangePasswordValidation}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <form className='form-container'>
                                <Box sx={{ height: '100%', width: '100%', marginTop: '20px' }}>
                                    <PasswordField label="Old Password" name="oldPassword" onChange={handleChange} value={values.oldPassword} error={touched?.oldPassword && errors.oldPassword} />
                                    <PasswordField label="New Password" name="newPassword" onChange={handleChange} value={values.newPassword} error={touched?.newPassword && errors.newPassword} />
                                    <PasswordField label="Confirm Password" name="confirmPassword" onChange={handleChange} value={values.confirmPassword} error={touched?.confirmPassword && errors.confirmPassword} />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                                    <button type="submit" className='btn12 submitBtn' onClick={handleSubmit} variant="contained" sx={{ width: "200px", margin: "0 30px 30px 0", background: '#3379d0', height: "40px", color: 'white' }}>
                                        {isSubmitting ? "Loading..." : "Change Password"}
                                    </button>
                                </Box>
                            </form>
                        )}
                    </Formik >
                </Box>
            </Box>
            <AlertModal
                open={successOpen}
                handleClose={() => setSuccessOpen(false)}
                title={"Password Changed Successfully"}
                desc={"Please try login with your new Password"}
                icon={successIcon}
                success={true}
            />
        </Drawer>
    )
}

export default ChangePasswordDrawer