import React, { useEffect, useRef } from 'react'
import Layout from '../Components/Layout/Layout'
import { Box, Button, Modal, Typography } from '@mui/material'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FileUploader from '../Components/FileUploader';
import { app, storage, uploadBlobToFirebaseStorage, uploadFiles } from '../Utils/Firebase';
import { equalTo, get, getDatabase, onValue, orderByChild, push, query, ref, remove, set, update } from 'firebase/database';
import { ref as sRef } from 'firebase/storage';
import { toast } from 'react-toastify';
import Close from '@mui/icons-material/Close';
import { Folder, History, Media, Playlist, videoRegex } from '../Utils/Constant';
import AlertDialog from '../Components/Common/AlertDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteObject } from 'firebase/storage';
import { bytesToMB, checkAvailableStorage, fetchFolderSize, generateCustomId } from '../Utils/utils';
import { useAuth } from '../Context/auth';
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";
import CustomButton from '../Components/Common/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import "../Css/Mediafiles.css";
import CustomTabs from '../Components/Common/Tab';
import MediaCard from '../Components/Mediafiles/MediaCard';
import ConfirmationModal from '../Components/Common/ConfirmationModal';
import UploadFileModal from '../Components/Common/UploadFileModal';
import RenameFileNameModal from '../Components/Mediafiles/RenameFileNameModal';
import AlertModal from '../Components/Common/AlertModal';
import checkIcon from "../Assets/checkIcon.svg"
import moment from 'moment';
import CreateFolderModal from '../Components/Mediafiles/CreateFolderModal';
import FolderCard from '../Components/Mediafiles/FolderCard';
import Breadcrumbs from '../Components/Mediafiles/Breadcrumbs';

function MediaFiles() {
    const [formOpen, setFormOpen] = useState(false);
    const [renameOpen, setRenameOpen] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState();
    const [mediafiles, setMediafiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [storageSize, setStorageSize] = useState();
    const [storageAvailable, setStorageAvailable] = useState(true);
    const [storageErrorMsg, setStorageErrorMsg] = useState("");
    const [searchText, setSearchText] = useState("");
    const [renameData, setRenameData] = useState();
    const [fileLoading, setFileLoading] = useState(0);
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [loadingMediafiles, setLoadingMediafiles] = useState(false);
    const [uploadingFile, setUploadingFile] = useState();
    const [folderData, setFolderData] = useState({});
    const [folderOpen, setFolderOpen] = useState(false);
    const id = localStorage.getItem("id");
    const [currentFolderId, setCurrentFolderId] = useState(id);  // Root folder as default
    const [folderPath, setFolderPath] = useState([{ id: id, name: "Home" }]);
    const [allMediaFiles, setAllMediaFiles] = useState();
    const [isFolder, setIsFolder] = useState(false);

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);
    const db = getDatabase(app);
    const imageRegex = /^image\/(jpeg|jpg|png|gif|bmp|webp)$/;
    const { user } = useAuth();

    const getStorageSize = async () => {
        try {
            const storage = await fetchFolderSize(id);
            setStorageSize(storage);
            const res = checkAvailableStorage(user?.storage, storage?.totalSizeInMB);
            setStorageAvailable(res);
            setLoadingMediafiles(false);
        } catch (error) {
            console.log(error);
            setLoadingMediafiles(false);
        }
    }

    const getAllMediaFiles = async () => {
        try {
            const playlistQuery = query(ref(db, Media), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(playlistQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setAllMediaFiles(data);
                }
            });
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const getFolderFiles = async () => {
        try {
            setLoadingMediafiles(true);
            const folderSnapshot = await get(ref(db, `${Folder}/${currentFolderId}`));
            if (folderSnapshot.exists()) {
                const FolderData = folderSnapshot.val();
                setFolderData(FolderData);
                const itemsArray = allMediaFiles && Object.entries(allMediaFiles).map(([key, value]) => {
                    if (FolderData?.files?.includes(key?.toString())) {
                        return {
                            ...value,
                            id: key
                        }
                    }
                    return;
                }).filter(Boolean);
                setMediafiles(itemsArray);
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }

    useEffect(() => {
        try {
            getStorageSize();
            getAllMediaFiles();
        } catch (error) {
            console.log("error", error);
        }
    }, []);

    useEffect(() => {
        try {
            getFolderFiles();
        } catch (error) {
            console.log("error", error);
            setLoadingMediafiles(false);
        }
    }, [currentFolderId, allMediaFiles])


    const uploadFile = async () => {
        try {

            if (!acceptedFiles?.length) return;
            const allFileSizeInBytes = acceptedFiles?.reduce((total, item) => {
                return total + item?.size;
            }, 0);
            const fileSize = bytesToMB(allFileSizeInBytes);
            if ((parseInt(storageSize?.totalSizeInMB) + parseInt(fileSize)) >= parseInt(storageAvailable?.totalStorageInMP)) {
                setStorageErrorMsg("Your Storage is full. Please select small size file.")
                return;
            }
            setLoading(true);
            let url = "";
            // if (acceptedFiles?.type === "video/mp4" || acceptedFiles?.type === "video/x-matroska") {
            //     try {

            //         const ffmpeg = new FFmpeg();
            //         ffmpeg.on("log", ({ message }) => {
            //             console.log("dsdsdsd", message)
            //             // if (messageRef.current) messageRef.current.innerHTML = message;
            //         });
            //         ffmpeg.on("progress", (ratio) => {
            //             console.log("ratio", ratio)
            //             console.log(ratio);
            //         });
            //         await ffmpeg.load();

            //         await ffmpeg.writeFile(acceptedFiles?.name, await fetchFile(acceptedFiles));
            //         console.log("acceptedFiles file:", acceptedFiles);
            //         // await ffmpeg.exec(['-i', 'input.mp4', 'output.mp4']);
            //         // await ffmpeg.exec(['-i', acceptedFiles?.name, '-s', '1280x720', '-crf', '30', '-b:a', '100k', '-r', '20', '-an', '-preset', 'ultrafast', 'output101.mp4']);
            //         // await ffmpeg.exec(['-i', acceptedFiles?.name, '-map_metadata', '-1', '-c:v', 'libx265', '-crf', '23', '-c:a', 'aac', '-b:a', '128k', '-ac', '2', '-tag:v', 'hvc1', '-sn', '-preset', 'ultrafast', 'output101.mp4']);
            //         // await ffmpeg.exec(['-i', acceptedFiles?.name, '-crf', '28', '-b:a', '64k', '-b:a', '64k', '-r', '22', '-preset', 'ultrafast', 'output101.mp4']);
            //         await ffmpeg.exec([
            //             '-i', acceptedFiles.name,
            //             '-c:v', 'libx264',
            //             '-b:v', '200k',
            //             '-r', '25',
            //             '-crf', '25',
            //             '-b:a', '75k',
            //             '-c:a', 'aac',
            //             '-preset', 'fast',
            //             'output101.mp4'
            //         ]);
            //         ffmpeg.on("progress", ({ progress, time }) => {
            //             console.log("progress file:", progress);
            //         })
            //         // Read compressed file data
            //         const data = await ffmpeg.readFile('output101.mp4');
            //         const blobUrl = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }));
            //         url = await uploadBlobToFirebaseStorage(blobUrl, acceptedFiles?.name, id, setFileLoading)
            //         console.log("uploadUrl file:", url, acceptedFiles);
            //     } catch (error) {
            //         console.log("error file:", error);
            //     }
            // } else {
            for await (let file of acceptedFiles) {
                setUploadingFile(file);
                url = await uploadFiles(id, file, setFileLoading);
                // }
                if (url) {
                    const filePayload = {
                        url: url, type: file?.type === "video/x-matroska" ? "video/mp4" : file?.type, user_id: id, name: file?.name, timestamp: moment(new Date()).toISOString()
                    };
                    const fileId = generateCustomId();
                    await set(ref(db, `${Media}/${fileId}`), filePayload);

                    const folderRef = ref(db, `${Folder}/${currentFolderId}`);
                    const folderSnapshot = await get(folderRef);
                    const FolderData = folderSnapshot.val();
                    const folderFiles = Array.isArray(FolderData?.files) ? FolderData.files : [];
                    const folderPayload = {
                        ...FolderData,
                        files: [...folderFiles, fileId]
                    }
                    await set(ref(db, `${Folder}/${currentFolderId}`), folderPayload);
                    const history = {
                        user_id: id,
                        title: "Media File",
                        status: `${file?.name} has been added successfully.`,
                        timestamp: moment(new Date()).toISOString()
                    }
                    const historyRef = ref(db, History);
                    const newHistoryRef = push(historyRef);
                    set(newHistoryRef, history);
                }
            }
            setAcceptedFiles();
            getStorageSize();
            getFolderFiles();
            getAllMediaFiles();
            toast.success("File Uploaded successfully.", {
                closeButton: <Close />
            })
            setFormOpen(false);
            setLoading(false);
            setStorageErrorMsg("");
        } catch (error) {
            setLoading(false);
            console.log({ error })
        }
    };

    const handleDeleteScreen = async () => {
        try {
            const fileRef = sRef(storage, selectedFile?.url);

            deleteObject(fileRef).then((res) => {
                // File deleted successfully
                const screenRef = ref(db, `${Media}/${selectedFile?.id}`);
                remove(screenRef).then(async (res) => {
                    const updatedFiles = folderData?.files?.filter(item => item !== selectedFile?.id);
                    const folderPayload = {
                        ...folderData,
                        files: updatedFiles
                    }
                    await set(ref(db, `${Folder}/${currentFolderId}`), folderPayload);
                    const playlistQuery = query(ref(db, Playlist), orderByChild('user_id'), equalTo(id));
                    const filterData = onValue(playlistQuery, (snapshot) => {
                        if (snapshot.exists()) {
                            const data = snapshot.val();
                            const itemsArray = Object.entries(data).map(([key, value]) => ({
                                ...value,
                                id: key
                            }));
                            const datafilter = JSON.stringify(itemsArray);
                            const datass = JSON.parse(datafilter);
                            itemsArray.forEach(item => {
                                item?.data?.zone?.forEach((subArray, index) => {
                                    const foundIndex = subArray?.findIndex(obj => obj?.url === selectedFile?.url);
                                    if (foundIndex !== -1) {
                                        subArray?.splice(foundIndex, 1);
                                    }
                                });
                            });
                            itemsArray.forEach(async (item) => {
                                // Check if the item has a "data" property
                                if (item?.data && item?.data?.collage) {
                                    if (item?.data?.collage?.screen) {
                                        // Loop through each screen item
                                        item?.data?.collage?.screen.forEach(screenItem => {
                                            // Check if the id matches the specified value
                                            if (screenItem?.url === selectedFile?.url) {
                                                // Empty the properties instead of removing them
                                                screenItem.id = null;
                                                screenItem.name = null;
                                                screenItem.type = null;
                                                screenItem.url = null;
                                                screenItem.user_id = null;
                                            }
                                            // Check if the "smallScreen" array exists
                                            if (screenItem?.smallScreen) {
                                                // Loop through each smallScreen item
                                                screenItem.smallScreen.forEach(smallScreenItem => {
                                                    // Empty the properties instead of removing them
                                                    if (smallScreenItem.url === selectedFile?.url) {
                                                        smallScreenItem.id = null;
                                                        smallScreenItem.name = null;
                                                        smallScreenItem.type = null;
                                                        smallScreenItem.url = null;
                                                        smallScreenItem.user_id = null;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                                const updates = {};
                                updates['/Playlist/' + item?.id] = {
                                    ...item
                                }
                                await update(ref(db), updates);
                            });
                        } else {
                            console.log('No data found');
                        }
                    });
                    setDeleteModel(false);
                    setSelectedFile();
                    getStorageSize();
                    setDeleteSuccessOpen(true);
                    setTimeout(() => {
                        setDeleteSuccessOpen(false);
                    }, 4000);
                });
            }).catch((error) => {
                setDeleteModel(false);
                setSelectedFile();
                console.log(" error", error)
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteIcon = (item) => {
        setSelectedFile(item);
        setDeleteModel(true);
        setIsFolder(false);
    }

    let filteredData = [];
    let filteredFolderData = [];

    if (searchText === "") {
        filteredData = mediafiles;
        filteredFolderData = folderData.folders;
    } else if (!mediafiles) {
        filteredData = [];
    } else {
        filteredData = mediafiles?.filter((item) => {
            const name = item?.name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
        filteredFolderData = folderData.folders?.filter((item) => {
            const name = item?.name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
    }

    const handleRenameClose = () => {
        setRenameOpen(false);
        setIsFolder(false);
    }

    const handleRename = (item) => {
        setRenameData(item);
        setRenameOpen(true);
    }

    // const tabsItems = [
    //     {
    //         title: "All",
    //         component: <MediaCard mediaFiles={filteredData} handleDeleteIcon={handleDeleteIcon} handleRename={handleRename} />
    //     },
    //     {
    //         title: "Images",
    //         component: <MediaCard mediaFiles={filteredData.filter(item => imageRegex.test(item?.type))} handleDeleteIcon={handleDeleteIcon} handleRename={handleRename} />
    //     },
    //     {
    //         title: "Videos",
    //         component: <MediaCard mediaFiles={filteredData.filter(item => videoRegex.test(item?.type))} handleDeleteIcon={handleDeleteIcon} handleRename={handleRename} />
    //     },
    // ];

    const handleUploadOpen = () => {
        setFormOpen(true);
        setFileLoading(0);
        closeNav();
    }

    const handleFolderClose = () => {
        setFolderOpen(false);
    }

    const handleFolderClick = (folderId) => {
        const folderName = folderData.folders?.find(item => item?.id === folderId)?.name || folderData.name; // Retrieve folder name
        setFolderPath([...folderPath, { id: folderId, name: folderName }]);
        setCurrentFolderId(folderId);
    };

    // Handle breadcrumb click to navigate to a folder
    const handleBreadcrumbClick = (folderId) => {
        const index = folderPath.findIndex((folder) => folder.id === folderId);
        const newPath = folderPath.slice(0, index + 1);
        setFolderPath(newPath);
        setCurrentFolderId(folderId);
    };

    const handleDeleteFolder = (item) => {
        setIsFolder(true);
        setSelectedFile(item);
        setDeleteModel(true);
    }

    const handleRenameFolder = (item) => {
        setIsFolder(true);
        setRenameData(item);
        setRenameOpen(true);
    }

    // Helper function to recursively delete a folder and its contents
    const deleteFolderAndContents = async (folderId) => {
        try {
            const folderRef = ref(db, `${Folder}/${folderId}`);
            const folderSnapshot = await get(folderRef);

            if (!folderSnapshot.exists()) return;

            const FolderData = folderSnapshot.val();

            // Delete all files in the folder
            if (FolderData?.files) {
                await Promise.all(FolderData.files.map(async (fileId) => {
                    const fileData = allMediaFiles?.[fileId];
                    if (fileData) {
                        const fileRef = sRef(storage, fileData.url);
                        await deleteObject(fileRef);
                    }
                }));
            }

            // Recursively delete nested folders
            if (FolderData?.folders) {
                await Promise.all(FolderData.folders.map(async (subFolder) => {
                    await deleteFolderAndContents(subFolder.id);
                }));
            }

            // Remove the folder itself
            await remove(folderRef);

        } catch (error) {
            console.log(error);
        }
    };


    const handleDeleteFolderYes = async () => {
        if (!selectedFile?.id) return;
        setLoading(true);
        const updatedFolders = folderData?.folders?.filter(item => item?.id !== selectedFile?.id);
        console.log('updatedFolders: ', updatedFolders);
        const folderPayload = {
            ...folderData,
            folders: updatedFolders
        }
        await set(ref(db, `${Folder}/${currentFolderId}`), folderPayload);
        await deleteFolderAndContents(selectedFile.id);
        setIsFolder(false);
        setSelectedFile();
        setDeleteModel(false);
        setLoading(false);
        getFolderFiles();
    };

    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Breadcrumbs folderPath={folderPath} onBreadcrumbClick={handleBreadcrumbClick} />
                        </div>
                        {!storageAvailable?.isStorageAvailable && !loadingMediafiles && <p style={{ color: "red" }}>Your Storage is full!</p>}
                        <div className='button-container'>
                            <CustomButton leftIcon={<AddIcon />} disabled={!storageAvailable?.isStorageAvailable} onClick={() => (setFormOpen(true), setFileLoading(0))} label="Upload Media" background="rgba(49, 0, 255, 1)" />
                            <CustomButton leftIcon={<AddIcon />} onClick={() => setFolderOpen(true)} label="Create Folder" background="rgba(49, 0, 255, 1)" />
                        </div>
                        <div className={`overlay ${isOpen ? 'open' : ''}`}>
                            <button className='add-button closebtn' onClick={closeNav}><CloseIcon /></button>
                            <div className="overlay-content">
                                <CustomButton leftIcon={<AddIcon />} disabled={!storageAvailable?.isStorageAvailable} onClick={handleUploadOpen} label="Upload Media" background="rgba(0, 0, 0, 1)" />
                                <CustomButton leftIcon={<AddIcon />} onClick={() => setFolderOpen(true)} label="Create Folder" background="rgba(0, 0, 0, 1)" />
                            </div>
                        </div>
                        <button className='add-button' onClick={openNav}><AddIcon /></button>
                    </div>
                    <div className='file-header'>
                        <div>
                            <p className='file-text'>Media</p>
                            {/* <p className='total-file-text'>Total files - {mediafiles?.length} </p> */}
                        </div>
                        <div style={{ display: 'flex', gap: "10px" }}>
                            <div class="wrapper">
                                <div class="icon"><SearchIcon color='rgba(135, 142, 171, 1)' /></div>
                                <input onChange={(e) => setSearchText(e.target.value)} className='search-input' placeholder='Search by Name' />
                            </div>
                            <CustomButton onClick={() => { }} leftIcon={<TuneIcon style={{ height: 18, width: 18 }} />} label="Filters" background="rgba(13, 110, 253, 0.3)" />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '20px' }}>
                        {filteredFolderData?.length ? <FolderCard folder={filteredFolderData} handleFolderClick={handleFolderClick} handleDeleteIcon={handleDeleteFolder} handleRenameFolder={handleRenameFolder} /> : ""}
                        {filteredData?.length ? <MediaCard mediaFiles={filteredData} handleDeleteIcon={handleDeleteIcon} handleRename={handleRename} /> : ""}
                        {!folderData?.folders?.length && !filteredData?.length && <p className='mediafile-not-found'>No Files Found</p>}
                        {/* <CustomTabs tabItems={tabsItems} tabsSx={{ '& .MuiTabs-flexContainer': { justifyContent: { xs: 'center', sm: "start" } } }} /> */}
                    </div>
                </div>
            </div>
            <UploadFileModal
                isMultiple={true}
                uploadingFile={uploadingFile}
                title={"Upload Media"}
                open={formOpen}
                handleUpload={uploadFile}
                loading={loading}
                storageErrorMsg={storageErrorMsg}
                handleClose={() => setFormOpen(!formOpen)}
                acceptedFiles={acceptedFiles}
                setAcceptedFiles={setAcceptedFiles}
                setStorageErrorMsg={setStorageErrorMsg}
                progress={fileLoading} />
            <ConfirmationModal
                open={deleteModel}
                setOpen={setDeleteModel}
                handleYes={isFolder ? handleDeleteFolderYes : handleDeleteScreen}
                handleClose={() => setDeleteModel(false)}
                title="Are your sure?"
                desc={isFolder ? "Are your sure you want to delete folder? This will delete all your files and folders inside this folder." : "Are your sure you want to delete file? This file may be assigned to multiple slots. Deleting this file will remove it from all slots."}
                disabled={loading}
            />
            <AlertModal
                open={deleteSuccessOpen}
                handleClose={() => setDeleteSuccessOpen(false)}
                title={`Deleted Successfully`}
                desc={"The file is removed from all the assigned slots"}
                icon={checkIcon}
                success={true}
            />
            <RenameFileNameModal
                isFolder={isFolder}
                open={renameOpen}
                handleClose={handleRenameClose}
                fileData={renameData}
                getAllMediaFiles={getAllMediaFiles}
                getFolderFiles={getFolderFiles}
                folderData={folderData}
                currentFolderId={currentFolderId} />
            <CreateFolderModal open={folderOpen} handleClose={handleFolderClose} currentFolderId={currentFolderId} folderData={folderData} getFolderFiles={getFolderFiles} />
        </Layout>
    )
}

export default MediaFiles
