import { Button } from '@mui/material'
import React from 'react'

function PlaylistSettingTopbar({ setIsVertical, navigate, isVertical, handleUpdatePlaylist, name, isFileUploading, isLogoUploading }) {
    return (
        <div className='playlist-topbar-container'>
            <p>{name}</p>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0px', gap: "15px" }}>
                <button className={!isVertical ? "blue-outlined-btn" : "blue-contained-btn"} onClick={() => setIsVertical(true)} sx={{ border: isVertical ? '2px solid black' : "", width: "110px", background: '#3379d0', height: "35px", color: 'white', borderRadius: '10px', margin: '10px' }}>
                    Vertical
                </button>
                <button className={isVertical ? "blue-outlined-btn" : "blue-contained-btn"} onClick={() => setIsVertical(false)} sx={{ border: !isVertical ? '2px solid black' : "", width: "110px", background: '#3379d0', height: "35px", color: 'white', borderRadius: '10px', margin: '10px' }}>
                    Horizontal
                </button>
            </div>
            <div style={{ display: 'flex', margin: '10px 10px 0px 10px', gap: "15px" }}>
                <button className='white-outlined-btn' onClick={() => navigate(-1)} sx={{ width: "20px", background: '#3379d0', height: "35px", color: 'white', borderRadius: '10px', }}>
                    Back
                </button>
                <button className='white-contained-btn' disabled={isFileUploading || isLogoUploading} onClick={handleUpdatePlaylist} sx={{ width: "20px", background: '#3379d0', height: "35px", color: 'white', borderRadius: '10px', marginLeft: '10px' }}>
                    Save
                </button>
            </div>

        </div>
    )
}

export default PlaylistSettingTopbar