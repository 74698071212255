import { DataGrid } from '@mui/x-data-grid';
import React from 'react'

function Schedule({ data = [] }) {

    const columns = [
        { field: 'playlist_name', headerName: 'Playlist Name', width: 250 },
        {
            field: 'Daily', headerName: 'Daily', width: 150,
            renderCell: (params) => (
                <>
                    <p>
                        {params.row.daily ? "Yes" : "No"}
                    </p>
                </>
            ),
        },
        { field: 'startDate', headerName: 'Start Date', width: 150 },
        { field: 'startTime', headerName: 'Start Time', width: 150 },
        { field: 'endDate', headerName: 'End Date', width: 150 },
        { field: 'endTime', headerName: 'End Time', width: 150 }
    ];

    return (
        <div style={{ height: 'auto', width: '100%' }}>
            <h2>All Schedules</h2>
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                style={{ background: "white" }}
            />
        </div>
    )
}

export default Schedule