export const Media = 'Media';
export const Folder = 'Folder';
export const Screens = 'Screens';
export const Playlist = 'Playlist';
export const Area = 'Area';
export const YoutubeWidgets = 'youtubeWidget';
export const WebpageWidgets = 'webpageWidgets';
export const AllWidgets = 'allWidgets';
export const History = 'History';
export const AreaGroup = 'AreaGroup';
export const imageRegex = /^image\/(jpeg|jpg|png|gif|bmp|webp)$/;
export const videoRegex = /^video\/(mp4|webm|ogg|avi|mov)$/;

export const CMSRoute = ["/login", "/forgot-password"];

export const clockFormatOption = [
    { label: "DD/MM/YYYY 12hr", value: "'dd/MM/yyyy hh:mm a" },
    { label: "MM/DD/YYYY 12hr", value: "MM/dd/yyyy hh:mm a" },
    { label: "Day,Month Year 12hr", value: "EEEE, MMMM dd yyyy hh:mm a" },
    { label: "DD/MM/YYYY 24hr", value: "dd/MM/yyyy HH:mm" },
    { label: "MM/DD/YYYY 24hr", value: "MM/dd/yyyy HH:mm" },
    { label: "Day,Month Year 24hr", value: "EEEE, MMMM dd yyyy HH:mm" },
];