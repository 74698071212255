import { Backdrop, Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Fade from '@mui/material/Fade';
import successImage from "../../Assets/success-back.svg"
import checkIcon from "../../Assets/checkIcon.svg"
import CloseIcon from '@mui/icons-material/Close';
import CustomInputField from '../Common/CustomInputField';
import { get, getDatabase, ref, update } from 'firebase/database';
import { app } from '../../Utils/Firebase';
import { Formik } from 'formik';
import { RenameFileNameValidation } from '../../Utils/Validation';
import { Folder, Media } from '../../Utils/Constant';
import AlertModal from '../Common/AlertModal';

const defaultValues = { name: "" };

function RenameFileNameModal({ open, handleClose, fileData, isFolder, getAllMediaFiles, getFolderFiles, currentFolderId, folderData }) {
    const [initialValues, setInitialValues] = useState(defaultValues);
    const [successOpen, setSuccessOpen] = useState(false);
    const db = getDatabase(app);

    useEffect(() => {
        if (fileData) {
            setInitialValues({
                name: fileData?.name
            })
        }
    }, [fileData])


    const handleUpdateChanges = async (values) => {
        try {
            if (fileData?.id) {
                const updates = {};
                updates[`/${Media}/` + fileData?.id] = {
                    ...fileData,
                    name: values?.name,
                };
                delete updates[`/${Media}/` + fileData?.id]?.id;
                await update(ref(db), updates);
                getAllMediaFiles();
                handleClose();
                setSuccessOpen(true);
                setTimeout(() => {
                    setSuccessOpen(false)
                }, 4000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdateFolderName = async (values) => {
        try {
            if (fileData?.id) {
                const folderRef = ref(db, `${Folder}/${fileData?.id}`);
                const folderSnapshot = await get(folderRef);
                const FolderData = folderSnapshot.val();

                const updates = {};
                updates[`/${Folder}/` + fileData?.id] = {
                    ...FolderData,
                    name: values?.name,
                };
                await update(ref(db), updates);

                const updatesCurrentFolder = {};
                const updatedFolders = folderData?.folders?.map(item => {
                    if (item?.id === fileData?.id) {
                        return {
                            ...item,
                            name: values?.name
                        }
                    } else {
                        return {
                            ...item
                        }
                    }
                })
                updatesCurrentFolder[`/${Folder}/` + currentFolderId] = {
                    ...folderData,
                    folders: updatedFolders
                };
                await update(ref(db), updatesCurrentFolder);
                getFolderFiles();
                setSuccessOpen(true);
                setTimeout(() => {
                    setSuccessOpen(false)
                    handleClose();
                }, 4000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (<>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={RenameFileNameValidation}
                        onSubmit={isFolder ? handleUpdateFolderName : handleUpdateChanges}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <>
                                <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div>
                                <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={handleClose} /></div>
                                <div className='modal-container'>
                                    <p className='modal-title'>{isFolder ? "Rename Folder" : "Rename File"}</p>
                                    <CustomInputField label="Name" name="name" onChange={handleChange} error={touched?.name && errors.name} value={values?.name} style={{ width: "80%" }} />
                                    <button type='submit' onClick={handleSubmit} disabled={isSubmitting} className='btn12' style={{ padding: "22px 40px", width: "80%" }}>
                                        Update Changes
                                    </button>
                                </div>
                            </>
                        )}
                    </Formik >
                </Box>
            </Fade>
        </Modal>
        <AlertModal
            open={successOpen}
            handleClose={() => setSuccessOpen(false)}
            title={`Changes Updated`}
            desc={`${isFolder ? "Folder" : "File"} name changed successfully`}
            icon={checkIcon}
            success={true}
        />
    </>
    )
}

export default RenameFileNameModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: "90%",
        sm: '650px',
    },
    height: '400px',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px"
};