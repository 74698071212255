import { API_BASE_URL } from "./axios";
import { v4 as uuidv4 } from 'uuid';
import { City, State } from 'country-state-city';

export const fetchFolderSize = async (id) => {
    try {
        const response = await fetch(
            `${API_BASE_URL}/api/folder-size?folderPath=${id}`
        );
        const data = await response.json();
        console.log("data========", data)
        return data;
    } catch (error) {
        console.error("Error fetching folder size:", error);
    }
}

const convertGBToMP = (gb) => {
    return parseInt(gb) * 1024;
}

export function bytesToMB(bytes) {
    return bytes / (1024 * 1024);
}

export const checkAvailableStorage = (totalSize, currentSize) => {
    const totalSizeSplit = totalSize?.split(" ");
    const storageType = totalSizeSplit?.[1];
    console.log("storage type", storageType)
    let totalSizeInMP;
    if (storageType === "GB") {
        totalSizeInMP = parseInt(totalSizeSplit?.[0]) * 1024;
    } else if (storageType === "MP") {
        totalSizeInMP = totalSizeSplit?.[0];
    } else {
        return "Invalid"
    }
    console.log("totalSizeInMP------------", totalSizeInMP, totalSizeInMP > currentSize ? true : false)
    return {
        isStorageAvailable: parseInt(totalSizeInMP) > parseInt(currentSize) ? true : false,
        totalStorageInMP: totalSizeInMP,
        currentStorage: currentSize
    }

}

export const filterAscDescData = (data, sort, from, limit) => {
    if (sort) {
        const [sortColumn, sortDirection] = sort.startsWith("-")
            ? [sort.slice(1), "desc"]
            : [sort, "asc"];
        data = data.sort((a, b) => {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];

            if (typeof aValue === "number" && typeof bValue === "number") {
                return sortDirection === "desc" ? bValue - aValue : aValue - bValue;
            } else {
                const stringA = String(aValue);
                const stringB = String(bValue);
                return sortDirection === "desc"
                    ? stringB.localeCompare(stringA)
                    : stringA.localeCompare(stringB);
            }
        });
    }
    const filteredResult = data.slice(from, from + limit);
    return filteredResult;
}

export const generateCustomId = () => {
    const uuid = uuidv4();  // Generates something like 'f47ac10b-58cc-4372-a567-0e02b2c3d479'

    const baseId = uuid.replace(/-/g, '');  // Remove dashes

    // Customize the ID to match '-NutrnEotiN7Rvw5-u_9'
    const customId = `-${baseId.substring(0, 7)}${baseId.substring(8, 20)}`;
    return customId;
};

export const getAllCities = (code) => {
    const indianCities = City.getCitiesOfState('IN', code);
    const cityNames = indianCities.map(city => city.name);
    return cityNames;
};

export const getAllState = () => {
    const indianState = State.getStatesOfCountry('IN');
    const stateNames = indianState.map(state => ({ name: state.name, code: state?.isoCode }));
    return stateNames;
};
