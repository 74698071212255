import { Backdrop, Box, Modal, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import uploadImg from "../../Assets/upload-img.svg"
import { useDropzone } from "react-dropzone";
import { Close } from '@mui/icons-material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { bytesToMB } from '../../Utils/utils';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function UploadFileModal({ title, open, handleClose, acceptedFiles, setAcceptedFiles, setStorageErrorMsg, handleUpload, storageErrorMsg, loading, progress, isMultiple = false, uploadingFile }) {

    const onDrop = useCallback((newFiles) => {
        if (isMultiple) {
            setAcceptedFiles(newFiles);
        } else {
            setAcceptedFiles(newFiles?.[0]);
        }
        setStorageErrorMsg("");
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    const handleRemoveFile = (e, item) => {
        if (loading) return;
        if (!isMultiple) {
            setAcceptedFiles();
        } else {
            e.stopPropagation();
            const filterData = acceptedFiles?.filter(file => file?.path !== item?.path);
            setAcceptedFiles(filterData);
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={handleClose} /></div>
                    <div className='upload-media-modal-container'>
                        <p className='modal-title-upload-file'>{title}</p>
                        {storageErrorMsg && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ fontSize: '16px', color: 'red' }}>{storageErrorMsg}</Typography>
                        </Box>}
                        <div className='upload-file-container' style={{ gap: isMultiple ? "5px" : "0px" }} {...getRootProps()} disabled={loading}>
                            {acceptedFiles && !isMultiple ? <Box sx={{ background: 'white', boxShadow: "0px 0px 10px grey", position: "relative", padding: "20px", borderRadius: "10px" }}>
                                <Close sx={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }} onClick={(e) => handleRemoveFile(e)} />
                                <Typography variant="h6">{acceptedFiles?.name}</Typography>
                            </Box> : acceptedFiles && isMultiple ? acceptedFiles?.map(item => <Box sx={{ background: 'white', boxShadow: "0px 0px 10px grey", position: "relative", padding: "5px", borderRadius: "5px" }}>
                                <Close sx={{ position: 'absolute', right: 0, top: -5, cursor: 'pointer' }} onClick={(e) => handleRemoveFile(e, item)} />
                                <Typography variant="h6">{item?.name}</Typography>
                            </Box>) : <><img src={uploadImg} alt="upload img" />
                                <p className='drop-img-title'>Drop your image here, or <span><input {...getInputProps()} disabled={loading} />browse</span></p>
                                <p className='drop-img-desc'>Supports PNG, JPG, JPEG</p></>}
                        </div>
                        {loading ? <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={progress} />
                            {progress === 0 ? "Uploading..." : (isMultiple && progress) ? `Uploading.. ${uploadingFile?.name}  ${bytesToMB(uploadingFile?.size)?.toFixed(2)}MP` : ""}
                        </Box> : ""}
                        <button onClick={handleUpload} disabled={loading || !acceptedFiles} className='btn12' style={{ padding: "22px 40px" }}>
                            {loading ? "Uploading..." : "Upload"}
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default UploadFileModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        sm: '550px',
    },
    height: '440px',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px"
};