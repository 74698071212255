import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CustomTable from '../Common/Table'
import CustomButton from '../Common/CustomButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ScreenFormDrawer from '../Screens/ScreenFormDrawer';
import { child, get, getDatabase, push, ref, remove, set, update } from 'firebase/database';
import { app } from '../../Utils/Firebase';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { filterAscDescData } from '../../Utils/utils';
import { isEqual } from 'lodash';
import { useAuth } from '../../Context/auth';
import AlertModal from '../Common/AlertModal';
import errorIcon from "../../Assets/error-icn.svg";
import successIcon from "../../Assets/success-icon.svg";
import { History } from '../../Utils/Constant';
import RefreshIcon from '@mui/icons-material/Refresh';

const defaultValue = {
    pairing_code: "",
    screen_name: "",
    screen_tag: "",
    location: "",
    city: "",
    state: "",
    country: "",
    area: "",
}

function ScreenTable({ screens, loading }) {
    const [isOpen, setIsOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(defaultValue);
    const [isEdit, setIsEdit] = useState(false);
    const [filter, setFilter] = useState({});
    const [pageCount, setPageCount] = useState(0);
    const [screenData, setScreenData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [successOpen, setSuccessOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isTrasferScreen, setIsTrasferScreen] = useState(false);
    console.log('isTrasferScreen: ', isTrasferScreen);
    const db = getDatabase(app);
    const id = localStorage.getItem("id");
    const fetchIdRef = useRef(0);
    const { user } = useAuth();
    const max_no_of_screens = user?.no_of_screens;

    const navigate = useNavigate();
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
        setInitialValues(defaultValue);
        setIsTrasferScreen(false);
    }

    const handleView = (data) => {
        setIsOpen(true);
        setInitialValues(data);
        setIsEdit(true);
        setIsTrasferScreen(false);
    }

    const handleTransfer = (data) => {
        setIsOpen(true);
        setInitialValues(data);
        setIsEdit(false);
        setIsTrasferScreen(true);
    }

    const handleRefreshScreen = async (item) => {
        delete item?.index;
        delete item?.status;
        try {
            const updates = {};
            updates[`Screens/${item?.pairing_code}`] = {
                ...item,
                refresh_time: new Date()
            }
            console.log(updates)
            await update(ref(db), updates);
            toast.success("Screen Refreshed successfully.", {
                closeButton: <Close />
            });
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const handleBackButtonBlockage = async (value, item) => {
        delete item?.index;
        delete item?.status;
        try {
            const updates = {};
            updates[`Screens/${item?.pairing_code}`] = {
                ...item,
                back_button_blockage: value
            }
            console.log(updates)
            await update(ref(db), updates);
            toast.success("Screen back button blockage updated successfully.", {
                closeButton: <Close />
            });
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const columns = useMemo(
        () => [
            {
                accessor: 'index',
                Header: 'Sr.no',
                width: "10vw",
                Cell: ({ row }) => {
                    return <p>{row?.original?.index}</p>
                }
            },
            {
                accessor: 'screen_name',
                Header: 'Screen Name',
                width: "15%",
            },
            {
                accessor: 'pairing_code',
                Header: 'Pairing Code',
                width: "15%",
            },
            {
                accessor: 'endDate',
                Header: 'Date of Expiry',
                width: "13%",
            },
            {
                accessor: 'startDate',
                Header: 'Date of Pairing',
                width: "13%",
            },
            {
                accessor: 'status',
                Header: 'Status',
                width: "10%",
            },
            {
                accessor: "refresh",
                Header: "Screen Refresh",
                width: "10%",
                Cell: ({ row }) => (
                    <>
                        <div className="cellAction">
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleRefreshScreen(row?.original)}
                            >
                                <RefreshIcon />
                            </div>
                        </div>
                    </>
                ),
            },
            {
                accessor: "back_button_blockage",
                Header: "Back button blockage",
                width: "12%",
                Cell: ({ row }) => (
                    <>
                        {console.log('row?.original=-=-: ', row?.original)}
                        <div className="cellAction">
                            <Switch
                                checked={row?.original?.back_button_blockage || false}
                                onChange={(event) => handleBackButtonBlockage(event.target.checked, row?.original)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    </>
                ),
            },
            {
                accessor: "transfer",
                Header: "Screen Transfer",
                // width: "12%",
                Cell: ({ row }) => {
                    return <Button variant="contained" onClick={() => handleTransfer(row?.original)} sx={{
                        width: "auto", background: 'rgba(13, 110, 253, 1)', borderRadius: "30px", height: "30px", color: 'white', fontFamily: "Montserrat", ":hover": {
                            background: 'rgba(13, 110, 253, 1)',
                            opacity: 0.8
                        },
                        fontSize: "10.3px",
                        fontWeight: 600,
                    }}>
                        Transfer
                    </Button>
                }
            },
            {
                accessor: 'actions',
                Header: 'Action',
                width: "15%",
                Cell: ({ row }) => {
                    return <Button variant="contained" onClick={() => handleView(row?.original)} sx={{
                        width: "auto", background: 'rgba(13, 110, 253, 1)', borderRadius: "30px", height: "30px", color: 'white', fontFamily: "Montserrat", ":hover": {
                            background: 'rgba(13, 110, 253, 1)',
                            opacity: 0.8
                        },
                        fontSize: "10.3px",
                        fontWeight: 600,
                    }}>
                        View More&nbsp;<ArrowForwardIosIcon style={{ height: 16, width: 16 }} />
                    </Button>
                }
            },
        ],
        []
    );

    let filteredData = [];

    if (searchText === "") {
        filteredData = screens;
    } else if (!screens) {
        filteredData = [];
    } else {
        filteredData = screens?.filter((item) => {
            const name = item?.screen_name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
    }


    useEffect(() => {
        const { limit, from, sort, ...props } = filter;
        if (filter && filter.from > -1) {
            if (filteredData?.length && limit) {
                setPageCount(Math.ceil((filteredData?.length) / limit));
                const allData = [...filteredData];
                const filteredResult = filterAscDescData(allData, sort, from, limit);
                setScreenData(filteredResult);
            } else {
                setScreenData([]);
            }
        }
    }, [filter, filteredData]);

    const fetchData = useCallback((oFilter) => {
        const fetchId = ++fetchIdRef.current;
        // if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
        setFilter(oFilter);
        // }
    }, []);

    const handleSubmit = async (values, { resetForm, setSubmitting, setFieldError, setFieldTouched }) => {
        console.log(values)
        try {
            if (isEdit) {
                const updates = {};
                updates[`Screens/${values?.pairing_code}`] = {
                    ...values,
                }
                console.log(updates)
                await update(ref(db), updates);
                resetForm();
                setInitialValues(defaultValue);
                setSubmitting(false);
                // setSuccessOpen(true);
                toast.success("Screen updated successfully.", {
                    closeButton: <Close />
                });
                setIsOpen(false);
                // setTimeout(() => {
                //     setSuccessOpen(false)
                // }, 4000);
                // setIsOpen(false);
            } else if (isTrasferScreen) {
                if (values?.new_pairing_code) {
                    const dbRef = ref(getDatabase());
                    get(child(dbRef, `pairingCodes/${values?.new_pairing_code}`)).then(async (snapshot) => {
                        const spanData = snapshot.val();
                        if (snapshot.exists() && !spanData?.userId && !spanData?.isConnected) {
                            const updates = {};
                            updates['/pairingCodes/' + values?.new_pairing_code] = { ...spanData, isConnected: true, userId: id };
                            const updateData = update(ref(db), updates);
                            const value = {
                                ...values,
                                user_id: id,
                                pairing_code: values?.new_pairing_code
                            };
                            delete value?.new_pairing_code;
                            delete value?.id;
                            console.log(value)
                            await set(ref(db, `Screens/${values?.new_pairing_code}`), value);
                            const oldPairingCodeRef = ref(db, `pairingCodes/${values?.pairing_code}`);
                            await remove(oldPairingCodeRef);
                            const oldScreenRef = ref(db, `Screens/${values?.pairing_code}`);
                            await remove(oldScreenRef);
                            const history = {
                                user_id: id,
                                title: "Screen",
                                status: `${values?.screen_name} screen transfered successfully.`,
                                timestamp: moment(new Date()).toISOString()
                            }
                            const historyRef = ref(db, History);
                            const newHistoryRef = push(historyRef);
                            set(newHistoryRef, history);
                            resetForm();
                            setSubmitting(false);
                            setIsOpen(false);
                            setInitialValues(defaultValue);
                            setIsSuccess(true);
                            setSuccessOpen(true);
                            setIsTrasferScreen(false);
                            setTimeout(() => {
                                setSuccessOpen(false)
                            }, 4000);
                        } else {
                            console.log("No data available");
                            setIsSuccess(false);
                            setSuccessOpen(true);
                            setTimeout(() => {
                                setSuccessOpen(false)
                            }, 4000);
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    setFieldError("new_pairing_code", "Please enter new pairing code.");
                }
            }
            else {
                const value = {
                    ...values,
                    user_id: id,
                    startDate: moment(new Date()).format("YYYY-MM-DD"),
                    endDate: moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format("YYYY-MM-DD")
                }
                console.log(value)
                const dbRef = ref(getDatabase());
                get(child(dbRef, `pairingCodes/${values?.pairing_code}`)).then(async (snapshot) => {
                    const spanData = snapshot.val();
                    if (snapshot.exists() && !spanData?.userId) {
                        const updates = {};
                        updates['/pairingCodes/' + spanData?.pairingCode] = { ...spanData, isConnected: true, userId: id };
                        const updateData = update(ref(db), updates);
                        await set(ref(db, `Screens/${spanData?.pairingCode}`), value);
                        const history = {
                            user_id: id,
                            title: "Screen",
                            status: `${values?.screen_name} screen paired successfully.`,
                            timestamp: moment(new Date()).toISOString()
                        }
                        const historyRef = ref(db, History);
                        const newHistoryRef = push(historyRef);
                        set(newHistoryRef, history);
                        resetForm();
                        setSubmitting(false);
                        setIsOpen(false);
                        setInitialValues(defaultValue);
                        setIsSuccess(true);
                        setSuccessOpen(true);
                        setTimeout(() => {
                            setSuccessOpen(false)
                        }, 4000);
                    } else {
                        console.log("No data available");
                        setIsSuccess(false);
                        setSuccessOpen(true);
                        setTimeout(() => {
                            setSuccessOpen(false)
                        }, 4000);
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    const defaultOptions = {
        pageSize: 5
    }

    const handleNewScreen = () => {
        if (screens?.length >= max_no_of_screens) {
            toast.error("Maximum Screens limit is over.", {
                closeButton: <Close />
            });
        } else {
            setIsOpen(true);
            setIsEdit(false);
            setIsTrasferScreen(false);
        }
    }
    return (
        <div className='screen-table-container'>
            <div className='screen-header'>
                <div>
                    <p className='screen-text'>Screen / Players</p>
                    <p className='total-screen-text'>Total Screens - {screens?.length} . Last 5 Screens</p>
                </div>
                <div className='screen-header-right-side'>
                    <div class="wrapper-screen">
                        <div class="icon"><SearchIcon color='rgba(135, 142, 171, 1)' /></div>
                        <input onChange={(e) => setSearchText(e.target.value)} className='search-input' placeholder='Search by Name' />
                    </div>
                    <CustomButton onClick={handleNewScreen} disabled={loading} leftIcon={<AddIcon style={{ height: 18, width: 18 }} />} label="New Screen" background="rgba(13, 110, 253, 1)" />
                    <CustomButton className="smallHide" onClick={() => { }} leftIcon={<TuneIcon style={{ height: 18, width: 18 }} />} label="Filters" background="rgba(13, 110, 253, 0.3)" />
                </div>
            </div>
            <CustomTable
                loading={loading}
                columns={columns}
                data={screenData}
                details="No Screen Found!"
                defaultOptions={defaultOptions}
                pageCount={pageCount}
                fetchData={fetchData}
            />
            <ScreenFormDrawer open={isOpen} toggleDrawer={toggleDrawer} initialValues={initialValues} handleSubmit={handleSubmit} isEdit={isEdit} isTransfer={isTrasferScreen} />
            <AlertModal
                open={successOpen}
                handleClose={() => setSuccessOpen(false)}
                title={isSuccess ? "Paired Successfully" : `Oops! \n Pairing code does not match`}
                desc={isSuccess ? "Screen paired successfully, you can now play content in application." : "Please check your pairing code and try again"}
                icon={isSuccess ? successIcon : errorIcon}
                success={isSuccess ? true : false}
            />
        </div>
    )
}

export default ScreenTable