import React, { useState } from 'react'
import { imageRegex } from '../../Utils/Constant';
import deleteIcon from "../../Assets/delete-icon-red.svg"
import Video from '../Common/Video';
import VideoDisplayModal from '../Common/VideoDisplayModal';
import moment from 'moment';

function MediaCard({ mediaFiles, handleDeleteIcon, handleRename }) {
    const [open, setOpen] = useState(false);
    const [mediaData, setMediaData] = useState();

    const handlePlayVideo = (data) => {
        setOpen(true)
        setMediaData(data)
    }
    const handleClose = () => {
        setOpen(false)
        setMediaData()
    }
    return (
        <>
            {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '20px' }}> */}
            {mediaFiles?.length ? mediaFiles?.map((p, i) => (
                <div key={i} className='mediaFileCard'>
                    {imageRegex.test(p?.type) ? <img
                        src={p.url}
                        className="mediaFileCardImg"
                        alt="image not found"
                        onClick={() => handlePlayVideo(p)}
                    /> :
                        <Video url={p?.url} type={p?.type} style={{ height: "120px" }} onClick={() => handlePlayVideo(p)} />
                    }
                    <div>
                        <p className='medialCard-header'>{p?.name?.length > 25
                            ? p.name.slice(0, 25) + "..."
                            : p.name}</p>
                        <p className='medialCard-desc'>{imageRegex.test(p?.type) ? "Image" : "Video"}</p>
                        <p className='medialCard-date'>Date of creation: <span>{p?.timestamp ? moment(p?.timestamp)?.format("MMM DD YYYY") : ""}</span></p>
                    </div>
                    <div className='mediafile-button-container'>
                        <button className='rename-btn' onClick={() => handleRename(p)}>Rename</button>
                        <img src={deleteIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} />
                    </div>
                </div>
            )) : <p className='mediafile-not-found'>No Media Found</p>}
            <VideoDisplayModal open={open} handleClose={handleClose} url={mediaData?.url} type={mediaData?.type} />
            {/* </div> */}
        </>
    )
}

export default MediaCard