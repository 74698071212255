import { Backdrop, Box, Modal } from '@mui/material'
import React, { useState } from 'react'
import Fade from '@mui/material/Fade';
import successImage from "../../Assets/success-back.svg"
import CloseIcon from '@mui/icons-material/Close';
import CustomInputField from '../Common/CustomInputField'
import { Formik } from 'formik';
import { AreaValidation } from '../../Utils/Validation';
import "../../Css/Group.css";

function AddGroupModal({ open, handleClose, handleSubmit }) {

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Formik
                        initialValues={{ area_name: "" }}
                        validationSchema={AreaValidation}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form style={{ height: "100%" }}>
                                {console.log(values)}
                                {/* <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div> */}
                                <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={handleClose} /></div>
                                <div className='add-group-modal-container'>
                                    <p className='modal-title'>{"Create Display"}</p>
                                    <CustomInputField label="Display Name" name="area_name" onChange={handleChange} value={values.area_name} error={touched?.area_name && errors.area_name} style={{ width: "80%" }} />
                                    <button onClick={handleSubmit} disabled={isSubmitting} className='btn12' style={{ padding: "22px 40px", width: "80%" }}>
                                        {isSubmitting ? "Loading..." : "Create Display"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik >
                </Box>
            </Fade>
        </Modal>
    )
}

export default AddGroupModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',  // 0px - 600px
        sm: '650px',
    },
    height: '400px',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px"
};