import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { ResetValidation } from '../../Utils/Validation';
import { equalTo, get, getDatabase, orderByChild, query, ref } from 'firebase/database';
import { Loader } from '../Css';
import { app } from '../../Utils/Firebase';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../Utils/axios';

function EmailSend({ setStep, setSecret, setEmail }) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const db = getDatabase(app);

    const handleSubmit = async (values, { setFieldError, resetForm }) => {
        const data = {
            email: values?.email
        }
        try {
            setLoading(true);
            const userSnapshot = query(ref(db, "users"), orderByChild('email'), equalTo(values?.email));
            get(userSnapshot).then(async (snapshot) => {
                if (snapshot.exists()) {
                    const res = await axiosInstance.post("/api/reset-password", data);
                    if (res?.data?.otp) {
                        setStep(1);
                        setSecret(res?.data?.otp);
                        setEmail(values?.email);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    setFieldError("email", "This email is not registered.")
                }
            }).catch((error) => {
                setLoading(false);
                console.error("Error getting data:", error);
            });
        } catch (error) {
            setLoading(false);
            console.log("error", error)
        }
    };

    return (
        <div className="loginform-container">
            <div className="loginform">
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={ResetValidation}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <h1>Forgot Password</h1>

                            <div className="field">
                                <label>Username</label>
                                <Field
                                    type="text"
                                    name="email"
                                    className="textarea"
                                    value={values?.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <p className="error-message">
                                {touched.email && errors.email}
                            </p>
                            <div className="middle">
                                <div className="right">
                                    <label>
                                        <p className="forgot-password" onClick={() => navigate("/login")}>login?</p>
                                    </label>
                                </div>
                            </div>

                            {loading ? (
                                <button
                                    className="btn12"
                                    onClick={handleSubmit}
                                    style={{ display: "grid" }}
                                    disabled={loading}
                                >
                                    <Loader />
                                </button>
                            ) : (
                                <button className="btn12" onClick={handleSubmit}>
                                    Submit
                                </button>
                            )}
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default EmailSend