import React, { useEffect, useState } from 'react'
import { equalTo, get, getDatabase, onValue, orderByChild, push, query, ref, set, update } from 'firebase/database';
import { app } from '../Utils/Firebase';
import { Button, colors } from '@mui/material';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { AllWidgets, History, Media, WebpageWidgets, YoutubeWidgets, imageRegex, videoRegex } from '../Utils/Constant';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import "../Css/PlaylistSetting.css";
import MediaFiles from '../Components/Playlist/MediaFiles';
import Widget from '../Components/Playlist/Widget';
import Zone from '../Components/Playlist/Zone';
import TvScreen from '../Components/Playlist/TvScreen';
import CustomTabs from '../Components/Common/Tab';
import PlaylistSettingTopbar from '../Components/Playlist/PlaylistSettingTopbar';
import AlertModal from '../Components/Common/AlertModal';
import checkIcon from "../Assets/checkIcon.svg"
import moment from 'moment';

const defaultSettingData = {
    music_mute: "mute",
    image_direction: "ltr",
    image_duration: 5,
    logo: "no",
    font_size: "16",
    color: "black",
    background_color: "white"
}
function PlaylistSetting() {
    const [playlist, setPlaylist] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [zone, setZone] = useState({});
    const [isVertical, setIsVertical] = useState(true);
    const [selectedCollapse, setSelectedCollapse] = useState({});
    const [selectedScreen, setSelectedScreen] = useState({});
    const [firstSelectedPlaylist, setFirstSelectedPlaylist] = useState([]);
    const [isMedia, setIsMedia] = useState(true);
    const [youtubeUrls, setYoutubeUrls] = useState([]);
    const [webpageUrls, setWebpageUrls] = useState([]);
    const [clockUrls, setClockUrls] = useState([]);
    const [weatherUrls, setWeatherUrls] = useState([]);
    const [selectedWidget, setSelectedWidget] = useState("");
    const [playlistName, setPlaylistName] = useState("");
    const [successOpen, setSuccessOpen] = useState(false);
    const [settingData, setSettingData] = useState(defaultSettingData);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [isLogoUploading, setIsLogoUploading] = useState(false);
    const [zoneSetting, setZoneSetting] = useState({});
    const id = localStorage.getItem("id");
    const { id: playlistId } = useParams();
    const db = getDatabase(app);

    const navigate = useNavigate();

    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        // Check if the item was dropped outside a valid droppable
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            if (destination.droppableId === 'tv') {
                const itemToCopy = playlist.find((item) => item.id === draggableId);

                if (itemToCopy) {
                    const copiedItem = { ...itemToCopy, id: uuidv4() };

                    // Add the copied item to the TV playlist
                    setSelectedFiles([...selectedFiles, copiedItem]);
                    setZone({ ...zone, [copiedItem?.id]: [{ ...copiedItem, id: uuidv4() }] });
                }
            }
            if (destination.droppableId === 'zone' && selectedScreen) {
                const itemToCopy = isMedia ? playlist.find((item) => item.id === draggableId) : selectedWidget === "youtube" ? youtubeUrls.find((item) => item.id === draggableId) : webpageUrls.find((item) => item.id === draggableId);
                if (itemToCopy) {
                    const copiedItem = { ...itemToCopy, id: uuidv4() };
                    const key = selectedScreen?.i;
                    if (zone?.[key]) {
                        setZone({ ...zone, [key]: [...zone?.[key], copiedItem] });
                    } else {
                        setZone({ ...zone, [key]: [copiedItem] });
                    }
                }
            }
        } else {
            // If the item was reordered within the same droppable, update the order of items
            const list = source.droppableId === 'libarary' ? playlist : source?.droppableId === 'tv' ? selectedFiles : zone;

            if (source?.droppableId === 'zone') {
                const [reorderedItem] = list?.[selectedScreen?.i].splice(source.index, 1);
                list?.[selectedScreen?.i].splice(destination.index, 0, reorderedItem);
                if (destination?.index === 0) {
                    const index = selectedFiles.indexOf(selectedScreen?.id);

                    const item = [...selectedCollapse?.screen];
                    const updatedArray = findAndReplace(item, selectedScreen, reorderedItem);
                    setSelectedCollapse({ ...selectedCollapse, screen: updatedArray });
                    setSelectedScreen({ ...selectedScreen, ...reorderedItem })
                    // }
                }
            } else if (source.index !== destination.index) {
                const [reorderedItem] = list.splice(source.index, 1);
                list.splice(destination.index, 0, reorderedItem);
            }

            // Update your state
            if (source.droppableId === 'libarary') {
                setPlaylist([...list]);
            } else if (source.droppableId === 'tv') {
                setSelectedFiles([...list]);
            } else if (source?.droppableId === 'zone') {
                setZone({ ...list, });
            }
        }
    };

    const getPlaylistData = async () => {
        try {
            const playlistQuery = query(ref(db, Media), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(playlistQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key
                    }));
                    setPlaylist(itemsArray);
                } else {
                    console.log('No data found');
                }
            });
            const playlistSnapshot = await get(ref(db, `Playlist/${playlistId}`));
            if (playlistSnapshot.exists()) {
                const PlaylistData = playlistSnapshot.val();
                setPlaylistName(PlaylistData?.name);
                if (PlaylistData?.data) {
                    // setSelectedFiles(PlaylistData?.data?.tvFiles);
                    const isVertical = PlaylistData?.data?.layout === 'vertical' ? true : false;
                    setIsVertical(isVertical);
                    setSelectedCollapse(PlaylistData?.data?.collage);
                    setZone(PlaylistData?.data?.zone || []);
                    if (PlaylistData?.data?.settingData?.music_mute) {
                        setSettingData({ ...settingData, ...PlaylistData?.data?.settingData });
                    }
                    if (PlaylistData?.data?.zoneSetting) {
                        setZoneSetting({ ...zoneSetting, ...PlaylistData?.data?.zoneSetting });
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getYoutubeData = async () => {
        try {
            const playlistQuery = query(ref(db, YoutubeWidgets), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(playlistQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key
                    }));
                    setYoutubeUrls(itemsArray);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getWebpageData = async () => {
        try {
            const webpageQuery = query(ref(db, WebpageWidgets), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(webpageQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key
                    }));
                    setWebpageUrls(itemsArray);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const getAllWidgetData = async () => {
        try {
            const webpageQuery = query(ref(db, AllWidgets), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(webpageQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => ({
                        ...value,
                        id: key,
                        _id: key
                    }));

                    const clockUrls = itemsArray?.filter(item => item?.type === "clock");
                    const weatherUrls = itemsArray?.filter(item => item?.type === "weather");
                    console.log('itemsArray: =-=-', itemsArray);
                    setClockUrls(clockUrls);
                    setWeatherUrls(weatherUrls);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPlaylistData();
        getYoutubeData();
        getWebpageData();
        getAllWidgetData();
    }, [playlistId])


    const handleUpdatePlaylist = async () => {
        try {
            const updates = {};
            const playlistSnapshot = await get(ref(db, `Playlist/${playlistId}`));
            if (playlistSnapshot.exists()) {
                const PlaylistData = playlistSnapshot.val();
                updates['/Playlist/' + playlistId] = {
                    ...PlaylistData,
                    data: {
                        collage: selectedCollapse, zone: zone ? zone : [], layout: isVertical ? 'vertical' : 'horizontal', layoutIndex: selectedCollapse?.index,
                        settingData: {
                            ...settingData
                        },
                        zoneSetting
                    }
                }
            }
            const updateDataaa = await update(ref(db), updates);
            const history = {
                user_id: id,
                title: "Playlist",
                status: `${playlistName} playlist updated successfully.`,
                timestamp: moment(new Date()).toISOString()
            }
            const historyRef = ref(db, History);
            const newHistoryRef = push(historyRef);
            set(newHistoryRef, history);
            setSuccessOpen(true);
            setTimeout(() => {
                setSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log(error);
        }
    }

    function findAndReplace(obj, target, replacement) {
        for (let key in obj) {
            if (typeof obj[key] === "object") {
                if (JSON.stringify(obj[key]) === JSON.stringify(target)) {
                    obj[key] = { ...obj[key], ...replacement }; // Replace the object
                    const copiedItem = { ...replacement, id: uuidv4() };
                    const keyId = obj[key]?.i;
                    if (zone?.[keyId]) {
                        setZone({ ...zone, [keyId]: [...zone?.[keyId], copiedItem] });
                    } else {
                        setZone({ ...zone, [keyId]: [copiedItem] });
                    }
                } else {
                    findAndReplace(obj[key], target, replacement); // Recurse into nested objects
                }
            }
        }
        return obj;
    }

    const handleScreenPlaylist = async (selectedplaylist) => {
        try {
            if (selectedScreen && Object.keys(selectedScreen)?.length) {
                const item = [...selectedCollapse?.screen];
                // const keyId = selectedScreen?.i;
                // const copiedItem = { ...selectedplaylist, id: uuidv4() };
                // if (zone?.[keyId]) {
                //     setZone({ ...zone, [keyId]: [...zone?.[keyId], copiedItem] });
                // } else {
                //     setZone({ ...zone, [keyId]: [copiedItem] });
                // }
                // const updatedArray = findAndReplace(item, selectedScreen, selectedplaylist);
                // setSelectedCollapse({ ...selectedCollapse, screen: updatedArray });
                setSelectedScreen({ ...selectedScreen });
                // Check if the item is already selected
                const isSelected = firstSelectedPlaylist.filter(item => item?.url === selectedplaylist?.url);

                if (isSelected?.length) {
                    // If selected, remove it from the selectedItems array
                    setFirstSelectedPlaylist((prevSelectedItems) =>
                        prevSelectedItems.filter((selectedItem) => selectedItem?.url !== selectedplaylist?.url)
                    );
                } else {
                    const copiedItem = { ...selectedplaylist, id: uuidv4() };
                    // If not selected, add it to the selectedItems array
                    setFirstSelectedPlaylist((prevSelectedItems) => [...prevSelectedItems, copiedItem]);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSetCollapse = (item) => {
        setZone({});
        setSelectedScreen({});
        setSelectedCollapse(item);
    }

    const handleChange = (e) => {
        const obj = [...selectedCollapse?.screen];
        for (let key in obj) {
            if (JSON.stringify(obj[key]?.i) === JSON.stringify(selectedScreen?.i)) {
                obj[key] = { ...obj[key], text: e?.target?.value }; // Replace the object
                const keyId = obj[key]?.i;
                setZone({ ...zone, [keyId]: [{ text: e?.target?.value }] });
            }
        }
        setSelectedCollapse({ ...selectedCollapse, screen: obj });
        setSelectedScreen({ ...selectedScreen, text: e?.target?.value })
    }

    const handleSelectScreen = (item) => {
        setSelectedScreen(item);
        const currentScreen = item.i;
        if (!zoneSetting?.[currentScreen] && !item?.scrollTop && !item?.scrollBottom) {
            setZoneSetting(prev => ({
                ...prev,
                [currentScreen]: {
                    image_direction: "ltr",
                    image_duration: "5"
                }
            }));
        } else if (!zoneSetting?.[currentScreen]) {
            setZoneSetting(prev => ({
                ...prev,
                [currentScreen]: {
                    fontFamily: "Nunito",
                    background_color: "#ffffff",
                    font_size: "16",
                    color: "#000000"
                }
            }));
        }
    }

    function findAndChangePosition(obj, target, replacement) {
        for (let key in obj) {
            if (typeof obj[key] === "object") {
                if (obj[key]?.url === target?.url) {
                    // if (Object.keys(replacement).length) {
                    //     console.log("iffffffff", replacement)

                    obj[key] = { ...obj[key], ...replacement }; // Replace the object
                    // } else {
                    //     console.log("elese", replacement)

                    //     obj[key] = { ...replacement }; // Replace the object
                    // }
                    const copiedItem = { ...replacement, id: uuidv4() };
                    const keyId = obj[key]?.i;
                    if (zone?.[keyId]) {
                        setZone({ ...zone, [keyId]: [...zone?.[keyId], copiedItem] });
                    } else {
                        setZone({ ...zone, [keyId]: [copiedItem] });
                    }
                } else {
                    findAndChangePosition(obj[key], target, replacement); // Recurse into nested objects
                }
            }
        }
        return obj;
    }

    const handleClose = (item) => {
        const selectedZone = zone?.[selectedScreen?.i];
        const filteredValue = selectedZone.filter((selectedItem) => selectedItem !== item);
        if (selectedZone[0] === item) {
            const screens = [...selectedCollapse?.screen];
            const updatedArray = findAndChangePosition(screens, item, selectedZone[1] || { url: "", name: "", id: "", type: "" });
            setSelectedCollapse({ ...selectedCollapse, screen: updatedArray });
        }
        zone[selectedScreen.i] = filteredValue;
        const arrayOfValues = Object.values(zone);

        setZone([...arrayOfValues]);

    }

    const handleUploadFile = () => {
        const selectedZone = zone?.[selectedScreen?.i] || [];
        zone[selectedScreen.i] = [...selectedZone, ...firstSelectedPlaylist];
        const arrayOfValues = Object.values(zone);
        setZone({ ...zone });
        setFirstSelectedPlaylist([]);
    }

    const handleDeselectFile = () => {
        setFirstSelectedPlaylist([]);
    }

    const tabsItems = [
        {
            title: "All",
            component: <MediaFiles playlist={playlist} handleScreenPlaylist={handleScreenPlaylist} selectedFiles={firstSelectedPlaylist} />
        },
        {
            title: "Images",
            component: <MediaFiles playlist={playlist.filter(item => imageRegex.test(item?.type))} handleScreenPlaylist={handleScreenPlaylist} selectedFiles={firstSelectedPlaylist} />
        },
        {
            title: "Videos",
            component: <MediaFiles playlist={playlist.filter(item => videoRegex.test(item?.type))} handleScreenPlaylist={handleScreenPlaylist} selectedFiles={firstSelectedPlaylist} />
        },
    ]

    return (
        <div>
            <PlaylistSettingTopbar name={playlistName} setIsVertical={setIsVertical} navigate={navigate} isVertical={isVertical} handleUpdatePlaylist={handleUpdatePlaylist}
                isFileUploading={isFileUploading}
                isLogoUploading={isLogoUploading} />
            <div className="parentDiv">
                <DragDropContext
                    onDragEnd={(result) => onDragEnd(result)}
                >
                    <div className='playlistDiv'>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                height: "calc(100% - 120px)",
                                overflowY: "auto",
                                overflowX: "hidden"
                            }}
                            key="libarary"
                        >
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: "15px", marginTop: '10px' }}>
                                <button className={!isMedia ? "blue-outlined-btn" : "blue-contained-btn"} onClick={() => setIsMedia(true)} sx={{ border: isMedia ? '2px solid black' : "", width: "110px", height: "35px", borderRadius: '10px', margin: '10px' }}>
                                    Media
                                </button>
                                <button className={isMedia ? "blue-outlined-btn" : "blue-contained-btn"} onClick={() => setIsMedia(false)} sx={{ border: !isMedia ? '2px solid black' : "", width: "110px", height: "35px", borderRadius: '10px', margin: '10px' }}>
                                    Widget
                                </button>
                            </div>
                            {isMedia ? <CustomTabs tabItems={tabsItems} tabStyle={{ width: "100%" }} tabsSx={{ '& .MuiTabs-flexContainer': { justifyContent: 'center' } }} /> : <Widget youtubeUrls={youtubeUrls} webpageUrls={webpageUrls} clockUrls={clockUrls} weatherUrls={weatherUrls} selectedFiles={firstSelectedPlaylist} handleScreenPlaylist={handleScreenPlaylist} selectedWidget={selectedWidget} setSelectedWidget={setSelectedWidget} />}
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: "15px", padding: '10px 20px', boxSizing: "border-box" }}>
                            <button className={"select-file-btn"} onClick={handleUploadFile}>
                                Upload Files
                            </button>
                            <button className={"blue-outlined-btn"} onClick={handleDeselectFile} style={{ padding: '10px 40px' }}>
                                Deselect
                            </button>
                        </div>
                    </div>
                    <div className='TvscreenDiv'>
                        <TvScreen
                            setIsVertical={setIsVertical} zone={zone} isVertical={isVertical} selectedCollapse={selectedCollapse} handleSelectScreen={handleSelectScreen} handleSetCollapse={handleSetCollapse} selectedScreen={selectedScreen}
                            settingData={settingData}
                            zoneSetting={zoneSetting}
                        />
                    </div >
                    <div className='zoneDiv'>
                        <Zone
                            zone={zone} selectedScreen={selectedScreen} handleClose={handleClose} handleChange={handleChange}
                            settingData={settingData}
                            setSettingData={setSettingData}
                            isFileUploading={isFileUploading}
                            setIsFileUploading={setIsFileUploading}
                            isLogoUploading={isLogoUploading}
                            setIsLogoUploading={setIsLogoUploading}
                            setZoneSetting={setZoneSetting}
                            zoneSetting={zoneSetting}
                            setZone={setZone}
                        />
                    </div>
                </DragDropContext >
            </div >
            <AlertModal
                open={successOpen}
                handleClose={() => setSuccessOpen(false)}
                title={`Playlist Created`}
                desc={`${playlistName} has been created successfully`}
                icon={checkIcon}
                success={true}
            />
        </div>
    )
}

export default PlaylistSetting
