import { Box, Checkbox, Modal, Typography } from '@mui/material';
import { Formik } from 'formik';
import React from 'react'
import { imageRegex, videoRegex } from '../../Utils/Constant';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import youtubeImage from "../../Assets/youtube.png";
import webpageImage from "../../Assets/webpage.png";
import saveIconImage from "../../Assets/saveIcon.svg";

function AddPlaylistToAreaModal({ playlist, formOpen, setFormOpen, handleSubmit, selectedPlaylist, handleCheckbox }) {

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
        <Modal
            open={formOpen}
            onClose={() => (setFormOpen(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => (setFormOpen(false))} />
                </Box>
                <p className="select-playlist-text">Select Playlist</p>
                <Formik
                    initialValues={{ area_name: "" }}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form style={{ height: "calc(80vh - 152px)", }}>
                            {console.log(values)}
                            <Box sx={{ height: '100%', width: '100%' }}>
                                <div className="playlist-group-container">
                                    {playlist?.length ? playlist?.map((p, i) => {
                                        const url = p?.data?.zone?.[0]?.[0]?.url || p?.data?.zone?.[1]?.[0]?.url;
                                        const type = p?.data?.zone?.[0]?.[0]?.type || p?.data?.zone?.[1]?.[0]?.type;
                                        return (<div key={i} className='playlist-group-card'>
                                            {imageRegex.test(type) ? <img
                                                src={url}
                                                className="card-img-top"
                                                // height={0}
                                                // width={250}
                                                alt="image not found"
                                                style={{ width: '100%', height: '125px', borderRadius: '6px' }}
                                            /> : videoRegex.test(type) ? <video controls style={{ width: '100%', height: '125px' }}>
                                                <source src={url} type={type} />
                                            </video> : (type) === 'webpage' ? <img src={webpageImage} alt='webpageImage' style={{ width: '100%', height: '70%', marginTop: "10px" }} /> : (type) === 'stream' ? <img src={youtubeImage} alt='youtube' style={{ width: '100%', height: '70%', marginTop: "10px" }}
                                            /> : <img src={saveIconImage} alt='webpageImage' style={{ width: '100%', height: '70%', marginTop: "10px" }} />}

                                            <div style={{ textAlign: 'center' }}>
                                                <h4 className="playlist-group-card-title">{p?.name?.length > 20
                                                    ? p.name.slice(0, 20) + "..."
                                                    : p.name}</h4>
                                                <Checkbox className='playlist-checkbox' {...label} icon={<RadioButtonUncheckedIcon />} defaultChecked={selectedPlaylist?.some(item => item?.id === p?.id) ? true : false} checkedIcon={<CheckCircleIcon style={{ fill: '#3DD24C' }} />} onChange={(e) => handleCheckbox(e, p)} />
                                            </div>
                                        </div>)
                                    }) : <p>No Playlist found</p>}
                                </div>
                                <button type="submit" onClick={handleSubmit} variant="contained" className='btn12' style={{ padding: "22px 40px", width: "100%" }}>
                                    {isSubmitting ? "Loading..." : "Add Playlist"}
                                </button>
                            </Box>
                        </form>
                    )}
                </Formik >
            </Box>
        </Modal>
    )
}

export default AddPlaylistToAreaModal;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '400px',
    width: {
        xs: '90vw',  // 0px - 600px
        sm: '70vw',
    },
    height: '80vh',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px",
    padding: "30px",
    overflowY: "auto"
};