import { FormHelperText, InputLabel, TextField } from '@mui/material';
import { Field } from 'formik';
import React from 'react'

function InputField({ label, name, error, ErrorMessage, onChange, sx, ...params }) {
    return (
        <div style={{ width: "min-content" }}>
            <InputLabel sx={inputstyle}>{label}</InputLabel>
            <Field
                name={name}
                as={TextField}
                // label={label}
                variant="outlined"
                placeholder={label}
                sx={{ ...textFieldStyle, ...sx }}
                error={error}
                // helperText={error}
                FormHelperTextProps={{
                    sx: {
                        position: "absolute",
                        bottom: "-20px"
                    },
                }}
                onChange={onChange}
                {...params}
            />
            {error && <FormHelperText sx={{ color: "#d32f2f", width: "90%" }}>
                {error}
            </FormHelperText>}
        </div>
    )
}

export default InputField

const inputstyle = {
    color: "black",
    fontSize: "16px",
    padding: "2px 0",
    // marginBottom: "5px",
    marginTop: "10px",
    width: "90%"
};

const textFieldStyle = {
    width: { xs: 300, sm: 300, md: 270, lg: 500, xl: 500 },
    height: "40px",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "20px",
    },
    "& .MuiInputBase-root ": {
        width: { xs: 300, sm: 300, md: 270, lg: 500, xl: 500 },
        height: "40px",
    },
    "& .MuiFormControl-root": {
        padding: "0px 10px",
        height: "40px",
    },
};
