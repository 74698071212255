import { Button } from '@mui/material'
import React from 'react'

function CustomButton({ onClick, label, rightIcon, leftIcon, background, ...parmas }) {
    return (
        <Button variant="contained" onClick={onClick} sx={{
            width: "auto", background: background ? background : 'rgba(13, 110, 253, 1)', borderRadius: "30px", height: "40px", color: 'white', fontFamily: "Montserrat", ":hover": {
                background: background ? background : 'rgba(13, 110, 253, 1)',
                opacity: 0.8
            },
            fontSize: {
                xs: "10px",
                sm: "14px"
            },
            ":disabled": {
                background: background ? background : 'rgba(13, 110, 253, 1)',
                color: "white",
                opacity: 0.6,
                cursor: "not-allowed"
            }
        }} {...parmas}>
            {leftIcon}&nbsp;{label}&nbsp;{rightIcon}
        </Button>
    )
}

export default CustomButton