import React, { useEffect, useRef } from 'react'
import Layout from '../Components/Layout/Layout'
import { Box, Button, Modal, Typography } from '@mui/material'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FileUploader from '../Components/FileUploader';
import { app, storage, uploadBlobToFirebaseStorage, uploadFiles } from '../Utils/Firebase';
import { equalTo, getDatabase, onValue, orderByChild, push, query, ref, remove, set, update } from 'firebase/database';
import { ref as sRef } from 'firebase/storage';
import { toast } from 'react-toastify';
import Close from '@mui/icons-material/Close';
import { Folder, History, Media, Playlist, videoRegex } from '../Utils/Constant';
import AlertDialog from '../Components/Common/AlertDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteObject } from 'firebase/storage';
import { bytesToMB, checkAvailableStorage, fetchFolderSize } from '../Utils/utils';
import { useAuth } from '../Context/auth';
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";
import CustomButton from '../Components/Common/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import "../Css/Mediafiles.css";
import CustomTabs from '../Components/Common/Tab';
import MediaCard from '../Components/Mediafiles/MediaCard';
import ConfirmationModal from '../Components/Common/ConfirmationModal';
import UploadFileModal from '../Components/Common/UploadFileModal';
import RenameFileNameModal from '../Components/Mediafiles/RenameFileNameModal';
import AlertModal from '../Components/Common/AlertModal';
import checkIcon from "../Assets/checkIcon.svg"
import moment from 'moment';
import CreateFolderModal from '../Components/Mediafiles/CreateFolderModal';
import FolderCard from '../Components/Mediafiles/FolderCard';

function LibraryFolder() {
    const [formOpen, setFormOpen] = useState(false);
    const [folder, setFolderfiles] = useState([]);
    console.log('folder: ', folder);
    const [searchText, setSearchText] = useState("");
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);
    const id = localStorage.getItem("id");
    const db = getDatabase(app);
    const { user } = useAuth();

    useEffect(() => {
        try {
            setIsLoading(true);
            const playlistQuery = query(ref(db, Folder), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(playlistQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const itemsArray = Object.entries(data).map(([key, value]) => {
                        return {
                            ...value,
                            id: key
                        }
                    });
                    setFolderfiles(itemsArray);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log("error", error);
        } finally {
            setIsLoading(false);
        }
    }, [])

    let filteredData = [];

    if (searchText === "") {
        filteredData = folder;
    } else if (!folder) {
        filteredData = [];
    } else {
        filteredData = folder?.filter((item) => {
            const name = item?.name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
    }

    const handleClose = () => {
        setFormOpen(false);
    }

    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div className='breadcrumbs'>
                                <p>{`Home >`}</p>
                                <span>&nbsp; Library</span>
                            </div>
                        </div>
                        <div className='button-container'>
                            <CustomButton leftIcon={<AddIcon />} onClick={() => setFormOpen(true)} label="Create Folder" background="rgba(49, 0, 255, 1)" />
                        </div>
                        <div className={`overlay ${isOpen ? 'open' : ''}`}>
                            <button className='add-button closebtn' onClick={closeNav}><CloseIcon /></button>
                            <div className="overlay-content">
                                <CustomButton leftIcon={<AddIcon />} onClick={() => setFormOpen(true)} label="Create Folder" background="rgba(0, 0, 0, 1)" />
                            </div>
                        </div>
                        <button className='add-button' onClick={openNav}><AddIcon /></button>
                    </div>
                    <div className='file-header'>
                        <div>
                            <p className='file-text'>Folder</p>
                            <p className='total-file-text'>Total folder - {folder?.length}</p>
                        </div>
                        <div style={{ display: 'flex', gap: "10px" }}>
                            <div class="wrapper">
                                <div class="icon"><SearchIcon color='rgba(135, 142, 171, 1)' /></div>
                                <input onChange={(e) => setSearchText(e.target.value)} className='search-input' placeholder='Search by Name' />
                            </div>
                            <CustomButton onClick={() => { }} leftIcon={<TuneIcon style={{ height: 18, width: 18 }} />} label="Filters" background="rgba(13, 110, 253, 0.3)" />
                        </div>
                    </div>
                    <FolderCard folder={folder} />
                </div>
            </div>
            <AlertModal
                open={deleteSuccessOpen}
                handleClose={() => setDeleteSuccessOpen(false)}
                title={`Deleted Successfully`}
                desc={"The file is removed from all the assigned slots"}
                icon={checkIcon}
                success={true}
            />
            <CreateFolderModal open={formOpen} handleClose={handleClose} />
        </Layout>
    )
}

export default LibraryFolder

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '50vh',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
};