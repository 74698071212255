import React from 'react'
import Layout from '../Components/Layout/Layout'
import ActivityLogs from '../Components/Dashboard/ActivityLogs'

function ActivityLogsPage() {
    return (
        <Layout>
            <ActivityLogs />
        </Layout>
    )
}

export default ActivityLogsPage