import { Link, useLocation, useNavigate } from "react-router-dom";

//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from "react-pro-sidebar";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "../../Css/Sidebar.css";
import { useAuth } from "../../Context/auth";
import homeIcon from '../../Assets/sidebar/homeIcon.svg'
import screen from '../../Assets/sidebar/screen.svg'
import log from '../../Assets/sidebar/log.svg'
import profile from '../../Assets/sidebar/profile.svg'
import library from '../../Assets/sidebar/library.svg'
import lock from '../../Assets/sidebar/lock.svg'
import playlist from '../../Assets/sidebar/playlist.svg'
import logout from '../../Assets/sidebar/logout.svg'
import group from '../../Assets/sidebar/group.svg'
import profileIcn from '../../Assets/sidebar/profileIcn.svg'
import LogoutModal from "../Common/LogoutModal";
import { useMediaQuery } from "react-responsive";
import saveIconImage from "../../Assets/saveIcon.svg";

const styles = {
    sideBarHeight: {
        height: "145vh"
    },
    menuIcon: {
        float: "right",
        margin: "10px"
    }
};

const SideBar = ({ showSidebar }) => {
    const [open, setOpen] = useState(false);
    const { user } = useAuth();
    const location = useLocation();
    console.log("userrrr", user)
    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery({ query: '(min-width: 720px)' });

    const handleLogout = () => {
        window.location.href = "/login"
        localStorage.clear();
    }

    const isPathActive = (path, currentPath) => {
        const pathSegments = path.split('/');
        const currentPathSegments = currentPath.split('/');

        if (pathSegments.length !== currentPathSegments.length) {
            return false;
        }

        return pathSegments.every((segment, i) => segment.startsWith(':') || segment === currentPathSegments[i]);
    };

    const areaTab = ["/area-group", "/area-group/:id", "/area-group/:id/group/:id"];

    const isActive = areaTab.some(path => isPathActive(path, location.pathname));



    return (
        <>
            <div id="header" style={{ width: isLargeScreen ? '15vw' : showSidebar ? "0px" : "50vw", zIndex: isLargeScreen ? 0 : 1 }}>
                <ProSidebar>
                    <SidebarHeader>
                        <div className="logotext">
                            <img
                                src={user?.image || saveIconImage}
                                alt="Logo"
                            />
                        </div>
                    </SidebarHeader>
                    <SidebarContent style={{ overflow: 'auto' }}>
                        <Menu iconShape="square">
                            <MenuItem icon={<img src={homeIcon} />} active={location.pathname === '/dashboard'}>
                                <Link to="/dashboard">Dashboard</Link>
                            </MenuItem>
                            <MenuItem icon={<img src={screen} />} active={location.pathname === '/screens'}>
                                <Link to="/screens">Screens</Link>
                            </MenuItem>
                            <MenuItem icon={<img src={library} />} active={location.pathname === '/library'}>
                                <Link to="/library">Library</Link>
                            </MenuItem>
                            <MenuItem icon={<img src={playlist} />} active={location.pathname === '/playlist'}>
                                <Link to="/playlist">Playlist</Link>
                            </MenuItem>
                            <MenuItem icon={<img src={group} />} active={isActive}>
                                <Link to="/area-group">Area</Link>
                            </MenuItem>
                            <MenuItem icon={<img src={log} />} active={location.pathname === '/history'}>
                                <Link to="/history">Logs</Link>
                            </MenuItem>
                            <MenuItem icon={<img src={profile} />} active={location.pathname === '/setting'}>
                                <Link to="/setting">Profile</Link>
                            </MenuItem>
                            <MenuItem icon={<img src={logout} />} onClick={() => setOpen(true)}>
                                <Link style={{ color: "red" }}>Logout</Link>
                            </MenuItem>
                            {/* <MenuItem icon={<BiCog />}>Settings</MenuItem> */}
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <Menu iconShape="square" onClick={() => navigate('/setting')}>
                            <MenuItem icon={<img src={profileIcn} />}>{user?.email}</MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>
                <LogoutModal open={open} handleClose={() => setOpen(false)} handleLogout={handleLogout} />
            </div>
        </>
    );
};

export default SideBar;
