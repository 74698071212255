import { Backdrop, Box, Modal } from '@mui/material'
import React from 'react'
import Fade from '@mui/material/Fade';
import successImage from "../../Assets/success-back.svg"
import errorImage from "../../Assets/error-back.svg"
import CloseIcon from '@mui/icons-material/Close';

function AlertModal({ open, handleClose, title, desc, icon, success }) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div style={{ position: 'absolute', zIndex: -1 }}><img src={success ? successImage : errorImage} height={"400px"} /></div>
                    <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={handleClose} /></div>
                    <div className='modal-container'>
                        <img src={icon} alt="icons" />
                        <p className='modal-title'>{title.split('\n').map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}</p>
                        <p className='modal-desc'>{desc}</p>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default AlertModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        sm: '650px',
    },
    height: '400px',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px"
};