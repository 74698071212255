import React from 'react'
import VerticalCollapse from './VerticalCollapse';
import HorizontalCollapse from './HorizontalCollapse';
import SingleCollapse from '../Common/SingleCollapse';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TvScreen({ setIsVertical, isVertical, selectedCollapse, handleSelectScreen, handleSetCollapse, selectedScreen, zone, settingData, zoneSetting }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "50px",
                height: "calc(100% - 50px)",
                overflow: "auto"
            }}
            key="tv"
        >
            <div style={{ height: isVertical ? "420px" : "420px", width: isVertical ? "380px" : "660px" }}>
                <div className='tv-screen-container' style={{ height: isVertical ? "420px" : "420px", width: isVertical ? "380px" : "660px" }}>
                    {!isVertical ? selectedCollapse?.screen?.map((item) => {
                        const isBorder = Number(selectedScreen?.i) === Number(item?.i);
                        return (
                            item?.scrollTop ? <div style={{ boxSizing: "border-box", height: `${item?.scrollTop}%`, border: isBorder ? '3px solid rgb(0 91 225)' : "", backgroundColor: zoneSetting?.[item?.i]?.background_color, boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)", display: "flex", alignItems: 'center' }} onClick={() => handleSelectScreen(item)}>
                                <marquee style={{ fontFamily: zoneSetting?.[selectedScreen.i]?.fontFamily, fontSize: `${zoneSetting?.[selectedScreen.i]?.font_size}px`, color: zoneSetting?.[selectedScreen.i]?.color }}>{item?.text ? item?.text : <div className='select-section-box'><p>Select section to enter text</p></div>}</marquee>
                            </div> :
                                item?.scrollBottom ? <div style={{ boxSizing: "border-box", height: `${item?.scrollBottom}%`, border: isBorder ? '3px solid rgb(0 91 225)' : "", backgroundColor: zoneSetting?.[item?.i]?.background_color, boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)", display: "flex", alignItems: 'center' }} onClick={() => handleSelectScreen(item)}>
                                    <marquee style={{ fontFamily: zoneSetting?.[selectedScreen.i]?.fontFamily, fontSize: `${zoneSetting?.[selectedScreen.i]?.font_size}px`, color: zoneSetting?.[selectedScreen.i]?.color }}>{item?.text ? item?.text : <div className='select-section-box'><p>Select section to enter text</p></div>}</marquee>
                                </div> :
                                    item?.direction ?
                                        <div style={{ display: 'flex', flexDirection: item?.direction === 'vertical' ? 'column' : 'row', height: `${item?.height}%` }}>
                                            {item?.smallScreen?.map((sc) => (
                                                <div onClick={() => handleSelectScreen(sc)} style={{ boxSizing: "border-box", width: `${sc?.width || 100}%`, height: `${sc?.height || 100}%`, color: 'black', border: selectedScreen?.i === sc?.i ? '3px solid rgb(0 91 225)' : "", boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)" }}>
                                                    <SingleCollapse item={sc} zone={zone} />
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div onClick={() => handleSelectScreen(item)} style={{ boxSizing: "border-box", width: `${item?.width || 100}%`, height: `${item?.height || 100}%`, color: 'black', border: isBorder ? '3px solid rgb(0 91 225)' : "", boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)" }}>
                                            <SingleCollapse item={item} zone={zone} />
                                        </div>
                        )
                    }) : selectedCollapse?.screen?.map((item) => {
                        const isBorder = Number(selectedScreen?.i) === Number(item?.i);
                        return (
                            item?.scrollTop ? <div style={{ boxSizing: "border-box", height: `${item?.scrollTop}%`, border: isBorder ? '3px solid rgb(0 91 225)' : '', backgroundColor: zoneSetting?.[item?.i]?.background_color, boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)", display: "flex", alignItems: 'center' }} onClick={() => handleSelectScreen(item)}>
                                <marquee style={{ fontFamily: zoneSetting?.[item?.i]?.fontFamily, fontSize: `${zoneSetting?.[item?.i]?.font_size}px`, color: zoneSetting?.[item?.i]?.color }}>{item?.text ? item?.text : <div className='select-section-box'><p>Select section to enter text</p></div>}</marquee>
                            </div> :
                                item?.scrollBottom ? <div style={{ boxSizing: "border-box", height: `${item?.scrollBottom}%`, border: isBorder ? '3px solid rgb(0 91 225)' : '', backgroundColor: zoneSetting?.[item?.i]?.background_color, boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)", display: "flex", alignItems: 'center' }} onClick={() => handleSelectScreen(item)}>
                                    <marquee style={{ fontFamily: zoneSetting?.[item?.i]?.fontFamily, fontSize: `${zoneSetting?.[item?.i]?.font_size}px`, color: zoneSetting?.[item?.i]?.color }}>{item?.text ? item?.text : <div className='select-section-box'><p>Select section to enter text</p></div>}</marquee>
                                </div> :
                                    item?.direction ?
                                        <div style={{ display: 'flex', flexDirection: item?.direction === 'vertical' ? 'column' : 'row', height: `${item?.height || 100}%`, width: `${item?.width || 100}%` }}>
                                            {item?.smallScreen?.map((sc) => (
                                                <div onClick={() => handleSelectScreen(sc)} style={{ boxSizing: "border-box", width: `${sc?.width || 100}%`, height: `${sc?.height || 100}%`, color: 'black', border: selectedScreen?.i === sc?.i ? '3px solid rgb(0 91 225)' : '', boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)" }}>
                                                    <SingleCollapse item={sc} zone={zone} />
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div onClick={() => handleSelectScreen(item)} style={{ boxSizing: "border-box", width: `${item?.width || 100}%`, height: `${item?.height || 100}%`, color: 'black', border: isBorder ? '3px solid rgb(0 91 225)' : "", boxShadow: "-4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1)" }}>
                                            <SingleCollapse item={item} zone={zone} />
                                        </div>
                        )
                    })}
                </div>
            </div>
            <div className="slider-container" style={{ width: '90%', display: 'block', marginTop: "30px" }}>
                {isVertical ? <VerticalCollapse selectedCollapse={selectedCollapse} handleSetCollapse={handleSetCollapse} /> :
                    <HorizontalCollapse selectedCollapse={selectedCollapse} handleSetCollapse={handleSetCollapse} />}
            </div>
        </div>
    )
}

export default TvScreen