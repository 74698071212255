import { Backdrop, Box, Modal } from '@mui/material'
import React from 'react'
import Fade from '@mui/material/Fade';
import successImage from "../../Assets/success-back.svg"
import CloseIcon from '@mui/icons-material/Close';

function LogoutModal({ open, handleClose, handleLogout }) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div style={{ position: 'absolute', zIndex: -1 }}><img src={successImage} height={"400px"} /></div>
                    <div style={{ position: 'absolute', zIndex: 1, right: "20px", top: "20px" }}><CloseIcon onClick={handleClose} /></div>
                    <div className='modal-container'>
                        <p className='modal-title'>{"Are you sure?"}</p>
                        <p className='modal-desc'>{"Are you sure that you want to log out"}</p>
                        <button onClick={handleLogout} className='btn12' style={{ padding: "22px 40px" }}>
                            Yes, Log Out
                        </button>
                        <button onClick={handleClose} className='remove-button' style={{ border: 'none' }}>
                            No, Cancel
                        </button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default LogoutModal

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '650px',
    height: '400px',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px"
};