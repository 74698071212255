// DoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const MediaChart = ({ storageSize }) => {
    const currentStoragePercentage = (storageSize?.currentStorage / storageSize?.totalStorageInMP) * 100;
    const remainingStoragePercentage = 100 - currentStoragePercentage;
    const data = {
        labels: ['Used', 'Unused'],
        datasets: [
            {
                label: '',
                data: [currentStoragePercentage, remainingStoragePercentage],
                backgroundColor: [
                    'rgba(49, 0, 255, 1)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(49, 0, 255, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
                borderRadius: 10,
                spacing: 3,
                width: 10

            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%', // Adjust the cutout value to make the segments thinner or thicker
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Storage Usage (%)',
            },
        },
        elements: {
            arc: {
                borderWidth: 2,
                borderRadius: 10,
                spacing: 2,
            },
        },
    };

    return (
        <div className='media-chart-container'>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default MediaChart;
