import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { app } from "../Utils/Firebase.js";
import { equalTo, get, getDatabase, orderByChild, query, ref } from "firebase/database";
import { CMSRoute } from "../Utils/Constant.js";
// import Spinner from "../layout/Spinner";

export default function PrivateRoute({ children }) {
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();
    const id = localStorage.getItem("id");
    const email = localStorage.getItem("email");
    const db = getDatabase(app);
    useEffect(() => {
        const mostViewedPosts = query(ref(db, 'users'), orderByChild('email'), equalTo(email));
        get(mostViewedPosts).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                const userKey = Object.keys(data)[0];
                const user = data[userKey];

                if (userKey === id) {
                    setIsAuth(true);
                } else {
                    navigate("/login");
                    setIsAuth(false);
                }
            } else {
                if (!CMSRoute.includes(window.location.pathname)) {
                    navigate("/login");
                }
                setIsAuth(false);
                console.log('No data found');
            }
        }).catch((error) => {
            console.error("Error getting data:", error);
        });
    }, [email]);

    return isAuth ? children : "";
}
