import React, { useEffect } from 'react'
import Layout from '../Components/Layout/Layout'
import { Box, Button, Modal, Typography } from '@mui/material'
import { useState } from 'react';
import { app, uploadFiles } from '../Utils/Firebase';
import { equalTo, get, getDatabase, onValue, orderByChild, push, query, ref, remove, set, update } from 'firebase/database';
import { Media, Area, History, AreaGroup } from '../Utils/Constant';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import AddGroupModal from '../Components/Groups/AddGroupModal';
import CustomButton from '../Components/Common/CustomButton';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import GroupCard from '../Components/Groups/GroupCard';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import AlertModal from '../Components/Common/AlertModal';
import ConfirmationModal from '../Components/Common/ConfirmationModal';
import checkIcon from "../Assets/checkIcon.svg"

function AreaPage() {
    const [formOpen, setFormOpen] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();
    const [area, setArea] = useState([]);
    const [searchText, setSearchText] = useState("");
    console.log(area)
    const id = localStorage.getItem("id");
    const db = getDatabase(app);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [areaData, setAreaData] = useState({});

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);
    const imageRegex = /^image\/(jpeg|jpg|png|gif|bmp|webp)$/;
    const { areaId } = useParams();

    const getAreaDetails = async () => {
        try {
            const areaSnapshot = await get(ref(db, `${AreaGroup}/${areaId}`));
            if (areaSnapshot.exists()) {
                const AreaData = areaSnapshot.val();
                setAreaData(AreaData)
                const allAreaQuery = query(ref(db, Area), orderByChild('user_id'), equalTo(id));
                const filterData = onValue(allAreaQuery, (snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        const itemsArray = Object.entries(data).map(([key, value]) => {
                            if (AreaData?.groups?.includes(key)) {
                                return {
                                    ...value,
                                    id: key
                                }
                            }
                            return;
                        }).filter(Boolean);
                        setArea(itemsArray);
                    } else {
                        console.log('No data found');
                    }
                });
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }

    useEffect(() => {
        getAreaDetails();
    }, [])

    const handleSubmit = async (values) => {
        try {
            const newUUID = uuidv4();
            await set(ref(db, `${Area}/${newUUID}`), {
                name: values?.area_name, user_id: id
            });
            const updates = {};
            updates[`/${AreaGroup}/` + areaId] = {
                ...areaData,
                groups: [...(areaData?.groups || []), newUUID]
            }
            await update(ref(db), updates);
            const history = {
                user_id: id,
                title: "Group",
                status: `${values?.area_name} group created successfully.`,
                timestamp: moment(new Date()).toISOString()
            }
            const historyRef = ref(db, History);
            const newHistoryRef = push(historyRef);
            set(newHistoryRef, history);
            navigate(`/area-group/${areaId}/group/${newUUID}`)
        } catch (error) {
            console.log(error)
        }
    }

    let filteredData = [];

    if (searchText === "") {
        filteredData = area;
    } else if (!area) {
        filteredData = [];
    } else {
        filteredData = area?.filter((item) => {
            const name = item?.name?.toLowerCase() || "";
            const query = searchText?.toLowerCase();
            return name.includes(query);
        });
    }

    const handleUploadOpen = () => {
        setFormOpen(true);
        closeNav();
    }

    const handleDeleteIcon = (item) => {
        setSelectedGroup(item);
        setDeleteModel(true);
    }

    const handleDeleteScreen = async () => {
        try {
            const groupRef = ref(db, `${Area}/${selectedGroup?.id}`);
            remove(groupRef).then(async (res) => {
                console.log(" File res res", res);
                const filteredGroups = areaData?.groups?.filter(item => item !== selectedGroup?.id);
                const updates = {};
                updates[`/${AreaGroup}/` + areaId] = {
                    ...areaData,
                    groups: filteredGroups
                }
                await update(ref(db), updates);
                setDeleteModel(false);
                setSelectedGroup();
                setDeleteSuccessOpen(true);
                setTimeout(() => {
                    setDeleteSuccessOpen(false);
                }, 4000);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleAreaNavigate = () => {
        navigate("/area-group");
    }

    return (
        <Layout>
            <div>
                <div style={{ height: '100%', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div className='breadcrumbs'>
                                <p>{`Home >`}</p>
                                <p onClick={handleAreaNavigate}>&nbsp;{`Area >`}</p>
                                <span>&nbsp; Display</span>
                            </div>
                        </div>
                        <div className='button-container'>
                            <CustomButton leftIcon={<AddIcon />} onClick={() => setFormOpen(true)} label="Add Display" background="rgba(13, 110, 253, 1)" />
                        </div>
                        <div className={`overlay ${isOpen ? 'open' : ''}`}>
                            <button className='add-button closebtn' onClick={closeNav}><CloseIcon /></button>
                            <div className="overlay-content">
                                <CustomButton leftIcon={<AddIcon />} onClick={handleUploadOpen} label="Add Display" background="rgba(0, 0, 0, 1)" />
                            </div>
                        </div>
                        <button className='add-button' onClick={openNav}><AddIcon /></button>
                    </div>
                    <div className='file-header'>
                        <div>
                            <p className='file-text'>Screen Display</p>
                            <p className='total-file-text'>Total Display Files - {area?.length}</p>
                        </div>
                        <div className='file-header-right-side'>
                            <div class="wrapper">
                                <div class="icon"><SearchIcon color='rgba(135, 142, 171, 1)' /></div>
                                <input onChange={(e) => setSearchText(e.target.value)} className='search-input' placeholder='Search by Name' />
                            </div>
                            <CustomButton onClick={() => { }} leftIcon={<TuneIcon style={{ height: 18, width: 18 }} />} label="Filters" background="rgba(13, 110, 253, 0.3)" />
                        </div>
                    </div>
                    <GroupCard groups={filteredData} handleDeleteIcon={handleDeleteIcon} areaId={areaId} />
                </div>
            </div>
            <ConfirmationModal
                open={deleteModel}
                setOpen={setDeleteModel}
                handleYes={handleDeleteScreen}
                handleClose={() => setDeleteModel(false)}
                title="Are you sure?"
                desc="Are you sure you want to delete this display?"
            />
            <AlertModal
                open={deleteSuccessOpen}
                handleClose={() => setDeleteSuccessOpen(false)}
                title={`Deleted Successfully`}
                desc={"The display is removed successfully"}
                icon={checkIcon}
                success={true}
            />
            <AddGroupModal open={formOpen} handleClose={() => setFormOpen(false)} handleSubmit={handleSubmit} />
        </Layout>
    )
}

export default AreaPage
