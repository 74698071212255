import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { equalTo, get, getDatabase, onValue, orderByChild, push, query, ref, set, update } from 'firebase/database';
import { History, Screens } from '../../Utils/Constant';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import { app } from '../../Utils/Firebase';
import CustomTable from '../Common/Table';
import PublishConfirmationModal from '../Groups/PublishConfirmationModal';
import checkIcon from "../../Assets/checkIcon.svg"
import AlertModal from '../Common/AlertModal';
import moment from 'moment';

function PublishScreen({ open, setOpen, selectedFile, isArea = false, areaData }) {
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [screens, setScreens] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const id = localStorage.getItem("id");
    const db = getDatabase(app);

    const onRowSelect = ({ selectedFlatRows }) => {
        const arrayOfStrings = selectedFlatRows.map(obj => obj.id);
        console.log(arrayOfStrings);
        setSelectedRowIds(arrayOfStrings);
    };

    useEffect(() => {
        try {
            const screensQuery = query(ref(db, Screens), orderByChild('user_id'), equalTo(id));
            const filterData = onValue(screensQuery, async (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const screenIds = Object.keys(data);
                    console.log(screenIds)
                    // Fetch user data for each screen
                    const screensData = [];
                    let i = 1;
                    for (const screenId of screenIds) {
                        const screen = data[screenId];
                        const pairingSnapshot = await get(ref(db, `pairingCodes/${screen.pairing_code}`));
                        if (pairingSnapshot.exists()) {
                            const pairingData = pairingSnapshot.val();
                            screensData.push({
                                ...screen,
                                id: screen?.pairing_code,
                                status: pairingData?.isOnline ? "Online" : "Offline",
                                index: i
                            });
                        }
                        i++;
                    }

                    setScreens(screensData);
                } else {
                    console.log('No data found');
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [])

    const handlePublishFile = async () => {
        try {
            for (let i = 0; i < selectedRowIds.length; i++) {
                const item = selectedRowIds[i];
                const screenData = screens?.filter(sr => sr?.pairing_code === item);
                delete screenData[0].id;
                delete screenData[0]?.status;
                const updates = {};
                const pairingCode = screenData[0]?.pairing_code;

                if (pairingCode) {
                    updates['/Screens/' + pairingCode] = {
                        ...screenData[0],
                        areaId: selectedFile,
                        isArea: true,
                        areaData: areaData
                    };
                    await update(ref(db), updates);
                    // const history = {
                    //     user_id: id,
                    //     screenId: pairingCode,
                    //     screen_name: screenData?.[0]?.screen_name,
                    //     updatedAt: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
                    //     areaId: selectedFile,
                    // }
                    const history = {
                        user_id: id,
                        title: "Screen",
                        status: `${areaData?.name} group successfully published to screen ${screenData?.[0]?.screen_name}.`,
                        timestamp: moment(new Date()).toISOString()
                    }
                    const historyRef = ref(db, History);
                    const newHistoryRef = push(historyRef);
                    set(newHistoryRef, history);
                }
            }
            const unselectedScreens = screens?.filter(item => !selectedRowIds?.includes(item?.pairing_code));
            for (let i = 0; i < unselectedScreens.length; i++) {
                const currentScreen = unselectedScreens[i];
                if (currentScreen?.areaId === selectedFile) {
                    const updates = {};
                    updates['/Screens/' + currentScreen?.pairing_code] = {
                        ...currentScreen,
                        areaId: "",
                        areaData: {}
                    };
                    console.log("updates", updates);
                    await update(ref(db), updates);
                }
            }
            setOpen(false);
            setOpenConfirm(false);
            setSuccessOpen(true);
            setTimeout(() => {
                setSuccessOpen(false);
            }, 4000);
        } catch (error) {
            console.log("error--=-=-", error)
        }
    }

    const columns = useMemo(
        () => [
            {
                accessor: 'index',
                Header: 'Sr.no',
                width: "20vw",
                Cell: ({ row }) => {
                    return <p>{row?.original?.index}</p>
                }
            },
            {
                accessor: 'screen_name',
                Header: 'Screen Name',
                width: "20%",
            },
            {
                accessor: 'pairing_code',
                Header: 'Pairing Code',
                width: "20%",
            },
            {
                accessor: 'startDate',
                Header: 'Date of Pairing',
                width: "20%",
            },
            {
                accessor: 'status',
                Header: 'Status',
                width: "20%",
            }
        ],
        []
    );

    const handlePublish = () => {
        setOpenConfirm(true);
    }

    const handleClose = () => {
        setOpenConfirm(false);
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
                    </Box>
                    <p className="select-playlist-text">Select screen</p>
                    <p className="publish-screen-desc">Please select screen to publish</p>
                    <div style={{ height: 'auto', width: '100%' }}>
                        <CustomTable
                            columns={columns}
                            data={screens}
                            details="No Screen Found!"
                            allowSelectRow
                            onRowSelect={onRowSelect}
                        />
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
                        <Button type="submit" onClick={handlePublishFile} variant="contained" sx={{ width: "200px", margin: "0 30px 30px 0", background: '#3379d0', height: "40px", color: 'white' }}>
                            Publish
                        </Button>
                    </Box> */}
                        <button type="submit" disabled={!selectedRowIds?.length} onClick={handlePublish} variant="contained" className='btn12' style={{ padding: "22px 40px", width: "100%" }}>
                            Continue
                        </button>
                    </div>
                </Box>
            </Modal>
            <PublishConfirmationModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                handleYes={handlePublishFile}
                handleClose={handleClose}
                title="Are your sure?"
                desc={`Are you sure you want publish this Group?`}
            />
            <AlertModal
                open={successOpen}
                handleClose={() => setSuccessOpen(false)}
                title={`Playlist Published successfully`}
                desc={"Playlist Published successfully"}
                icon={checkIcon}
                success={true}
            />
        </>
    )
}

export default PublishScreen

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '400px',
    width: {
        xs: '90vw',  // 0px - 600px
        sm: '70vw',
    },
    height: '80vh',
    backgroundColor: "rgba(240, 249, 254, 1)",
    borderRadius: "30px",
    padding: "30px",
    overflowY: "auto"
};