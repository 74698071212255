import React from 'react'
import duplicateIcon from "../../Assets/saveIcon.svg"
import { useNavigate } from 'react-router-dom';
import deleteIcon from "../../Assets/delete-icon-red.svg"

function FolderCard({ folder, handleFolderClick, handleDeleteIcon, handleRenameFolder }) {
    const navigate = useNavigate();

    return (
        <>
            {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', gap: '20px', marginTop: "35px" }}> */}
            {folder?.length ? folder?.map((p, i) => {
                return (<div key={i} className='folderCard'>
                    <img
                        src={duplicateIcon}
                        className="mediaFileCardImg"
                        alt="image not found"
                        onClick={() => handleFolderClick(p?.id)}
                    />
                    <div className='playlist-content-container' onClick={() => handleFolderClick(p?.id)}>
                        <div>
                            <p className='group-card-title'>{p?.name?.length > 25
                                ? p.name.slice(0, 25) + "..."
                                : p.name}</p>
                        </div>
                    </div>
                    <div className='playlist-button-container' style={{ alignItems: "center" }}>
                        <button className='rename-btn' onClick={() => handleRenameFolder(p)}>Rename</button>
                        <img src={deleteIcon} alt="delete-img" onClick={() => handleDeleteIcon(p)} />
                    </div>
                </div>)
            }) : <p className='mediafile-not-found'>No Folder Found</p>}
            {/* </div> */}
        </>
    )
}

export default FolderCard