import { Field, Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { OTPValidation, ResetValidation } from '../../Utils/Validation'
import { Loader } from '../Css';

function Otp({ step, setStep, secret }) {
    const formRef = useRef();

    const handleVerifyCode = (values, { setFieldError, resetForm, setSubmitting }) => {
        if (Number(values?.otp) === Number(secret)) {
            setStep(step + 1);
        } else {
            setFieldError("otp", "Invalid OTP Please Enter correct OTP.");
            setSubmitting(false);
        }
    };

    return (
        <div className="loginform-container">
            <div className="loginform">
                <Formik
                    initialValues={{ otp: '' }}
                    validationSchema={OTPValidation}
                    onSubmit={handleVerifyCode}
                    innerRef={formRef}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form>
                            <h1>Verify OTP</h1>
                            <br />

                            <div className="field">
                                <label>Enter OTP</label>
                                <Field
                                    type="text"
                                    name="otp"
                                    className="textarea"
                                    value={values?.otp}
                                    onChange={handleChange}
                                    style={{ paddingLeft: "14px" }}
                                />
                            </div>
                            <p className="error-message">
                                {touched.otp && errors.otp}
                            </p>

                            {isSubmitting ? (
                                <button
                                    className="btn12"
                                    style={{ display: "grid" }}
                                    disabled={isSubmitting}
                                >
                                    <Loader />
                                </button>
                            ) : (
                                <button className="btn12" onClick={handleSubmit}>
                                    Submit
                                </button>
                            )}
                        </form>
                    )}
                </Formik >
            </div>
        </div>
    )
}

export default Otp