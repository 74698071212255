import { Typography } from '@mui/material'
import React from 'react'
// import totalMedia from "../../Assets/totalmedia.svg"
import graph from "../../Assets/dashboard-graph.svg"
import { useAuth } from '../../Context/auth';
import MediaChart from './MediaChart';

function StatisticsSide({ storageSize, totalStorage }) {
    const { user } = useAuth();
    return (
        <div className='StatisticsBox'>
            <div className='mediafile-main-box'>
                <div className='mediafiles-sub-box'>
                    <p className='totalmediaText'>Total Media Files</p>
                    <p className='totalmediaNumber'>{storageSize?.files?.length}</p>
                    <p className='totalmediaVideos'>Photos/Videos</p>
                </div>
            </div>
            <div className='statisticsSmallBox'>
                <p className='totalmediaText'>Statistics</p>
                {/* <img src={graph} alt='graph image' /> */}
                <MediaChart storageSize={totalStorage} />
                <div className='stat-small-box-text'>
                    <p className='total-mb'>Total <span>{storageSize?.totalSizeInMB.toFixed(0)} mb</span> used from {user?.storage}</p>
                    {/* <img src={totalMedia} /> */}
                </div>
            </div>
        </div>
    )
}

export default StatisticsSide