import React, { useState } from "react";
import "../../Css/Login.css";
import EmailSend from "./EmailSend";
import Otp from "./Otp";
import NewPassword from "./NewPassword";
import mainlogo from "../../Assets/logo_and_text.svg";

function ForgotPassword() {
    const [step, setStep] = useState(0);
    const [secret, setSecret] = useState("");
    const [email, setEmail] = useState("");

    const getRenderComponent = () => {
        switch (step) {
            case 0:
                return <EmailSend setStep={setStep} setSecret={setSecret} setEmail={setEmail} />;
            case 1:
                return <Otp setStep={setStep} step={step} secret={secret} />;
            case 2:
                return <NewPassword email={email} />;
            default:
                throw new Error("Unknown step");
        }
    }

    return (
        <>
            <div className="container">
                {/* <h1 style={{ marginTop: '10px' }}>MaxPro Displays</h1> */}
                <img src={mainlogo} alt="logo" className="logo" />
                {getRenderComponent()}
            </div>
        </>
    );
}

export default ForgotPassword;
