import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material';
import "../Css/ScreenSetting.css";
import { get, getDatabase, ref, remove } from 'firebase/database';
import { app } from '../Utils/Firebase';
import CustomTabs from '../Components/Common/Tab';
import FileUploader from '../Components/FileUploader';
import Schedule from '../Components/ScreenView/Schedule';
import DeleteIcon from '@mui/icons-material/Delete';
import { Screens } from '../Utils/Constant';
import { toast } from 'react-toastify';
import Close from '@mui/icons-material/Close';
import AlertDialog from '../Components/Common/AlertDialog';
import { fetchFolderSize } from '../Utils/utils';

function ScreenSetting() {
    const [screenData, setScreenData] = useState();
    const [areaData, setAreaData] = useState();
    const [deleteModel, setDeleteModel] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const db = getDatabase(app);
    console.log(areaData)

    const tabsItems = [
        {
            title: "Schedule",
            component: <Schedule data={areaData?.playlist || []} />
        },
        {
            title: "Playlist",
            component: <div>Playlist list</div>
        },
    ]

    const getScreenData = async () => {
        try {
            const screenSnapshot = await get(ref(db, `Screens/${id}`));
            if (screenSnapshot.exists()) {
                const data = screenSnapshot.val();
                setScreenData(data);
                const areaSnapshot = await get(ref(db, `Area/${data?.areaId}`));
                if (areaSnapshot.exists()) {
                    const areadata = areaSnapshot.val();
                    const filteresData = [];

                    for (const area of areadata?.playlist) {
                        // const area = data[areaId?.id];
                        const playlistSnapshot = await get(ref(db, `Playlist/${area?.id}`));
                        if (playlistSnapshot.exists()) {
                            const playlistData = playlistSnapshot.val();
                            filteresData.push({
                                ...area,
                                playlist_name: playlistData?.name
                            });
                        }
                    }
                    const data = {
                        ...areadata,
                        playlist: filteresData
                    }

                    setAreaData(data);
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getScreenData();
        // fetchFolderSize("607d4b71-94b1-41b8-add2-879a51ac5b84");
    }, []);

    const handleDeleteScreen = async () => {
        try {
            const screenRef = ref(db, `${Screens}/${id}`);
            await remove(screenRef);
            toast.success("Screen Deleted Successfully.", {
                closeButton: <Close />
            });
            navigate("/screens");
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Layout>
            <div style={{ height: 'calc(100vh - 40px)', padding: '20px', background: 'aliceblue', overflow: 'auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Button variant="contained" onClick={() => navigate(`/screens`)} sx={{ width: "20px", background: '#3379d0', height: "35px", color: 'white', borderRadius: '10px', }}>
                            Back
                        </Button>
                    </div>
                </div>
                <div className='card'>
                    <div style={{ display: "flex" }}>
                        <div className='display'>
                            <div>
                                <h3>Currently Playing Folder</h3>
                                <h3 style={{ color: '#0085ff' }}>{areaData?.name}</h3>
                            </div>
                            <p>Your Screen</p>
                        </div>
                        <div className='displayContent'>
                            <div>
                                <h2>{screenData?.screen_name}</h2>
                                <p>{screenData?.screen_tag}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => setDeleteModel(true)} />
                    </div> */}
                </div>
                <div>
                    <CustomTabs tabItems={tabsItems} />
                </div>
            </div>
            <AlertDialog
                open={deleteModel}
                setOpen={setDeleteModel}
                handleYes={handleDeleteScreen}
                title="Delete Screen"
                description="Are you sure want to delete this screeen?"
            />
        </Layout>
    )
}

export default ScreenSetting