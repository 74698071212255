import React from 'react'
import activeScreen from "../../Assets/activeScreen.svg"
import InactiveScreen from "../../Assets/InactiveScreen.svg"
import totalScreenImg from "../../Assets/totalScreens.svg"
import { Typography } from '@mui/material'
import "../../Css/Dashboard.css";
import { useAuth } from '../../Context/auth'

function ScreenCard({ screens, totalScreen, storageSize }) {
    const { user } = useAuth();
    const max_no_of_screens = user?.no_of_screens;
    return (
        <>
            <div className='screen-container'>
                <Typography variant='p' className='box-heading'>Active Screens </Typography>
                <div className='screen-layout'>
                    <div>
                        <Typography variant='h6' className='screen-number'>{totalScreen || screens?.length}</Typography>
                        <p className='screen-date'>15 Nov 2023</p>
                        <p className='screen-pertantage'>7.2%</p>
                    </div>
                    <img src={activeScreen} />
                </div>
            </div>
            <div className='screen-container'>
                <Typography variant='p' className='box-heading'>Inactive Screens</Typography>
                <div className='screen-layout'>
                    <div>
                        <Typography variant='h6' className='screen-number'>{parseInt(max_no_of_screens) - (totalScreen || parseInt(screens?.length))}</Typography>
                        <p className='screen-date'>15 Nov 2023</p>
                        <p className='screen-pertantage'>7.2%</p>
                    </div>
                    <img src={InactiveScreen} />
                </div>
            </div>
            <div className='screen-container'>
                <Typography variant='p' className='box-heading'>Total Screens</Typography>
                <div className='screen-layout'>
                    <div>
                        <Typography variant='h6' className='screen-number'>{max_no_of_screens}</Typography>
                        <p className='screen-date'>15 Nov 2023</p>
                        <p className='screen-pertantage'>7.2%</p>
                    </div>
                    <img src={totalScreenImg} />
                </div>
            </div>
            <div className='screen-container'>
                <Typography variant='p' className='box-heading'>Allotted Space</Typography>
                <div className='screen-layout'>
                    <div>
                        <p className='allocate-space-text'>{storageSize?.totalSizeInMB.toFixed(0)}mb<span>/{user?.storage} used</span></p>
                        <p className='screen-date'>15 Nov 2023</p>
                    </div>
                    <img src={totalScreenImg} />
                </div>
            </div>
        </>
    )
}

export default ScreenCard